<template>
    <div class="gyss_container">
        <div class="tabs_btns_container">
            <div class="gyss_btn" v-for="item in gyssInfo" :key="item.typeId" @click="changeTab(item.typeId)">
                {{item.typeName}}
            </div>
        </div>
        <div class="tabs_content_container">
            <div v-if="tabTypeId == 1" :class="tabTypeId == 1 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="ssdj_container">
                  <div class="ssdj_articleList_container" style="text-align: center" v-if="carousel.length == 0 && article.length == 0">
                    暂无内容
                  </div>
                  <div class="ssdj_carousel_container" v-if="carousel.length > 0">
                      <el-carousel arrow="never" height="100%" indicator-position="none" :interval="2000">
                        <el-carousel-item v-for="item in carousel" :key="item.articleid">
                          <div class="ssdj_carousel_content_container">
                            <div class="ssdj_carousel_img_container">
                                <el-image :src="item.articlecarouselimg" fit="cover">
                                  <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                  </div>
                                </el-image>
                            </div>
                            <div class="ssdj_carousel_words_container">
                                <div class="ssdj_carousel_title_container">
                                    {{item.articletitle}}
                                </div>
                                <div class="ssdj_carousel_date_container">
                                    日期：{{item.articledate}}
                                    <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                                </div>
                                <div class="ssdj_carousel_text_container" v-html="item.articletext"></div>
                            </div>
                          </div>
                        </el-carousel-item>
                      </el-carousel>
                  </div>
                  <div class="ssdj_articleList_container" v-if="article.length > 0">
                      <div class="ssdj_articleItem_container" v-for="item in article" :key="item.articleid">
                        <div class="ssdj_article_img_container">
                            <el-image :src="item.articlefaceimg" fit="cover">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                            </el-image>
                        </div>
                        <div class="ssdj_article_text_container">
                            <div class="ssdj_article_title_container">
                                {{item.articletitle}}
                            </div>
                            <div class="ssdj_article_date_container">
                                日期：{{item.articledate}}
                                <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                            </div>
                            <div class="ssdj_article_words_container" v-html="item.articletext"></div>
                        </div>
                      </div>
                      <!-- 分页 -->
                      <div class="ssdj_articlePage_container">
                          <el-pagination
                            layout="prev, pager, next"
                            @current-change="pageChanged"
                            :current-page="pageInfo.currentPage"
                            :total="pageInfo.totals"
                            :page-size="pageInfo.pageSize">
                          </el-pagination>
                      </div>
                  </div>
                </div>
            </div>
            <div v-if="tabTypeId == 2" :class="tabTypeId == 2 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="ssdj_container">
                  <div class="ssdj_carousel_container" v-if="carousel.length > 0">
                      <el-carousel arrow="never" height="100%" indicator-position="none" :interval="2000">
                        <el-carousel-item v-for="item in carousel" :key="item.articleid">
                          <div class="ssdj_carousel_content_container">
                            <div class="ssdj_carousel_img_container">
                                <el-image :src="item.articlecarouselimg" fit="cover">
                                  <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                  </div>
                                </el-image>
                            </div>
                            <div class="ssdj_carousel_words_container">
                                <div class="ssdj_carousel_title_container">
                                    {{item.articletitle}}
                                </div>
                                <div class="ssdj_carousel_date_container">
                                    日期：{{item.articledate}}
                                    <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                                </div>
                                <div class="ssdj_carousel_text_container" v-html="item.articletext"></div>
                            </div>
                          </div>
                        </el-carousel-item>
                      </el-carousel>
                  </div>
                  <div class="ssdj_articleList_container" style="text-align: center" v-if="carousel.length == 0 && article.length == 0">
                    暂无内容
                  </div>
                  <div class="ssdj_articleList_container" v-if="article.length > 0">
                      <div class="ssdj_articleItem_container" v-for="item in article" :key="item.articleid">
                        <div class="ssdj_article_img_container">
                            <el-image :src="item.articlefaceimg" fit="cover">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                            </el-image>
                        </div>
                        <div class="ssdj_article_text_container">
                            <div class="ssdj_article_title_container">
                                {{item.articletitle}}
                            </div>
                            <div class="ssdj_article_date_container">
                                日期：{{item.articledate}}
                                <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                            </div>
                            <div class="ssdj_article_words_container" v-html="item.articletext"></div>
                        </div>
                      </div>
                      <!-- 分页 -->
                      <div class="ssdj_articlePage_container">
                          <el-pagination
                            layout="prev, pager, next"
                             @current-change="pageChanged"
                             :current-page="pageInfo.currentPage"
                            :total="pageInfo.totals"
                            :page-size="pageInfo.pageSize">
                          </el-pagination>
                      </div>
                  </div>
                </div>
            </div>
            <div v-if="tabTypeId == 3" :class="tabTypeId == 3 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="ssdj_articleList_container" style="text-align: center" v-if="article.length == 0">
                    暂无内容
                </div>
                <div class="ssdj_articleList_container" v-if="article.length > 0">
                      <div class="ssdj_articleItem_container" v-for="item in article" :key="item.articleid">
                        <div class="ssdj_article_img_container">
                            <el-image :src="item.articlefaceimg" fit="cover">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                            </el-image>
                        </div>
                        <div class="ssdj_article_text_container">
                            <div class="ssdj_article_title_container">
                                {{item.articletitle}}
                            </div>
                            <div class="ssdj_article_date_container">
                                日期：{{item.articledate}}
                                <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                            </div>
                            <div class="ssdj_article_words_container" v-html="item.articletext"></div>
                        </div>
                      </div>
                      <!-- 分页 -->
                      <div class="ssdj_articlePage_container">
                          <el-pagination
                            layout="prev, pager, next"
                             @current-change="pageChanged"
                             :current-page="pageInfo.currentPage"
                            :total="pageInfo.totals"
                            :page-size="pageSize">
                          </el-pagination>
                      </div>
                  </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      tabTypeId: 1,
      activeCzlcId: '',
      gyssInfo: [
        {
          typeName: '企业新闻',
          typeId: 1
        },
        {
          typeName: '业内资讯',
          typeId: 2
        },
        {
          typeName: '相关法规',
          typeId: 3
        }
      ],
      swiperOption: {
        autoplay: {
          delay: 500,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        observer: true,
        observeParents: true,
        loop: true,
        autoplayDisableOnInteraction: false,
        speed: 1000,
        grabCursor: true,
        setWrapperSize: true,
        centeredSlides: true,
        spaceBetween: 30
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: false
        // }
      },
      carousel: [],
      article: [],
      pageInfo: {
        currentPage: 1,
        totals: 50,
        pageSize: 3
      }
    }
  },
  methods: {
    // 获取轮播
    queryCarouselByType (typeId) {
      // 山水党建type：1
      this.$http.post('/admin/queryCarouselByType/' + typeId).then(res => {
        console.log(res)
        this.carousel = res.data.data
      })
    },
    // 根据类型获取文章
    getArticleByPage (typeId) {
      // 山水党建type：1
      this.$http.post('/admin/getArticleByType/' + typeId, this.pageInfo).then(res => {
        // console.log(res.data.articles)
        this.pageInfo.totals = res.data.totals
        this.article = res.data.articles
      })
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    changeTab (typeId) {
      // console.log(typeId)
      this.pageInfo = {
        currentPage: 1,
        totals: 0,
        pageSize: 3
      }
      window.sessionStorage.setItem('tabTypeId', typeId)
      this.tabTypeId = typeId
      if (typeId === 1) {
        this.queryCarouselByType(3)
        this.getArticleByPage(2)
      } else if (typeId === 2) {
        this.queryCarouselByType(4)
        this.getArticleByPage(3)
      } else if (typeId === 3) {
        this.getArticleByPage(4)
      }
    },
    pageChanged (currentPage) {
      this.pageInfo.currentPage = currentPage
      this.getArticleByPage(this.tabTypeId + 1)
    },
    showArticleDeltail (data) {
      window.sessionStorage.setItem('articleDetail', JSON.stringify(data))
      window.sessionStorage.setItem('activeIndex', '/xwzx')
      this.$router.push('articleDetail')
    }
  },
  mounted () {
    // this.tabTypeId = window.sessionStorage.getItem('tabTypeId')
    this.changeTab(this.tabTypeId)
  }
}
</script>

<style lang="less" scoped>
// 新的
.gyss_container {
    width: 100%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
}
.tabs_btns_container {
    width: 100%;
    //  background-color: red;
    padding: 10px 5px;
    font-size: 100%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
}
.gyss_btn {
    padding: 10px 20px;
    background-color: transparent;
    text-align: center;
    border-radius: 5px;
    color: #808080;
    cursor: pointer;
}
.gyss_btn:hover{
    background-color: #F5F5F5;
    color: #000;
}
.gyss_btn_click{
    background-color: #F5F5F5;
    color: #000;
}
.tabs_content_container {
    width: 100%;
    min-height: 200px;
    padding: 5px;
    overflow: hidden;
}
.tabs_content_item {
    width: 100%;
    min-height: 450px;
}
@media screen and (max-width: 500px){
  .tabs_btns_container {
      width: 100%;
      //  background-color: red;
      padding: 5px;
      font-size: 10px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
  }
  .gyss_btn {
      padding: 10px 10px;
      background-color: #F5F5F5;
      text-align: center;
      border-radius: 5px;
      color: #808080;
      cursor: pointer;
  }
  .gyss_btn:hover{
      background-color: #F5F5F5;
      color: #000;
  }
  .gyss_btn_click{
      background-color: #F5F5F5;
      color: #000;
  }
  .tabs_content_container {
      width: 100%;
      min-height: 200px;
      padding: 5px;
      overflow: hidden;
  }
  .tabs_content_item {
      width: 100%;
      min-height: 200px;
  }
}
// 后
.ssjs_container {
    width: 100%;
    padding: 20px 0;
    min-height: 450px;
    position: relative;
}
.ssjs_container i {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    transform: translate(-50%, -50%);
}
// 山水荣誉
.ssry_container {
    width: 100%;
    padding-top: 20px;
    overflow: hidden;
}
.ssry_img_container {
    width: 100%;
}
.ssry_carousel_container {
    width: 100%;
    height: 400px;
    // background-color: pink;
}
.ssry_carousel_container .swiper{
    height: 100%;
}
.swiper-slide {
    width: 450px;
    height: 100%;
    padding: 2px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-slide img{
    max-height: 100%;
    max-width: 100%;
    box-shadow: 1px 1px 5px black;
}
.ssry_type_container {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
@media screen and (max-width: 500px){
    // 山水荣誉
    .ssry_container {
        width: 100%;
    }
    .ssry_img_container {
        width: 100%;
    }
    .ssry_carousel_container {
        width: 100%;
        height: 250px;
        // background-color: pink;
    }
    .ssry_carousel_container .swiper{
        height: 100%;
    }
    .swiper-slide {
        width: 300px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-slide img{
        max-height: 100%;
        max-width: 100%;
        box-shadow: 1px 1px 5px black;
    }
    .ssry_type_container {
        width: 100%;
        padding: 10px 0;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ssry_type_container .el-button{
        width: 17%;
        height: 30px;
        font-size: 10px;
        text-align: center;
        padding: 0;
    }
}
// 成长历程
.czlc_container {
  width: 100%;
  min-height: 500px;
}
.czlc_px_container , .czlc_body_container{
  width: 100%;
  // background-color: red;
  padding: 10px 0;
}
.hxbk_container {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hxbk_item_container {
  width: 100%;
  min-height: 400px;
  display: flex;
}
.hxbk_item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
}
.hxbk_item_content_container {
  width: 100%;
  height: 300px;
  position: relative;
}
.hxbk_item_img_container {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 300px;
}
.hxbk_item_img_container .el-image{
  width: 100%;
  height: 100%;
}
.hxbk_item_text_container {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 200;
  top: 240px;
  width: 80%;
  height: 50px;
  // background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
  color: #fff;
  box-shadow: 1px 1px 5px black;
}
@media screen and (max-width: 500px){
  .hxbk_item_container {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }
  .hxbk_item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .hxbk_item_content_container {
    width: 100%;
    height: 250px;
    position: relative;
  }
  .hxbk_item_img_container {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 250px;
  }
  .hxbk_item_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .hxbk_item_text_container {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 200;
    top: 190px;
    width: 90%;
    height: 50px;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150%;
    color: #fff;
    box-shadow: 1px 1px 5px black;
  }
}
// 山水党建
.ssdj_container {
  width: 100%;
  min-height: 450px;
}
.ssdj_carousel_container {
  width: 100%;
  height: 450px;
  background-color: #E6E6E6;
}
.ssdj_carousel_container .el-carousel {
  height: 100%;
}
.ssdj_carousel_content_container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
}
.ssdj_carousel_img_container {
  width: 40%;
  height: 100%;
}
.ssdj_carousel_img_container .el-image{
  width: 100%;
  height: 100%;
}
.ssdj_carousel_words_container {
  width: 60%;
  height: 100%;
  padding: 5px 20px;
  overflow: hidden;
}
.ssdj_carousel_title_container {
  width: 100%;
  font-size: 30px;
  text-align: center;
  height: 45px;
  padding: 10px 0;
  text-align: center;
  overflow : hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ssdj_carousel_date_container {
  width: 100%;
  color: #C9C9C9;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ssdj_carousel_text_container {
  width: 100%;
  height: 330px;
  color: #000;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0.2rem;
  display: -webkit-box;
  overflow : hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 768px) and (min-width: 500px){
  .ssdj_carousel_title_container {
    width: 100%;
    font-size: 24px;
    height: 40px;
    text-align: center;
    padding: 10px 0;
    text-align: center;
  }
  .ssdj_carousel_date_container {
    width: 100%;
    color: #C9C9C9;
    font-size: 14px;
  }
  .ssdj_carousel_text_container {
    width: 100%;
    height: 300px;
    color: #000;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.2rem;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}
@media screen and (max-width: 500px){
  .ssdj_carousel_container {
    width: 100%;
    height: 170px;
    background-color: #E6E6E6;
  }
  .ssdj_carousel_container .el-carousel {
    height: 100%;
  }
  .ssdj_carousel_content_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 5px;
  }
  .ssdj_carousel_img_container {
    width: 40%;
    height: 100%;
  }
  .ssdj_carousel_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .ssdj_carousel_words_container {
    width: 60%;
    height: 100%;
    padding: 5px;
  }
  .ssdj_carousel_title_container {
    width: 100%;
    font-size: 15px;
    height: 24px;
    text-align: center;
    padding: 5px 0;
  }
  .ssdj_carousel_date_container {
    width: 100%;
    color: #C9C9C9;
    height: 25px;
    // background-color: red;
    font-size: 10px;
  }
  .ssdj_carousel_text_container {
    width: 100%;
    height: 90px;
    color: #000;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2rem;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
// 列表
.ssdj_articleList_container {
  width: 100%;
  min-height: 500px;
  // background-color: yellow;
  padding: 20px 0;
}
.ssdj_articleItem_container {
  width: 90%;
  height: 300px;
  // background-color: red;
  margin: 30px auto;
  display: flex;
  box-shadow: 1px 1px 5px 1px lightgray;
}
.ssdj_article_img_container {
  width: 40%;
  height: 100%;
}
.ssdj_article_img_container .el-image{
  width: 100%;
  height: 100%;
}
.ssdj_article_text_container {
  width: 60%;
  height: 100%;
  padding: 10px;
}
.ssdj_articlePage_container {
  width: 100%;
  height: 50px;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ssdj_article_title_container {
  width: 100%;
  font-size: 24px;
  height: 34px;
  padding: 5px 0;
  text-align: center;
  overflow : hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ssdj_article_date_container {
  width: 100%;
  font-size: 14px;
  text-align: left;
  color: #C9C9C9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ssdj_article_words_container {
  width: 100%;
  height: 216px;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  overflow : hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 500px){
  .ssdj_articleList_container {
    width: 100%;
    min-height: 500px;
    // background-color: yellow;
    padding: 10px 0;
  }
  .ssdj_articleItem_container {
    width: 100%;
    height: 170px;
    // background-color: red;
    margin: 30px auto;
    display: flex;
    // border: 1px solid lightgray;
    box-shadow: 1px 1px 5px 1px lightgray;
  }
  .ssdj_article_img_container {
    width: 40%;
    height: 100%;
  }
  .ssdj_article_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .ssdj_article_text_container {
    width: 60%;
    height: 100%;
    padding: 5px;
  }
  .ssdj_articlePage_container {
    // width: 90%;
    height: 50px;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ssdj_article_title_container {
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    height: 28px;
    padding: 2px 0;
    text-align: left;
    overflow : hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .ssdj_article_date_container {
    width: 100%;
    font-size: 10px;
    text-align: left;
    height: 30px;
    color: #C9C9C9;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ssdj_article_words_container {
    width: 100%;
    height: 100px;
    font-size: 14px;
    letter-spacing: 0.1rem;
    line-height: 20px;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
</style>
