<template>
<!-- 业绩展示 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="业绩案例标题"
                width="300"
                prop="achievementname">
                </el-table-column>
                <el-table-column
                label="封面"
                prop="achievementfaceimg"
                width="300">
                </el-table-column>
                <el-table-column
                label="图片"
                prop="achievementshowimgs">
                </el-table-column>
                <!-- <el-table-column
                label="描述"
                prop="achievementtext"
                width="400"
                sortable>
                </el-table-column> -->
                <el-table-column
                prop="achievementshowhomepage"
                label="首页展示"
                sortable
                width="100">
                    <template slot-scope="scope">
                        <el-switch
                          v-model="scope.row.achievementshowhomepage"
                          active-value="1"
                          inactive-value="0"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          disabled>
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                fixed="right"
                width="90"
                align="center"
                prop="achievementtype"
                label="类型"
                sortable
                :filters="achievementtypeInfo"
                :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.achievementtype === '0'" type="success">设计</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '1'" type="info">施工</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '2'" type="warning">监理</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '3'" type="danger">咨询</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '4'" >科研</el-tag>
                </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia lunbo -->
        <el-dialog title="添加业绩案例" :visible.sync="addDialogVisable" width="1000px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="achievementInfo">
                <el-form-item label="项目名称" label-width="100px">
                    <el-input type="text" v-model="achievementInfo.achievementname"></el-input>
                    <!-- <el-select v-model="carosuelInfo.articlecarouseltype" placeholder="请选择轮播图类型">
                        <el-option  v-for="item in carouselTypeInfo" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="项目描述"  label-width="100px">
                    <el-input type="textarea" v-model="achievementInfo.achievementtext" maxlength="500" rows="10"></el-input>
                </el-form-item>
                <el-form-item label="所属分类" label-width="100px">
                    <el-select v-model="achievementInfo.achievementtype" placeholder="请选择文章">
                        <el-option v-for="item in achievementtypeInfo" :key="item.value" :label="item.text" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px">
                    <el-upload
                      :auto-upload="true"
                      ref="achievementfaceimg"
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      :on-preview="handlePreview"
                      :on-error="handleError"
                      :on-remove="handleRemove"
                      :on-success="uploadSuccess"
                      :file-list="QiniuData.fileList"
                      :before-upload="beforeAvatarUpload">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="上传更多图片"  label-width="100px">
                    <el-upload
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      list-type="picture-card"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :on-error="handleError"
                      :on-success="uploadListImgSuccess"
                      :before-upload="beforeAvatarUpload"
                      :file-list="QiniuData.moreFileList">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="修改业绩" :visible.sync="editDialogVisable"  width="1000px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="achievementInfo">
                <el-form-item label="项目名称" label-width="100px">
                    <el-input type="text" v-model="achievementInfo.achievementname"></el-input>
                    <!-- <el-select v-model="carosuelInfo.articlecarouseltype" placeholder="请选择轮播图类型">
                        <el-option  v-for="item in carouselTypeInfo" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item label="项目描述"  label-width="100px">
                    <el-input type="textarea" v-model="achievementInfo.achievementtext" maxlength="500" rows="10"></el-input>
                </el-form-item>
                <el-form-item label="所属分类" label-width="100px">
                    <el-select v-model="achievementInfo.achievementtype" placeholder="请选择文章">
                        <el-option v-for="item in achievementtypeInfo" :key="item.value" :label="item.text" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px">
                    <el-upload
                      :auto-upload="true"
                      ref="achievementfaceimg"
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      :on-preview="handlePreview"
                      :on-remove="handleRemoveFaceImg"
                      :on-error="handleError"
                      :on-success="uploadSuccess"
                      :file-list="QiniuData.fileList"
                      :before-upload="beforeAvatarUpload">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="上传更多图片"  label-width="100px">
                    <el-upload
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      list-type="picture-card"
                      :on-preview="handlePreview"
                      :on-error="handleError"
                      :on-remove="handleRemove"
                      :on-success="uploadListImgSuccess"
                      :before-upload="beforeAvatarUpload"
                      :file-list="QiniuData.moreFileList">
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="SubmitEditDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {
      listCardImg: '',
      showImgsDialogVisible: false,
      achievementtypeInfo: [
        {
          text: '设计',
          value: '0'
        },
        {
          text: '施工',
          value: '1'
        },
        {
          text: '监理',
          value: '2'
        },
        {
          text: '咨询',
          value: '3'
        },
        {
          text: '科研',
          value: '4'
        }
      ],
      achievementInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        moreFileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      }
    }
  },
  methods: {
    handleError (file) {
      console.log(file)
      this.$message.error('上传错误，请刷新页面重新上传')
    },
    // 模态框关闭
    dialogClose () {
      this.QiniuData.fileList = []
      this.QiniuData.moreFileList = []
      this.achievementInfo = {}
      // console.log(this.achievementInfo)
    },
    getAchievement () {
      this.loading = true
      this.$http.get('/admin/getAchievement').then(res => {
        // console.log(res)
        this.loading = false
        this.tableData = res.data.data
      })
    },
    // editDialog
    SubmitEditDialog () {
      this.loading = true
      // console.log(this.achievementInfo)
      if (this.achievementInfo.achievementfaceimg !== '' && this.achievementInfo.achievementfaceimg !== null) {
        this.$http.post('/admin/updateAchievement', this.achievementInfo).then(res => {
          // console.log(res)
          this.loading = false
          if (res.data.code === 200) {
            this.$notify.success({
              title: '成功',
              message: '操作成功'
            })
            // this.getcarouselList()
            this.addDialogVisable = false
            this.editDialogVisable = false
          } else {
            this.$notify.error({
              title: '失败',
              message: '操作失败'
            })
          }
          this.getAchievement()
        }).catch(error => {
          console.log(error)
          this.$notify.warn({
            title: '警告',
            message: '修改失败'
          })
        })
      } else {
        this.$notify.warn({
          title: '警告',
          message: '请选择图片'
        })
      }
    },
    // 提交dialog内容 addDialog
    submitDialog () {
      // console.log(this.achievementInfo)
      this.loading = true
      if (this.achievementInfo.achievementfaceimg !== '' && this.achievementInfo.achievementfaceimg !== null) {
        this.$http.post('/admin/addAchievement', this.achievementInfo).then(res => {
          // console.log(res)
          this.loading = false
          if (res.data.code === 200) {
            this.$notify.success({
              title: '成功',
              message: '操作成功'
            })
            // this.getcarouselList()
            this.addDialogVisable = false
            this.editDialogVisable = false
          } else {
            this.$notify.error({
              title: '失败',
              message: '操作失败'
            })
          }
          this.getAchievement()
        }).catch(error => {
          console.log(error)
          this.$notify.warn({
            title: '警告',
            message: '修改失败'
          })
        })
      } else {
        this.$notify.warn({
          title: '警告',
          message: '请选择封面图片'
        })
      }
      this.achievementInfo = {}
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    filterTag  (value, row) {
      return row.achievementtype === value
    },
    // 修改操作
    editSelection () {
      if (this.multipleSelection.length === 1) {
        // console.log(this.multipleSelection[0])
        this.QiniuData.fileList = []
        this.QiniuData.moreFileList = []
        this.achievementInfo = this.multipleSelection[0]
        if (this.achievementInfo.achievementfaceimg !== '' && this.achievementInfo.achievementfaceimg !== null) {
          this.QiniuData.fileList.push({
            name: this.achievementInfo.achievementfaceimg,
            url: this.achievementInfo.achievementfaceimg
          })
        }
        if (this.achievementInfo.achievementshowimgs !== '' && this.achievementInfo.achievementshowimgs !== null) {
          const imgsUrl = this.achievementInfo.achievementshowimgs.split(',')
          for (let index = 0; index < imgsUrl.length; index++) {
            this.QiniuData.moreFileList.push({
              name: imgsUrl[index],
              url: imgsUrl[index]
            })
          }
        }
        this.editDialogVisable = true
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    // 删除单张
    handleRemoveFaceImg (file, fileList) {
      this.achievementInfo.achievementfaceimg = ''
      this.QiniuData.fileList = []
    },
    handleRemove (file, fileList) {
      // console.log(file)
      // console.log(fileList)
      const _urlList = []
      for (let index = 0; index < fileList.length; index++) {
        const _url = fileList[index].name
        _urlList.push(_url)
      }
      this.achievementInfo.achievementshowimgs = _urlList.toString()
      // this.carosuelInfo.articlecarouselimg = ''
    },
    handlePreview (file) {
      // console.log(file)
      this.listCardImg = file.url
      this.showImgsDialogVisible = true
      // this.achievementInfo.achievementfaceimg = file.url
      // this.faceImgDialogVisible = true
    },
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取token数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    beforeAvatarUpload (file) {
      const isPNG = file.type === 'image/png'
      const isJPEG = file.type === 'image/jpeg'
      const isJPG = file.type === 'image/jpg'
      const isGIF = file.type === 'image/gif'
      // 本地测试8M
      // const isLt2M = file.size / 1024 / 1024 < 8
      if (!isPNG && !isJPEG && !isJPG && !isGIF) {
        this.$message.error('上传头像图片只能是 jpg、png、jpeg、gif 格式!')
        return false
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      //   return false
      // }
      this.QiniuData.data.key = `${file.name}-sf`
    },
    // 上传更多图片 成功回调函数
    uploadListImgSuccess  (response, file, fileList) {
      // console.log(fileList)
      // console.log(response)
      const _urlList = []
      let _url = ''
      this.achievementInfo.achievementshowimgs = []
      for (let index = 0; index < fileList.length; index++) {
        if ((fileList[index].name).indexOf(this.QiniuData.qiniuaddr) === -1) {
          _url = this.QiniuData.qiniuaddr + fileList[index].name + '-sf'
        } else {
          _url = fileList[index].name
        }
        _urlList.push(_url)
      }
      this.achievementInfo.achievementshowimgs = _urlList.toString()
      this.loading = false
    },
    uploadSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.fileList = []
      this.achievementInfo.achievementfaceimg = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: this.achievementInfo.achievementfaceimg
      })
      this.loading = false
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        this.loading = true
        this.$http.post('/admin/deleteAchievement', this.multipleSelection).then(res => {
          this.loading = false
          this.getAchievement()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
    this.achievementInfo = {}
    this.getQiniuToken()
    this.getAchievement()
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
