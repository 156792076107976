<template>
<!-- 党工办事服务 -->
    <div class="zxfw_zxly_container" v-loading.lock="loading">
        <div class="zxfw_zxly_title">
            <div class="zxfw_zxly_title_ch">
                党工办事服务
            </div>
            <div class="dot"></div>
            <div class="zxfw_zxly_title_en">
                WORK SERVICE
            </div>
        </div>
        <div class="zxfw_zxly_form_container">
            <el-form ref="form" :model="adviseForm" :rules="rules" label-width="60px">
                <el-form-item label="标题" prop="adviseTitle">
                    <el-input v-model="adviseForm.adviseTitle"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="adviseContent">
                    <el-input type="textarea" v-model="adviseForm.adviseContent"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">立即提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      adviseForm: {
        adviseTitle: '',
        adviseContent: ''
      },
      rules: {
        adviseTitle: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ],
        adviseContent: [
          { required: true, message: '请填写内容', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$http.post('/advise/add', this.adviseForm).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              alert('提交成功')
            } else {
              alert('提交失败')
            }
            this.adviseForm = {
              adviseTitle: '',
              adviseContent: ''
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.zxfw_zxly_container {
    width: 100%;
    margin: 5px auto;
    float: left;
    border: 1px solid lightgrey;
    border-radius: 5px;
    .zxfw_zxly_title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        letter-spacing: 0.1rem;
        .zxfw_zxly_title_ch, .zxfw_zxly_title_en {
            font-size: 200%;
            font-weight: 200;
        }
        .zxfw_zxly_title_ch {
            color: #000;
        }
        .zxfw_zxly_title_en {
            color: orangered;
        }
        .dot {
            height: 10px;
            width: 10px;
            margin: 0 10px;
            border-radius: 50%;
            background-color: orangered;
        }
    }
    .zxfw_zxly_form_container {
        width: 100%;
        padding: 10px 0;
        min-height: 415px;
        display: flex;
        justify-content: center;
        align-items: center;
        .el-form{
            width: 80%;
        }
    }
}
@media screen and (max-width: 768px) and (min-width: 500px){
  .zxfw_zxly_container {
        width: 60%;
        margin: 5px auto;
        float: left;
        .zxfw_zxly_title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            letter-spacing: 0.1rem;
            .zxfw_zxly_title_ch {
                color: #000;
                font-size: 150%;
                font-weight: 200;
            }
            .zxfw_zxly_title_en {
                color: orangered;
                font-size: 150%;
                font-weight: 200;
            }
            .dot {
                height: 10px;
                width: 10px;
                margin: 0 10px;
                border-radius: 50%;
                background-color: orangered;
            }
        }
        .zxfw_zxly_form_container {
            width: 100%;
            padding: 10px 0;
            min-height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            .el-form{
                width: 80%;
            }
        }
  }
}
@media screen and (max-width: 500px) {
    .zxfw_zxly_container {
        width: 100%;
        margin: 5px auto;
        float: left;
        .zxfw_zxly_title {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            // background-color: red;
            letter-spacing: 0.1rem;
            .zxfw_zxly_title_ch {
                color: #000;
                font-size: 120%;
                font-weight: 200;
            }
            .zxfw_zxly_title_en {
                color: orangered;
                font-size: 120%;
                font-weight: 200;
            }
            .dot {
                height: 10px;
                width: 10px;
                margin: 0 10px;
                border-radius: 50%;
                background-color: orangered;
            }
        }
        .zxfw_zxly_form_container {
            width: 100%;
            padding: 10px 2px;
            min-height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            .el-form{
                width: 80%;
            }
        }
    }
}
</style>
