<template>
    <div class="gyss_container">
        <div class="tabs_btns_container">
            <div class="gyss_btn" v-for="item in zxnsInfo" :key="item.typeId" @click="changeType(item.typeId)">
                {{item.typeName}}
            </div>
        </div>
        <div class="tabs_content_container">
            <div :class="tabTypeId == 1 ? 'tabs_content_item animate__animated animate__slideInLeft' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="rczp_container">
                    <div class="rczp_search_container">
                        <div class="rczp_search_body">
                             <el-input placeholder="请输入岗位" v-model="searchInfo.positionname" class="input-with-select">
                                <el-select v-model="searchInfo.positionplace" slot="prepend" placeholder="请选择地点" style="width: 150px">
                                    <el-option :label="item" :value="item" v-for="item in place" :key="item.index"></el-option>
                                </el-select>
                                <el-button slot="append" icon="el-icon-search" @click="searchPosition"></el-button>
                            </el-input>
                        </div>
                    </div>
                    <div class="rczp_table_container">
                        <el-table
                            :data="tableData"
                            style="width: 100%">
                            <el-table-column type="expand">
                                <template slot-scope="props">
                                    <el-form label-position="left" inline class="demo-table-expand">
                                        <el-form-item label="职位名称:">
                                            <span>{{ props.row.positionname }}</span>
                                        </el-form-item>
                                        <el-form-item label="招聘人数:">
                                            <span>{{ props.row.positionnumber }}</span>
                                        </el-form-item>
                                        <el-form-item label="工作地点:">
                                            <span>{{ props.row.positionplace }}</span>
                                        </el-form-item>
                                        <el-form-item label="发布时间:">
                                            <span>{{ props.row.createtime }}</span>
                                        </el-form-item>
                                        <el-form-item label="发布时间:">
                                            <span>{{ props.row.createtime }}</span>
                                        </el-form-item>
                                        <el-form-item label="更新日期:">
                                            <span>{{ props.row.edittime }}</span>
                                        </el-form-item>
                                         <el-form-item label="任职技能:">
                                            <span  v-html="props.row.positionskill"></span>
                                        </el-form-item>
                                    </el-form>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="职位名称"
                                prop="positionname">
                            </el-table-column>
                            <el-table-column
                                label="招聘人数"
                                align="center"
                                prop="positionnumber">
                            </el-table-column>
                            <el-table-column
                                label="工作地点"
                                align="center"
                                prop="positionplace">
                            </el-table-column>
                            <el-table-column
                                label="发布时间"
                                prop="createtime">
                            </el-table-column>
                             <!-- <el-table-column
                                label="投递简历">
                                <template slot-scope="scope">
                                    <el-button type="primary" @click="uploadFile(scope.row)">上传简历</el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                    </div>
                </div>
            </div>
            <div  :class="tabTypeId == 2 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutRight'">
                <!-- 人才理念 -->
                <div class="rcnl_container">
                    <div v-html="rclnInfo.text" class="rcnl_content_container"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      zxnsInfo: [
        {
          typeName: '人才招聘',
          typeId: 1
        },
        {
          typeName: '人才理念',
          typeId: 2
        }
      ],
      tabTypeId: 1,
      rclnInfo: {},
      tableData: [],
      place: [],
      searchInfo: {}
    }
  },
  methods: {
    uploadFile (data) {
      console.log(data)
    },
    searchPosition () {
    //   console.log(this.searchInfo)
      if (this.searchInfo.positionname !== '' && this.searchInfo.positionname !== null && this.searchInfo.positionname !== undefined) {
        this.searchInfo.positionname = this.searchInfo.positionname.replaceAll(/\s/g, '')
      }
      this.getPositionByLimit()
    },
    getPositionByLimit () {
      this.$http.post('/admin/getPositionByLimit', this.searchInfo).then(res => {
        this.tableData = res.data.data
      })
    },
    getPosition () {
      this.$http.get('/admin/getPosition').then(res => {
        this.tableData = res.data.data
        this.tableData.forEach(res => {
          if (!this.place.includes(res.positionplace)) {
            this.place.push(res.positionplace)
          }
        })
      })
    },
    getSimpleText () {
      this.$http.post('/admin/getSimpleTextByType/1').then(res => {
        if (res.data.data.length > 0) {
          this.rclnInfo = res.data.data[0]
        }
      })
    },
    changeType (typeId) {
    //   console.log(typeId)
      this.tabTypeId = typeId
    },
    searchClick () {
      alert('click')
    }
  },
  created () {
    this.getSimpleText()
    this.getPosition()
  }
}
</script>

<style lang="less" scoped>
// 新的
.gyss_container {
    width: 100%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
}
.tabs_btns_container {
    width: 100%;
    //  background-color: red;
    padding: 10px 5px;
    font-size: 100%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-around;
}
.gyss_btn {
    padding: 10px 20px;
    background-color: transparent;
    text-align: center;
    border-radius: 5px;
    color: #808080;
    cursor: pointer;
}
.gyss_btn:hover{
    background-color: #F5F5F5;
    color: #000;
}
.gyss_btn_click{
    background-color: #F5F5F5;
    color: #000;
}
.tabs_content_container {
    width: 100%;
    min-height: 200px;
    padding: 5px;
    overflow: hidden;
}
.tabs_content_item_hide {
    width: 100%;
    height:0;
    // overflow: hidden;
}
.tabs_content_item {
    width: 100%;
    min-height: 200px;
}
@media screen and (max-width: 500px){
  .tabs_btns_container {
      width: 100%;
      //  background-color: red;
      padding: 5px;
      font-size: 10px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
  }
  .gyss_btn {
      padding: 10px 10px;
      background-color: #F5F5F5;
      text-align: center;
      border-radius: 5px;
      color: #808080;
      cursor: pointer;
  }
  .gyss_btn:hover{
      background-color: #F5F5F5;
      color: #000;
  }
  .gyss_btn_click{
      background-color: #F5F5F5;
      color: #000;
  }
  .tabs_content_container {
      width: 100%;
      min-height: 200px;
      padding: 5px;
      overflow: hidden;
  }
  .tabs_content_item {
      width: 100%;
      min-height: 200px;
  }
}
.rcnl_container {
    width: 100%;
    min-height: 400px;
    // background-color: red;
}
.rcnl_content_container {
    width: 100%;
    min-height: 1px;
    // background-color: yellow;
}
.rcnl_img_container {
    width: 100%;
    min-height: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: blue;
}
.rcnl_img_container .el-image{
    width: 80%;
}
@media screen and (max-width: 500px){
    .rcnl_container {
        width: 100%;
        min-height: 200px;
        // background-color: red;
    }
    .rcnl_img_container .el-image{
        width: 100%;
    }
}
.demo-table-expand {
   font-size: 0;
}
.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
// 是搜索框
.rczp_search_container {
    width: 100%;
    padding: 5px 2px;
}
.rczp_search_body {
    max-width: 800px;
    margin: 0 auto;
}
</style>
