<template>
<!-- 业绩展示 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="名称"
                width="300"
                sortable=""
                prop="name">
                </el-table-column>
                <el-table-column
                label="联系电话"
                prop="tel">
                </el-table-column>
                <el-table-column
                label="地址"
                prop="address">
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia -->
        <el-dialog title="添加公司信息" :visible.sync="addDialogVisable" width="600px" @close="dialogClose" :close-on-click-modal="false" v-loading.lock="dialogLoading" element-loading-text="上传中">
            <el-form :model="companyInfo" ref="companyFormRef" :rules="companyFormRules">
                <el-form-item label="公司名称" label-width="100px" prop="name">
                    <el-input type="text" v-model="companyInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话"  label-width="100px" prop="name">
                    <el-input type="text" v-model="companyInfo.tel"></el-input>
                </el-form-item>
                <el-form-item label="联系地址" label-width="100px" prop="address">
                    <el-select v-model="companyInfo.province" placeholder="省份" style="width:100px;" @change="selectProvince">
                        <el-option v-for="item in provinceList" :key="item.value" :label="item.province" :value="item.province"></el-option>
                    </el-select>
                    <el-select v-model="companyInfo.city" placeholder="市" style="width:100px; margin: 0 5px;">
                        <el-option v-for="item in cityOptions" :key="item.value" :label="item.city" :value="item.city"></el-option>
                    </el-select>
                    <el-input type="text" v-model="companyInfo.address" placeholder="详细地址" style="width:250px;" prop="address"></el-input>
                </el-form-item>
                <!-- <el-form-item label="详细地址"  label-width="100px">
                    <el-input type="text" v-model="companyInfo.address"></el-input>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="修改公司信息" :visible.sync="editDialogVisable" width="600px"  @close="dialogClose" :close-on-click-modal="false" v-loading.lock="dialogLoading" element-loading-text="上传中">
            <el-form :model="companyInfo" ref="companyFormRef" :rules="companyFormRules">
                <el-form-item label="公司名称" label-width="100px" prop="name">
                    <el-input type="text" v-model="companyInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话"  label-width="100px" prop="name">
                    <el-input type="text" v-model="companyInfo.tel"></el-input>
                </el-form-item>
                <el-form-item label="联系地址" label-width="100px" prop="address">
                    <el-select v-model="companyInfo.province" placeholder="省份" style="width:100px;" @change="selectProvince">
                        <el-option v-for="item in provinceList" :key="item.value" :label="item.province" :value="item.province"></el-option>
                    </el-select>
                    <el-select v-model="companyInfo.city" placeholder="市" style="width:100px; margin: 0 5px;">
                        <el-option v-for="item in cityOptions" :key="item.value" :label="item.city" :value="item.city"></el-option>
                    </el-select>
                    <el-input type="text" v-model="companyInfo.address" placeholder="详细地址" style="width:250px;" prop="address"></el-input>
                </el-form-item>
                <!-- <el-form-item label="详细地址"  label-width="100px">
                    <el-input type="text" v-model="companyInfo.address"></el-input>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitEditDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import map from '../assets/json/map.json'
export default {
  data () {
    return {
      dialogLoading: false,
      provinceList: [],
      cityList: [],
      cityOptions: [],
      companyInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      companyFormRules: {
        name: [
          { required: true, message: '请输入分公司名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在3到10个字符之间', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { min: 5, max: 13, message: '长度在5到13个字符之间', trigger: 'blur' }
        ],
        province: [
          { required: true, message: '其选择省份', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '其选择市', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    dialogClose () {
      this.companyInfo = {}
    },
    selectProvince (val) {
      this.cityOptions = []
      this.provinceList.forEach((item) => {
        if (item.province === val) {
          this.cityOptions = item.children
        }
      })
      console.log(this.cityOptions)
    },
    getMapJSON () {
      // console.log(map)
      this.provinceList = []
      this.cityList = []
      map.forEach((item) => {
        if (item.value.match(/000$/)) {
          this.provinceList.push({
            province: item.name,
            value: item.value
          })
        } else if (item.value.match(/00$/)) {
          this.cityList.push({
            city: item.name,
            value: item.value
          })
        }
      })
      for (const index in this.provinceList) {
        this.provinceList[index].children = []
        for (const index1 in this.cityList) {
          if (this.provinceList[index].value.slice(0, 2) === this.cityList[index1].value.slice(0, 2)) {
            this.provinceList[index].children.push(this.cityList[index1])
          }
        }
      }
    },
    getCompanyList () {
      this.loading = true
      this.$http.get('/admin/getCompanyList').then(res => {
        // console.log(res)
        this.loading = false
        this.tableData = res.data.data
      })
    },
    // editDialog
    submitEditDialog () {
      console.log(this.companyInfo)
      this.$refs.companyFormRef.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.$http.post('/admin/updateCompay', this.companyInfo).then(res => {
            this.dialogLoading = true
            if (res.data.code === 200) {
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.companyFormRef.resetFields()
              this.getCompanyList()
              this.editDialogVisable = false
              this.dialogLoading = false
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
              this.dialogLoading = false
            }
          })
        }
      })
    },
    // 提交dialog内容 addDialog
    submitDialog () {
      console.log(this.companyInfo)
      this.dialogLoading = true
      this.$refs.companyFormRef.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.$http.post('/admin/addCompany', this.companyInfo).then(res => {
            if (res.data.code === 200) {
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.getCompanyList()
              this.$refs.companyFormRef.resetFields()
              this.dialogLoading = false
              this.addDialogVisable = false
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
          })
        }
      })
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 修改操作
    editSelection () {
      if (this.multipleSelection.length === 1) {
        console.log(this.multipleSelection[0])
        this.companyInfo = this.multipleSelection[0]
        this.editDialogVisable = true
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        this.loading = true
        this.$http.post('/admin/deleteCompany', this.multipleSelection).then(res => {
          this.loading = false
          this.getCompanyList()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
  },
  created () {
    this.getCompanyList()
    this.getMapJSON()
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
