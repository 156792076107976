<template>
<!-- 成长历程 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="日期"
                width="200"
                sortable
                prop="czlcdate">
                </el-table-column>
                <el-table-column
                label="内容"
                prop="czlctext">
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia  -->
        <el-dialog title="添加成长历程" :visible.sync="addDialogVisable" width="1000px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="czlcInfo" ref="addDialogRef" :rules="addDialogRules">
                <el-form-item label="选择时间"  label-width="100px" prop="czlcdate">
                    <el-date-picker
                      v-model="czlcInfo.czlcdate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="内容描述" label-width="100px" prop="czlctext">
                    <editor-bar  v-model="czlcInfo.czlctext" :isClear="isClear" @change="change"></editor-bar>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="修改成长历程" :visible.sync="editDialogVisable"  width="1000px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="editCzlcInfo" ref="addDialogRef" :rules="addDialogRules">
                <el-form-item label="选择时间"  label-width="100px" prop="honourdate">
                    <el-date-picker
                      v-model="editCzlcInfo.czlcdate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="内容描述" label-width="100px" prop="czlctext">
                    <editor-bar  v-model="editCzlcInfo.czlctext" :isClear="isClear" @change="change"></editor-bar>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="SubmitEditDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import EditorBar from './WangEditor/Editor.vue'
export default {
  components: { 'editor-bar': EditorBar },
  data () {
    return {
      isClear: true,
      listCardImg: '',
      showImgsDialogVisible: false,
      editCzlcInfo: {},
      czlcInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        moreFileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      },
      addDialogRules: {
        czlctext: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
        czlcdate: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    // 富文本编辑器change事假
    change (val) {
      // console.log(val)
    },
    getCzlc () {
      this.loading = true
      this.$http.get('/admin/getCzlc').then(res => {
        console.log(res)
        this.loading = false
        this.tableData = res.data.data
      })
    },
    // editDialog
    SubmitEditDialog () {
      this.loading = true
      // console.log(this.ssryInfo)
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.$http.post('/admin/editCzlc', this.editCzlcInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.editDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
            this.getCzlc()
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    // 提交dialog内容 addDialog
    submitDialog () {
      this.loading = true
      // console.log(this.ssryInfo)
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.$http.post('/admin/addCzlc', this.czlcInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.addDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
            this.getCzlc()
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    dialogClose () {
      this.czlcInfo = {}
      this.editCzlcInfo = {}
      this.QiniuData.fileList = []
      this.loading = false
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    filterTag  (value, row) {
      return row.honourtype === value
    },
    // 修改操作
    editSelection () {
      if (this.multipleSelection.length === 1) {
        this.editCzlcInfo = this.multipleSelection[0]
        this.editDialogVisable = true
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    // 删除单张
    handleRemove (file, fileList) {
      this.ssryInfo.honourimgurl = ''
      this.QiniuData.fileList = []
    },
    handlePreview (file) {
      console.log(file)
      this.showImgsDialogVisible = true
      this.listCardImg = file.url
    },
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取token数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    beforeAvatarUpload (file) {
      const isPNG = file.type === 'image/png'
      const isJPEG = file.type === 'image/jpeg'
      const isJPG = file.type === 'image/jpg'
      const isGIF = file.type === 'image/gif'
      // 本地测试8M
      // const isLt8M = file.size / 1024 / 1024 < 8
      if (!isPNG && !isJPEG && !isJPG && !isGIF) {
        this.$message.error('上传头像图片只能是 jpg、png、jpeg、gif 格式!')
        return false
      }
      // if (!isLt8M) {
      //   this.$message.error('上传头像图片大小不能超过 8MB!')
      //   return false
      // }
      this.QiniuData.data.key = `${file.name}-sf`
    },
    uploadSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.fileList = []
      this.ssryInfo.honourimgurl = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: this.ssryInfo.honourimgurl
      })
      this.loading = false
    },
    uploadEditSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.fileList = []
      this.editSsryInfo.honourimgurl = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: `${this.QiniuData.qiniuaddr}${response.key}`
      })
      this.loading = false
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        this.loading = true
        this.$http.post('/admin/deleteCzlc', this.multipleSelection).then(res => {
          this.loading = false
          this.getCzlc()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
    // this.getQiniuToken()
  },
  created () {
    this.getCzlc()
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
