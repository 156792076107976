<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar">
    </div>
    <div ref="editor" class="editor_text">
    </div>
  </div>
</template>

<script>
import E from 'wangeditor'
export default {
  name: 'editoritem',
  data () {
    return {
    // uploadPath,
      editor: null,
      info_: null,
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        moreFileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      },
      // qiniu-js
      config: {
        // 表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false
        useCdnDomain: true,
        // 选择上传域名区域；当为 null 或 undefined 时，自动分析上传域名区域。  我这里是华东区
        region: this.$qiniu.region.z2,
        // 配置好的七牛云域名  如   https://cdn.qniyun.com/
        domain: 'https://ssjt.buildone.com.cn/',
        chunkSize: 100,
        // 直传还是断点续传方式，true为直传
        forceDirect: true
      },
      putExtra: {
        // 文件原始文件名
        fname: '',
        params: {},
        mimeType: []
      }
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    isClear: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isClear (val) {
      // 触发清除文本域内容
      if (val) {
        this.editor.txt.clear()
        this.info_ = null
      }
    },
    value: function (value) {
      if (value !== this.editor.txt.html()) {
        this.editor.txt.html(this.value)
      }
    }
    // value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
  },
  mounted () {
    this.seteditor()
    this.getQiniuToken()
    this.editor.txt.html(this.value)
  },
  methods: {
    // 上传
    uploadQiNiu (file) {
    },
    // 获取token
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取token数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    seteditor () {
      const _this = this
      this.editor = new E(this.$refs.toolbar, this.$refs.editor)
      this.editor.config.uploadImgShowBase64 = false // base 64 存储图片
      this.editor.config.uploadImgServer = this.QiniuData.domain// 配置服务器端地址(自己更换为自己项目的服务器)
      this.editor.config.uploadImgHeaders = { }// 自定义 header
      this.editor.config.uploadFileName = 'file' // 后端接受上传文件的参数名
      this.editor.config.uploadImgMaxSize = 4 * 1024 * 1024 // 将图片大小限制为 4M
      this.editor.config.uploadImgMaxLength = 3 // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000 // 设置超时时间

      //  配置菜单
      this.editor.config.menus = [
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'indent',
        'lineHeight',
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        // 'video', // 插入视频
        // 'code', // 插入代码
        'undo', // 撤销
        'splitLine',
        'redo', // 重复
        'fullscreen' // 全屏
      ]

      this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // console.log(resultFiles)
        const file = resultFiles[0]
        return new Promise((resolve, reject) => {
          _this.putExtra.fname = file.name
          var observable = _this.$qiniu.upload(file, file.name, _this.QiniuData.data.token, _this.putExtra, _this.config)
          observable.subscribe({
            next: (result) => {
              // 主要用来展示进度
              _this.np.start()
            },
            error: (err) => {
              // 上传错误后触发
              console.log(err)
              reject(err)
              _this.np.done()
            },
            complete: (result) => {
              // 上传成功后触发。包含文件地址。
              // console.log(result)
              resolve(result)
              _this.np.done()
              insertImgFn(_this.QiniuData.qiniuaddr + file.name + '-sf')
            }
          })
        })
      }
      this.editor.config.onchange = (html) => {
        this.info_ = html // 绑定当前逐渐地值
        this.$emit('change', this.info_) // 将内容同步到父组件中
      }
      // 创建富文本编辑器
      this.editor.create()
    }
  }
}
</script>

<style lang="css">
.editor {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 0;
}
.toolbar {
  border: 1px solid #ccc;
}
.editor_text {
  border: 1px solid #ccc;
  min-height: 400px;
}
</style>
