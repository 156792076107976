<template>
<!-- 党工办事服务 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <span>标题：</span>
            <el-input v-model="queryParams.adviseTitle" clearable @clear="getTableData()" @blur="getTableData()" placeholder="可输入标题关键字查询"></el-input>
            <span>状态：</span>
            <el-select v-model="queryParams.state" @change="getTableData()">
                <el-option label="未读" value="0"></el-option>
                <el-option label="已读" value="1"></el-option>
            </el-select>
            <el-button type="primary" @click="getTableData()" icon="el-icon-search"></el-button>
        </div>
        <div class="sylb_table_container">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark"  fit style="width: 100%" height="675px" >
                <el-table-column label="标题" width="300" prop="adviseTitle" align="center"></el-table-column>
                <el-table-column label="内容" prop="adviseContent"></el-table-column>
                <el-table-column label="状态" prop="state" width="100px" align="center">
                    <template slot-scope="scope">
                    <span>{{scope.row.state=='0' ? '未读' : '已读'}}</span>
                </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="250px">
                    <template slot-scope="scope">
                        <el-button type="success" size="small" icon="el-icon-view" @click="readThisContent(scope.row)" :disabled="scope.row.state == '1'">批阅</el-button>
                        <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteThisContent(scope.row.adviseId)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      queryParams: {
        adviseTitle: '',
        state: '0'
      },
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    // 获取数据
    getTableData () {
      this.loading = true
      this.$http.get('/advise/list', { params: this.queryParams }).then(res => {
        this.loading = false
        this.tableData = res.data.data
      })
    },

    // 删除
    deleteThisContent (adviseId) {
      this.$confirm('是否确定删除此服务留言, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        this.$http.delete('/advise/delete/' + adviseId).then(res => {
          this.loading = false
          this.getTableData()
          this.$notify.success({
            title: '成功',
            message: '已删除此留言！'
          })
        }).catch(error => {
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: error
          })
        })
      }).catch(() => {
      })
    },
    // 批阅
    readThisContent (row) {
      this.$confirm('是否确定已经阅读此服务留言, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        const temp = { ...row }
        temp.state = '1'
        this.$http.put('/advise/update', temp).then(res => {
          this.loading = false
          this.getTableData()
          this.$notify.success({
            title: '成功',
            message: '已阅读此留言！'
          })
        }).catch(error => {
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: error
          })
        })
      }).catch(() => {
      })
    }
  },
  mounted () {
  },
  created () {
    this.getTableData()
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .el-input{
    width: 500px;
    margin-right: 15px;
  }
  .el-select{
    margin-right: 15px;
  }
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
