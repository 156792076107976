<template>
<!-- 业绩展示详情 -->
    <div class="articleDetail_container">
        <div class="articleDetail_nav_container">
            <el-page-header @back="goBack"></el-page-header>
        </div>
        <div class="articleDetail_content_container">
            <div class="articleDetail_title_container">
                {{achievementInfo.achievementname}}
            </div>
            <div class="articleDetail_text_container">
                <div class="text_item_container" v-if="achievementInfo.achievementtext">
                    {{achievementInfo.achievementtext}}
                </div>
                <div class="imgs_item_container" v-for="item in achievementInfo.achievementshowimgs" :key="item">
                    <el-image v-if="item" :src="item" fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <el-image v-else :src="achievementInfo.achievementfaceimg" fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </div>
            </div>
            <div class="articleDetail_next_container">
                <el-button type="text" icon="el-icon-back">上一篇</el-button>
                <el-button type="text">下一篇<i class="el-icon-right el-icon--right"></i></el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      // data
      achievementInfo: {}
    }
  },
  methods: {
    // getArticleDetail () {
    //   const JSONArticleDetail = window.sessionStorage.getItem('articleDetail')
    //   this.articleDetail = JSON.parse(JSONArticleDetail)
    // },
    goBack () {
      this.$router.go(-1)
    }
  },
  created () {
    const data = JSON.parse(window.sessionStorage.getItem('jdalDetails'))
    data.achievementshowimgs = data.achievementshowimgs.split(',')
    this.achievementInfo = data
    // console.log(this.achievementInfo)
    // created todo
  }
}
</script>

<style lang="less" scoped>
.articleDetail_container {
    width: 100%;
    min-height: 450px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
    padding: 5px;
}
.articleDetail_nav_container {
    width: 100%;
    font-size: 100% !important;
    padding: 5px 0;
    border-bottom: 1px solid lightgrey;
}
.articleDetail_content_container {
    width: 100%;
    min-height: 400px;
    padding: 10px 0 50px 0;
    position: relative;
}
.articleDetail_title_container {
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 150%;
    letter-spacing: .1rem;
}
.articleDetail_text_container {
    width: 100%;
    padding: 5px;
}
.imgs_item_container {
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.text_item_container {
    width: 100%;
    font-size: 100%;
    line-height: 30px;
    text-indent: 2em;
}
.articleDetail_next_container {
    width: 100%;
    height: 50px;
    padding: 0 5px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
