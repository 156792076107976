<template>
<!-- \\Desktop-11fhehc\共享  共享 (file://Desktop-11fhehc/共享)-->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addCarouselBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteCarosuel()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="标题"
                width="500">
                <template slot-scope="scope">{{ scope.row.articletitle }}</template>
                </el-table-column>
                <el-table-column
                label="日期"
                width="160"
                sortable>
                    <template slot-scope="scope">{{ scope.row.articledate }}</template>
                </el-table-column>
                <el-table-column
                prop="articlecarouselimg"
                label="轮播图">
                </el-table-column>
                <el-table-column
                prop="articlecarouselstate"
                label="状态"
                sortable
                width="100">
                    <template slot-scope="scope">
                        <el-switch
                          v-model="scope.row.articlecarouselstate"
                          active-value="1"
                          inactive-value="0"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          disabled=""
                          :change="changeCarouselState(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                width="150"
                prop="articlecarouseltype"
                label="类型"
                sortable
                fixed="right"
                align="center"
                :filters="carouselTypeInfo"
                :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.articlecarouseltype === '0'" type="success">首页轮播</el-tag>
                    <el-tag v-if="scope.row.articlecarouseltype === '1'" type="info">首页资讯轮播</el-tag>
                    <el-tag v-if="scope.row.articlecarouseltype === '2'" type="warning">山水党建轮播</el-tag>
                    <el-tag v-if="scope.row.articlecarouseltype === '3'" type="danger">新闻轮播</el-tag>
                    <el-tag v-if="scope.row.articlecarouseltype === '4'" >资讯轮播</el-tag>
                </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia lunbo -->
        <el-dialog title="添加轮播图" :visible.sync="addDialogVisable" width="600px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="carosuelInfo">
                <el-form-item label="轮播图类型" label-width="100px">
                    <el-select v-model="carosuelInfo.articlecarouseltype" placeholder="请选择轮播图类型">
                        <el-option  v-for="item in carouselTypeInfo" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="文章链接" label-width="100px">
                    <el-select v-model="carosuelInfo.articleid" placeholder="请选择文章" filterable  style="width: 450px">
                        <el-option v-for="item in articleSelectionInfo" :key="item.articleid" :label="item.articletitle" :value="item.articleid"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="轮播图介绍"  label-width="100px">
                    <el-input type="textarea" v-model="carosuelInfo.articlecarouseltext" maxlength="150" rows="5"></el-input>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="QiniuData.domain"
                    :data="QiniuData.data"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :on-error="handleError"
                    :on-success="uploadSuccess"
                    :before-upload="beforeAvatarUpload"
                    :file-list="QiniuData.fileList"
                    :auto-upload="true">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="addCarouselFunc()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改轮播图 -->
        <el-dialog title="修改轮播图" :visible.sync="editDialogVisable" width="600px" :close-on-click-modal="false"  @close="dialogClose">
            <el-form :model="carosuelInfo">
                <el-form-item label="轮播图类型" label-width="100px">
                    <el-select v-model="carosuelInfo.articlecarouseltype" placeholder="请选择轮播图类型">
                        <el-option  v-for="item in carouselTypeInfo" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="文章链接" label-width="100px">
                    <el-input type="text" v-model="carosuelInfo.articletitle" disabled=""></el-input>
                </el-form-item>
                <el-form-item label="轮播图介绍"  label-width="100px">
                    <el-input type="textarea" v-model="carosuelInfo.articlecarouseltext" maxlength="150" rows="5"></el-input>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="QiniuData.domain"
                    :data="QiniuData.data"
                    :on-preview="handlePreview"
                    :on-error="handleError"
                    :on-remove="handleRemove"
                    :on-success="uploadSuccess"
                    :before-upload="beforeAvatarUpload"
                    :file-list="QiniuData.fileList"
                    :auto-upload="true">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="addCarouselFunc()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {
      showImgsDialogVisible: false,
      listCardImg: '',
      loading: false,
      // 轮播类型
      carouselTypeInfo: [
        {
          value: '0',
          text: '首页轮播 ',
          label: '首页轮播 '
        },
        {
          value: '1',
          text: '首页资讯轮播 ',
          label: '首页资讯轮播 '
        }
      ],
      deleteCarosuelInfo: [],
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      articleSelectionInfo: [],
      carosuelInfo: {},
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      }
    }
  },
  methods: {
    handleError (file) {
      console.log(file)
      this.$message.error('上传错误，请刷新页面重新上传')
    },
    dialogClose () {
      this.carosuelInfo = {}
      this.QiniuData.fileList = []
      this.loading = false
    },
    changeCarouselState (row) {
      // console.log(row)
    },
    getArticleNullCarosuel () {
      this.$http.get('/admin/getArticleNullCarosuel').then(res => {
        // console.log(res)
        this.loading = false
        this.articleSelectionInfo = res.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    getcarouselList () {
      this.loading = true
      this.$http.get('/admin/getHomePageCarousel').then(res => {
        console.log(res)
        this.loading = false
        this.tableData = res.data.data
      }).catch(error => {
        console.log(error)
      })
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    filterTag  (value, row) {
      // console.log(value)
      // console.log(row)
      return row.articlecarouseltype === value
    },
    editSelection () {
      this.QiniuData.fileList = []
      if (this.multipleSelection.length === 1) {
        this.editDialogVisable = true
        this.getArticleNullCarosuel()
        // console.log(this.multipleSelection[0])
        this.carosuelInfo = this.multipleSelection[0]
        // this.articleSelectionInfo.push(this.carosuelInfo)
        if (this.carosuelInfo.articlecarouselimg !== '' && this.carosuelInfo.articlecarouselimg !== null) {
          this.QiniuData.fileList.push({
            name: this.carosuelInfo.articlecarouselimg,
            url: this.carosuelInfo.articlecarouselimg
          })
        }
        // console.log(this.QiniuData.fileList)
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    addCarouselBtn () {
      this.getArticleNullCarosuel()
      this.addDialogVisable = true
    },
    // 提交 添加及修改 信息
    addCarouselFunc () {
      this.carosuelInfo.articlecarouselstate = '1'
      if (this.carosuelInfo.articlecarouselimg !== '' && this.carosuelInfo.articlecarouselimg !== null) {
        this.$http.post('/admin/editArticle', this.carosuelInfo).then(res => {
          // console.log(res)
          if (res.data.code === 200) {
            this.$notify.success({
              title: '成功',
              message: '操作成功'
            })
            this.getcarouselList()
            this.addDialogVisable = false
            this.editDialogVisable = false
          } else {
            this.$notify.error({
              title: '失败',
              message: '操作失败'
            })
            this.addDialogVisable = false
            this.editDialogVisable = false
          }
        }).catch(error => {
          console.log(error)
          this.$notify.warn({
            title: '警告',
            message: '修改失败'
          })
        })
      } else {
        this.$notify.warn({
          title: '警告',
          message: '请选择图片'
        })
      }
    },
    handleRemove (file, fileList) {
      // console.log(file, fileList)
      this.carosuelInfo.articlecarouselimg = ''
    },
    handlePreview (file) {
      // console.log(file)
      this.showImgsDialogVisible = true
      this.listCardImg = file.url
    },
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        // console.log(res)
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    beforeAvatarUpload (file) {
      const isPNG = file.type === 'image/png'
      const isJPEG = file.type === 'image/jpeg'
      const isJPG = file.type === 'image/jpg'
      // 本地测试8M
      // const isLt2M = file.size / 1024 / 1024 < 8
      if (!isPNG && !isJPEG && !isJPG) {
        this.$message.error('上传头像图片只能是 jpg、png、jpeg 格式!')
        return false
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      //   return false
      // }
      this.QiniuData.data.key = `${file.name}`
    },
    uploadSuccess (response, file, fileList) {
      console.log(fileList)
      this.QiniuData.fileList = []
      this.carosuelInfo.articlecarouselimg = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: `${this.QiniuData.qiniuaddr}${response.key}`
      })
    },
    deleteCarosuel () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        this.loading = true
        this.$http.post('/admin/deleteCarousel', this.multipleSelection).then(res => {
          this.loading = false
          this.getcarouselList()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
    this.getQiniuToken()
    this.getcarouselList()
    this.carosuelInfo = {}
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
    width: 100%;
    padding: 20px 5px;
    background-color: #FFFFFF;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.sylb_table_container {
    width: 100%;
    padding: 5px 0;
    margin: 5px 0;
}
</style>
