<template>
<!-- 宣传片 -->
    <div class="wysp-container" v-loading.lock="loading" element-loading-text="获取数据中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button  type="primary" size="small" icon="el-icon-edit" @click="editBtn()">替换视频</el-button>
            </el-button-group>
        </div>
        <div class="wysp_video_container">
            <div class="wysp_body_urls" v-if="this.playerOptions.sources[0].src">
                <video-player  class="video-player vjs-custom-skin" id="videoDiv"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    :webkit-playsinline="true"
                    customEventName="customstatechangedeventname">
                </video-player>
            </div>
        </div>
        <!-- tihuan  -->
        <el-dialog :close-on-click-modal="false"  v-loading.lock="loading"  element-loading-text="上传中" :visible.sync="addDialogVisable" width="800px">
            <el-form :model="videoInfo" ref="videoFormRef" :rules="videoFormRules">
                <el-form-item label="视频名称" label-width="100px" prop="videoname">
                    <el-input type="text" v-model="videoInfo.videoname"></el-input>
                </el-form-item>
                <el-form-item label="日期" label-width="100px" prop="videodate">
                    <el-date-picker
                        v-model="videoInfo.videodate"
                        type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传视频" label-width="100px" prop="videourl">
                    <el-upload
                        class="upload-demo"
                        :action="QiniuData.domain"
                        :data="QiniuData.data"
                        :on-preview="handlePreview"
                        :on-error="handleError"
                        :on-remove="handleRemove"
                        :on-success="uploadVideoSuccess"
                        :before-upload="beforeAvatarUpload"
                        :file-list="QiniuData.videoList">
                            <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="预览" label-width="100px">
                    <div class="priviewVideo_container">
                          <div class="priviewVideo_item">
                                <video-player  class="video-player vjs-custom-skin" id="videoDiv"
                                    ref="videoPlayer"
                                    :options="previewPlayerOptions"
                                    :playsinline="true"
                                    :webkit-playsinline="true"
                                    customEventName="customstatechangedeventname">
                                </video-player>
                          </div>
                        </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
  data () {
    return {
      // todo
      loading: false,
      addDialogVisable: false,
      // video Options
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      previewPlayerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      videoInfo: {},
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        moreFileList: [],
        videoList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      },
      videoFormRules: {
        videoname: [
          { required: true, message: '请输视频名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在3到15个字符之间', trigger: 'blur' }
        ],
        videodate: [
          { required: true, message: '请选择时间', trigger: 'blur' }
        ],
        videourl: [
          { required: true, message: '请上传视频', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleError (file) {
      console.log(file)
      this.$message.error('上传错误，请刷新页面重新上传')
    },
    //   获取
    getVideoByType () {
      this.loading = true
      this.$http.get('/admin/getVideoByType/3').then(res => {
        // console.log(res)
        if (res.data.data.length > 0) {
          this.videoInfo = res.data.data[0]
          this.playerOptions.sources[0].src = res.data.data[0].videourl
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        this.$notify.error({
          title: '失败',
          message: '获取数据错误'
        })
        console.log(error)
      })
    },
    //   替换
    submitDialog () {
      this.videoInfo.videotype = '3'
      this.videoInfo.videodate = this.renderTime(this.videoInfo.videodate).substring(0, 11)
      // console.log(this.videoInfo)
      this.$http.post('/admin/updateVideo', this.videoInfo).then(res => {
        this.$notify.success({
          title: '成功',
          message: '替换成功'
        })
      }).catch(error => {
        this.$notify.success({
          title: '失败',
          message: '替换失败'
        })
        console.log(error)
      })
      this.getVideoByType()
      this.addDialogVisable = false
    },
    editBtn () {
      this.QiniuData.videoList = []
      if (this.videoInfo.videourl !== '' && this.videoInfo.videourl !== null && this.videoInfo.videourl !== undefined) {
        this.previewPlayerOptions.sources[0].src = this.videoInfo.videourl
        this.QiniuData.videoList.push({
          name: this.videoInfo.videourl,
          url: this.videoInfo.videourl
        })
      }
      this.addDialogVisable = true
    },
    handleRemove (file, fileList) {
      this.videoInfo.videourl = ''
      this.QiniuData.videoList = []
    },
    handlePreview (file) {
      // console.log(file)
    //   this.listCardImg = file.url
    //   this.showImgsDialogVisible = true
      // this.achievementInfo.achievementfaceimg = file.url
      // this.faceImgDialogVisible = true
    },
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取token数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    beforeAvatarUpload (file) {
      const isMP4 = file.type === 'video/mp4'
      // 本地测试8M
      // const isLt2M = file.size / 1024 / 1024 < 8
      if (!isMP4) {
        this.$message.error('上传视频只能是 MP4 格式!')
        return false
      }
      this.QiniuData.data.key = `${file.name}`
    },
    // 上传更多图片 成功回调函数
    uploadVideoSuccess  (response, file, fileList) {
      // console.log(response)
      this.QiniuData.videoList = []
      this.videoInfo.videourl = `${this.QiniuData.qiniuaddr}${response.key}`
      this.previewPlayerOptions.sources[0].src = `${this.QiniuData.qiniuaddr}${response.key}`
      // console.log(this.videoInfo.videourl)
      this.QiniuData.videoList.push({
        name: this.videoInfo.videourl,
        url: this.videoInfo.videourl
      })
    },
    renderTime (date) {
      var dateee = new Date(date).toJSON()
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    }
  },
  mounted () {
    this.getQiniuToken()
    this.getVideoByType()
  },
  created () {
    // this.getVideoByType()
  }
}
</script>

<style lang="less" scoped>
.wysp-container {
    width: 100%;
    min-height: 500px;
    padding: 10px;
    margin-top: 50px;
}
.sylb_btns_container {
    width: 100%;
    padding: 20px 5px;
    background-color: #FFFFFF;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.wysp_video_container {
    width: 1700px;
    padding: 40px;
    height: 700px;
    // background-color: #fff;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    background-image: url('../assets/images/pm.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.wysp_body_urls {
    width: 886px;
    height: 620px;
    overflow: hidden;
}
.priviewVideo_container {
    width: 650px;
    padding: 17px;
    height: 300px;
    background-color: #F5F5F5;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    background-image: url('../assets/images/pm.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.priviewVideo_item {
    width: 382px;
    height: 213px;
    overflow: hidden;
}
</style>
