<template>
<full-page ref="fullpage" :options="options">
    <div class="section section_1">
        <el-row class="navbar_container">
            <el-col :xs="12" :sm="6" :md="5" :lg="5" :xl="4" class="logo">
                <router-link to="/">
                  <el-image :src="navBar.sslogo">
                      <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                      </div>
                  </el-image>
                </router-link>
            </el-col>
            <el-col :sm="15" :md="15" :lg="15" :xl="16" class="nav hidden-sm-and-down">
                <router-link  :to="nav.index"  v-for="nav in navBar.navName" :key="nav.index" :class="navBar.isShowNavBar?'animate__animated animate__fadeInRight':'animate__animated animate__fadeOutRight'">
                  <el-button type="text">{{nav.name}}</el-button>
                </router-link>
                <a target="_blank" href="http://cwxt.buildone.com.cn:20005/#/login" :class="navBar.isShowNavBar?'animate__animated animate__fadeInRight':'animate__animated animate__fadeOutRight'">
                  <el-button type="text">山水学堂</el-button>
                </a>
            </el-col>
            <el-col :xs="12" :sm="18" :md="4" :lg="4" :xl="4" class="btns">
                <span class="iconfont icon-line-search" @click="$jumpTo('/search')"></span>
                <span class="iconfont icon-line-open" @click="clickNavBar"></span>
            </el-col>
        </el-row>
        <div class="navSM_container hidden-md-and-up ">
          <div :class="!navBar.isShowNavBar?'navSM animate__animated animate__slideInDown':'display_none animate__animated animate__slideOutUp'">
              <router-link  :to="nav.index"   v-for="nav in navBar.navName" :key="nav.index">
                  <el-button type="text">{{nav.name}}</el-button>
              </router-link>
               <a target="_blank" href="http://cwxt.buildone.com.cn:20005/#/login" :class="!navBar.isShowNavBar?'navSM animate__animated animate__slideInDown':'display_none animate__animated animate__slideOutUp'">
                  <el-button type="text">山水学堂</el-button>
                </a>
          </div>
        </div>
        <el-carousel class="carousel_container" height="100%" direction="vertical" :autoplay="true" @change="carosuelChange">
            <el-carousel-item v-for="(banner, index) in syBanners" :key="banner.articleid">
                <div class="swiper_div" :style="{backgroundImage:'url('+banner.articlecarouselimg +')'}"></div>
                <div class="showText_container">
                    <div :class="index==carosuelChangeid?'title animate__animated animate__fadeInRight':'title animate__animated animate__fadeOutLeft'" @click="showArticleDeltail(banner)">
                        {{banner.articletitle}}
                    </div>
                    <div :class="index==carosuelChangeid?'content animate__animated animate__fadeInLeft':'content animate__animated animate__fadeOutRight'">
                        {{banner.articlecarouseltext}}
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
    <div class="section section_2">
        <div class="section_title">
            <span>百年山水</span>
        </div>
        <div class="bnss_continer">
            <div :class="sectionIndex==1?'bnss_caousel_container animate__animated animate__fadeInLeft':'animate__animated animate__fadeOutRight'">
                <el-carousel class="bnss_caousel" height="100%" direction="horizontal" :autoplay="true">
                    <el-carousel-item v-for="banner in syzxBanners" :key="banner.articleid">
                        <div class="swiper_div" :style="{backgroundImage:'url('+banner.articlecarouselimg +')'}"></div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div :class="sectionIndex ==1?'bnss_content_container animate__animated animate__fadeInRight':'animate__animated animate__fadeOutRight'">
                <div class="bnss_marque_title">
                    <span>集团资讯</span>
                </div>
                <div class="bnss_marque_body" ref="bnssMarquee">
                    <div :class="{animMarquee:ishow == true}" ref="bnssMarqueeMove">
                        <div class="bnss_marque_container" v-for="item in syzxBanners" :key="item.articleid" @click="showArticleDeltail(item)">
                            <div class="bnss_marque_date">
                                {{item.articledate}}
                            </div>
                            <div class="bnss_marque_articleTile">
                                {{item.articletitle}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bnss_marque_btn">
                    <el-button type="primary" @click="$jumpTo('/xwzx')">MORE</el-button>
                </div>
            </div>
        </div>
    </div>
    <div class="section section_3">
        <div class="section3_title">
            <span>经典案例</span>
            <div class="jdal_btns_container hidden-lg-and-down">
                <button  @click="getAchievement()">全部</button>
                <button v-for="item in jdalTypeInfo" :key="item.achievementtype" @click="changeJdalType(item.achievementtype)">{{item.typeName}}</button>
                <button  @click="$jumpTo('/yjzs')">更多</button>
            </div>
        </div>
        <el-row class="jdal_container">
            <el-col :xs="24" :sm="24" :md="24" class="jdal_btns_small_container hidden-lg-and-up">
                <button  @click="getAchievement()">全部</button>
                <button v-for="item in jdalTypeInfo" :key="item.achievementtype" @click="changeJdalType(item.achievementtype)">{{item.typeName}}</button>
                <button @click="$jumpTo('/yjzs')">更多</button>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" class="jdal_item_container" v-for="item in jdalInfo" :key="item.achievementid">
              <div v-if="item.achievementfaceimg" class="jdal_item_img" :style="{backgroundImage:'url('+item.achievementfaceimg +')'}"  @click="showDetails(item)">
              </div>
              <div v-else class="jdal_item_img">
                  <i v-if="!item.achievementfaceimg" class="el-icon-picture-outline"></i>
              </div>
              <div class="jdal_item_text">{{item.achievementname}}</div>
            </el-col>
        </el-row>
    </div>
    <div class="section section_4">
      <el-row class="lxwm_container">
          <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10" class="lxwm_left_container">
            <div class="section_4_title">
                联系我们
            </div>
            <div class="lxwm_marquee_container">
                <div class="marqueePrevious">
                  <i class="el-icon-arrow-up" @click="scrollMarqueeUp"></i>
                </div>
                <div class="lxwm_marquee">
                  <div v-if="!lxwmUpDown" :class="lxwmIsShow?'lxwm_marquee_moveUp':'lxwm_marquee_move'">
                    <div class="lxwm_marquee_item_container" v-for="item in lxwmInfo" :key="item.id">
                        <div class="lxwm_marquee_item_Name">
                            {{item.name}}
                        </div>
                        <div class="lxwm_marquee_item_Line"></div>
                        <div class="lxwm_marquee_item_address">
                          <div class="lxwm_marquee_item_address_tel">
                              {{item.tel}}
                          </div>
                          <div class="lxwm_marquee_item_address_add">
                              {{item.province + item.city + item.address}}
                          </div>
                        </div>
                    </div>
                  </div>
                  <div v-if="lxwmUpDown" :class="lxwmIsShow?'lxwm_marquee_moveDown':'lxwm_marquee_move'">
                    <div class="lxwm_marquee_item_container" v-for="item in lxwmInfo" :key="item.id">
                        <div class="lxwm_marquee_item_Name">
                            {{item.name}}
                        </div>
                        <div class="lxwm_marquee_item_Line"></div>
                        <div class="lxwm_marquee_item_address">
                          <div class="lxwm_marquee_item_address_tel">
                              {{item.tel}}
                          </div>
                          <div class="lxwm_marquee_item_address_add">
                              {{item.province + item.city + item.address}}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="marqueeNext">
                  <i class="el-icon-arrow-down" @click="scrollMarqueeDown"></i>
                </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14" class="lxwm_right_container">
            <el-image :src="section4BgImg" fit="cover">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </el-col>
      </el-row>
    </div>
    <div class="section section_5">
      <div class="section5_video_container">
          <video-player  class="video-player vjs-custom-skin" id="videoDiv"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
                :webkit-playsinline="true"
                customEventName="customstatechangedeventname"
            >
          </video-player>
      </div>
      <el-row class="section5_navBottom_container">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="bottomNavBar_container">
              <div class="bottomNavBar_item_container" v-for="menu in bootomInfo.bottomNavInfo" :key="menu.menuId" >
                  <div class="bottomNavBar_item_menu" @click="$jumpTo(menu.menuIndex)">
                      {{menu.menuName}}
                  </div>
                  <div class="bottomNavBar_item_subMenu_container">
                    <div class="bottomNavBar_item_subMenu" v-for="subMenu in menu.subMenu" :key="subMenu.subMenuId" @click="$jumpTo(menu.menuIndex)">
                        {{subMenu.subMenuName}}
                    </div>
                  </div>
              </div>
              <div class="bottomNavBar_item_container">
                  <div class="bottomNavBar_item_menu">
                      关注我们
                  </div>
                  <div class="bottomNavBar_item_subMenu_container">
                     <el-image :src="bootomInfo.emwUrl" fit="contain">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                  </div>
              </div>
          </el-col>
           <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="bottomText_container">
              <div>江苏山水环境建设集团股份有限公司 版权所有</div>
              <div>苏ICP备10039575号 江苏必得网络有限公司承建</div>
          </el-col>
      </el-row>
    </div>
</full-page>
</template>

<script>
// import XRouterLink from '../components/RouterLink.vue'
export default {
  // components: { XRouterLink: XRouterLink },
  data () {
    return {
      sectionIndex: 0,
      ishow: true,
      lxwmIsShow: true,
      // 导航栏信息
      carosuelChangeid: 0,
      navBar: {
        isShowNavBar: true,
        sslogo: 'https://ssjt.buildone.com.cn/sslogo.png',
        navName: [
          {
            index: '/gyss',
            name: '关于山水'
          },
          {
            index: '/xwzx',
            name: '新闻中心'
          },
          {
            index: '/yjzs',
            name: '业绩展示'
          },
          {
            index: '/sswh',
            name: '山水文化'
          },
          {
            index: '/zxns',
            name: '招贤纳士'
          },
          // {
          //   index: 'http://cwxt.buildone.com.cn:20005/#/login',
          //   type: 'out',
          //   name: '山水学堂'
          // },
          {
            index: '/lxss',
            name: '联系山水'
          }
        ]
      },
      syBanners: [],
      syzxBanners: [],
      // fullpage 全屏滚动
      options: {
        sectionsColor: ['', '#fff', '#fff', '#fff', '#fff'],
        resize: true,
        scrollingSpeed: 1000,
        // 是否显示导航，设为true会显示小圆点作为导航
        navigation: true,
        // 导航小圆点的位置，可以设置为left或者right
        // navigationPosition: 'right',
        // 鼠标移动到小圆点上时显示出的提示信息
        // navigationTooltips: ['第一页', '第二页', '第三页'],
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        afterLoad: this.afterLoad
        // paddingTop: '60px',
        // paddingBottom: '30px',
        // fixedElements: '.header'
      },
      // Swiper 轮播 -- section1
      // notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
      notNextTick: true,
      swiperOptions: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        // 水平方向移动
        direction: 'horizontal',
        observer: true,
        observeParents: true,
        loop: true,
        speed: 1000,
        grabCursor: true,
        setWrapperSize: true,
        height: window.innerHeight,
        // autoHeight: true,
        scrollbar: '.swiper-scrollbar',
        mousewheelControl: true,
        // Some Swiper option/callback...
        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        onTransitionStart (swiper) {
          console.log(swiper)
        },
        pagination: {
          el: '.swiper-pagination'
        },
        // 一个屏幕展示的数量
        slidesPerView: 1,
        // 抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。
        resistanceRatio: 0,
        // 鼠标划过之后继续轮播
        autoplayDisableOnInteraction: false
      },
      jdalTypeInfo: [
        {
          achievementtype: 0,
          typeName: '设计'
        },
        {
          achievementtype: 1,
          typeName: '施工'
        },
        {
          achievementtype: 2,
          typeName: '监理'
        },
        {
          achievementtype: 3,
          typeName: '咨询'
        },
        {
          achievementtype: 4,
          typeName: '科研'
        }
      ],
      changeTypeId: 1,
      jdalInfo: [],
      section4BgImg: 'https://ssjt.buildone.com.cn/section3.jpg-sf',
      lxwmInfo: [],
      timer: '',
      lxwmUpDown: false,
      // video Options
      playerOptions: {
        // videojs options
        autoplay: true,
        // 消除任何音频
        muted: true,
        // 视频一结束就重新开始
        loop: true,
        language: 'zh-CN',
        aspectRatio: '16:9',
        // fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        // poster: 'https://ssjt.buildone.com.cn/v9m2r8.png',
        width: '100%',
        controls: true,
        preload: 'auto'
      },
      bootomInfo: {
        emwUrl: 'https://ssjt.buildone.com.cn/ewm1.jpg',
        bottomNavInfo: [
          {
            menuId: 0,
            menuIndex: '/gyss',
            menuName: '关于山水',
            subMenu: [
              {
                subMenuId: 0,
                subMenuName: '山水介绍',
                subMenuIndex: '/ssjs'
              },
              {
                subMenuId: 1,
                subMenuName: '山水荣誉',
                subMenuIndex: '/ssry'
              },
              {
                subMenuId: 2,
                subMenuName: '成长历程',
                subMenuIndex: '/cclc'
              },
              {
                subMenuId: 3,
                subMenuName: '核心板块',
                subMenuIndex: '/hcmk'
              },
              {
                subMenuId: 4,
                subMenuName: '山水党建',
                subMenuIndex: '/ssdj'
              }
            ]
          },
          {
            menuId: 1,
            menuIndex: '/xwzx',
            menuName: '新闻中心',
            subMenu: [
              {
                subMenuId: 0,
                subMenuName: '企业新闻',
                subMenuIndex: '/ssjs'
              },
              {
                subMenuId: 1,
                subMenuName: '业内资讯',
                subMenuIndex: '/ssry'
              },
              {
                subMenuId: 2,
                subMenuName: '相关法规',
                subMenuIndex: '/cclc'
              }
            ]
          },
          {
            menuId: 2,
            menuIndex: '/yjzs',
            menuName: '业绩展示',
            subMenu: [
              {
                subMenuId: 0,
                subMenuName: '设计',
                subMenuIndex: '/sj'
              },
              {
                subMenuId: 1,
                subMenuName: '施工',
                subMenuIndex: '/sg'
              },
              {
                subMenuId: 2,
                subMenuName: '监理',
                subMenuIndex: '/jl'
              },
              {
                subMenuId: 3,
                subMenuName: '资讯',
                subMenuIndex: '/zx'
              },
              {
                subMenuId: 4,
                subMenuName: '科研',
                subMenuIndex: '/ky'
              }
            ]
          },
          {
            menuId: 3,
            menuIndex: '/sswh',
            menuName: '山水文化',
            subMenu: [
              {
                subMenuId: 0,
                subMenuName: '宣传片',
                subMenuIndex: '/xcp'
              },
              {
                subMenuId: 1,
                subMenuName: '员工风采',
                subMenuIndex: '/ssr'
              },
              {
                subMenuId: 2,
                subMenuName: '项目展示',
                subMenuIndex: '/ylzs'
              },
              {
                subMenuId: 3,
                subMenuName: '推荐文章',
                subMenuIndex: '/zwy'
              }
            ]
          },
          {
            menuId: 4,
            menuIndex: '/zxns',
            menuName: '招贤纳士',
            subMenu: [
              {
                subMenuId: 0,
                subMenuName: '人才招聘',
                subMenuIndex: '/rczp'
              },
              {
                subMenuId: 1,
                subMenuName: '人才理念',
                subMenuIndex: '/rcln'
              }
            ]
          },
          {
            menuId: 5,
            menuIndex: '/lxss',
            menuName: '联系山水',
            subMenu: [
              {
                subMenuId: 0,
                subMenuName: '联系我们',
                subMenuIndex: '/lxwm'
              },
              {
                subMenuId: 1,
                subMenuName: '资讯服务',
                subMenuIndex: '/ssry'
              },
              {
                subMenuId: 2,
                subMenuName: '周边旅游',
                subMenuIndex: '/zbly'
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    // 获取公司列表
    getCompanyList () {
      this.$http.get('/admin/getCompanyList').then(res => {
        // console.log(res)
        this.lxwmInfo = res.data.data
      })
    },
    showDetails (data) {
      // console.log(data)
      window.sessionStorage.setItem('jdalDetails', JSON.stringify(data))
      this.$router.push('jdalDetails')
    },
    // 获取首页展示 / 未展示的案例列表
    getAchievement () {
      this.$http.get('/admin/getAchievement').then(res => {
        console.log(res)
        const info = res.data.data
        const tableData = []
        for (let index = 0; index < info.length; index++) {
          // 首页展示案例
          if (info[index].achievementshowhomepage === '1' && tableData.length < 6) {
            tableData.push(info[index])
          }
        }
        this.jdalInfo = tableData
      })
    },
    // 页面切换
    pageChange (path) {
      this.$router.push(path)
    },
    // 加载section1轮播信息
    getcarouselList () {
      this.syBanners = []
      this.syzxBanners = []
      this.$http.get('/admin/getCarousel').then(res => {
        // 首页轮播
        const data = res.data.data
        // console.log(data)
        for (let index = 0; index < data.length; index++) {
          if (data[index].articlecarouseltype === '0') {
            this.syBanners.push(data[index])
          } else if (data[index].articlecarouseltype === '1') {
            data[index].articledate = data[index].articledate.substring(0, 11)
            this.syzxBanners.push(data[index])
          }
        }
        // console.log(this.syzxBanners)
      })
    },
    // 查看轮播
    showArticleDeltail (data) {
      window.sessionStorage.setItem('articleDetail', JSON.stringify(data))
      window.sessionStorage.setItem('activeIndex', '/')
      this.$router.push('articleDetail')
    },
    clickNavBar () {
      this.navBar.isShowNavBar = !this.navBar.isShowNavBar
    },
    afterLoad (origin, destination, direction) {
      this.sectionIndex = destination.index
      console.log(this.sectionIndex)
      if (this.sectionIndex >= 1 && this.sectionIndex <= 3) {
        this.getAchievement()
      } else if (this.sectionIndex >= 3) {
        this.getVideoByType()
      }
    },
    carosuelChange (res) {
      this.carosuelChangeid = res
    },
    scrollBnssMarquee () {
      if (this.sectionIndex === 1) {
        this.ishow = true
        setTimeout(() => {
          this.syzxBanners.push(this.syzxBanners[0])
          this.syzxBanners.shift()
          this.ishow = false
        }, 500)
      }
    },
    // 联系我们滚动
    scrollLxwmMarquee () {
      if (this.sectionIndex === 3) {
        this.lxwmUpDown = false
        this.lxwmIsShow = true
        // this.lxwmUpDown = true
        setTimeout(() => {
          this.lxwmInfo.push(this.lxwmInfo[0])
          this.lxwmInfo.shift()
          this.lxwmIsShow = false
          // this.lxwmUpDown = false
        }, 500)
      }
    },
    // 联系我们滚动 向上滚动按钮
    scrollMarqueeUp () {
      clearInterval(this.timer)
      this.lxwmUpDown = false
      this.lxwmIsShow = true
      setTimeout(() => {
        this.lxwmInfo.push(this.lxwmInfo[0])
        this.lxwmInfo.shift()
        this.lxwmIsShow = false
      }, 500)
    },
    // 联系我们滚动 向上滚动按钮
    scrollMarqueeDown () {
      clearInterval(this.timer)
      this.lxwmUpDown = true
      this.lxwmIsShow = true
      setTimeout(() => {
        this.lxwmInfo.unshift(this.lxwmInfo[this.lxwmInfo.length - 1])
        this.lxwmInfo.pop(this.lxwmInfo[this.lxwmInfo.length - 1])
        this.lxwmIsShow = false
      }, 500)
    },
    // 经典案例 分类切换
    changeJdalType (typeId) {
      console.log(typeId)
      this.$http.get('/admin/getAchievement/' + typeId).then(res => {
        // console.log(res)
        const info = res.data.data
        const tableData = []
        for (let index = 0; index < info.length; index++) {
          // 首页展示案例
          if (tableData.length < 6) {
            tableData.push(info[index])
          }
        }
        this.jdalInfo = tableData
      })
    },
    //   获取尾页视频
    getVideoByType () {
      this.$http.get('/admin/getVideoByType/0').then(res => {
        if (res.data.data.length > 0) {
          this.playerOptions.sources[0].src = res.data.data[0].videourl
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted () {},
  created () {
    this.getcarouselList()
    this.getCompanyList()
    this.getVideoByType()
    setInterval(this.scrollBnssMarquee, 1500)
    this.timer = setInterval(this.scrollLxwmMarquee, 1000)
  }
}
</script>

<style lang="less" scoped>
.display_none {
  display: none !important;
}
.section_1{
  overflow: hidden;
  background-color: unset;
  position: relative;
}
.carousel_container {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0px;
  z-index: 10;
}
.swiper_div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* 导航部分 */
.navbar_container {
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 99;
}
.logo {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  align-items: center;
}
.logo .el-image {
  width: 240px;
  height: 60px;
  cursor: pointer;
}
.nav {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav .el-button--text:hover{
  color: #0080FF;
}
.nav .el-button{
  font-size: 100%;
  color: #fff;
  margin: 0 5px;
}
// sm 及以下显示 下拉菜单
.navSM_container {
  width: 100%;
  // height: 300px;
  // background-color: red;
  position: fixed;
  top: 100px;
  z-index: 99;
  overflow: hidden;
}
.navSM .el-button--text:hover{
  color: #0080FF;
}
.navSM .el-button{
  // border-top: 1px solid #fff;
  font-size: 100%;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  letter-spacing: .1rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: unset;
}
.btns {
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btns span{
  color: #fff;
  font-size: 200%;
  margin: 0 10px;
  cursor: pointer;
}
.btns span:hover{
  color: #0080FF;
}
/* 展示文字 */
.showText_container {
  position: absolute;
  bottom: 10px;
  z-index: 50;
  width: 100%;
  padding: 5px 20px;
  color: #fff;
  text-shadow: 1px 2px 5px lightgrey;
  overflow: hidden;
}
.showText_container .title{
  /* 最多20字 */
  font-size: 140%;
  width: 100%;
  letter-spacing: .1rem;
  cursor: pointer;
}
.showText_container .title:hover{
  color: #0080FF;
}
.showText_container .content{
  /* 最多50字 */
  font-size: 100%;
  width: 100%;
  line-height: 150%;
  margin-top: 5px;
  letter-spacing: .1rem;
}
// 第二屏
.section_2 {
  // min-height: 800px;
  // max-height: 100%;
  // overflow: hidden;
  // background-image: linear-gradient(125deg, #e06464, #a5da9a, #3b75e9, #e44bff);
  // background-size: 400%;
  // animation: bganimation 10s infinite;
}
.section_2 .section_title{
  width: 100%;
  height: 10%;
  min-height: 60px;
  border-bottom: 2px solid black;
}
.section_title span{
    height: 100%;
    font-size: 200%;
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.bnss_continer {
    height: 90%;
    width: 100%;
    display: flex;
    // padding: 10px 0;
    // border-bottom: 2px solid black;
}
.display_none {
  display: none;
}
.bnss_caousel_container {
    height: 100%;
    width: 60%;
    min-width: 200px;
    padding: 20px;
    // background-color: lightcoral;
    .bnss_caousel{
        width: 100%;
        height: 100%;
    }
}
.bnss_content_container {
    height: 100%;
    width: 40%;
    transition: all 1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 300px;
    // background-image: linear-gradient(125deg, #e06464, #a5da9a, #3b75e9, #e44bff);
    // background-size: 400%;
    // animation: bganimation 10s infinite;
}
/* 动画属性 */
@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.bnss_content_container .bnss_marque_title {
    height: 80px;
    // background-color: red;
}
// 集团资讯 下滚动单元
.bnss_content_container .bnss_marque_body {
    height: 400px;
    // background-color: green;
    overflow: hidden;
}
// .bnss_content_container .bnss_marque_body .bnss_marque_body_move{
// }
.animMarquee {
    // background-color: red;
    transition: all 0.5s;
    margin-top: -100px;
}
.bnss_content_container .bnss_marque_body .bnss_marque_container{
    width: 100%;
    height: 100px;
    // background-color: red;
    display: flex;
    align-items: center;
    font-size: 110%;
    font-weight: 200;
    border-top: 1px dashed black;
    cursor: pointer;
}
.bnss_content_container .bnss_marque_body .bnss_marque_container .bnss_marque_date{
    min-width: 120px;
    // max-width: 400px;
    // background-color: pink;
}
.bnss_content_container .bnss_marque_body .bnss_marque_container .bnss_marque_articleTile{
    min-width: 200px;
    line-height: 150%;
}
.bnss_content_container .bnss_marque_btn {
    margin-top: 5px;
    padding: 5px 0;
    border-top: 2px solid black;
    // background-color: blue;
    display: flex;
    align-items: center;
}
.bnss_content_container .bnss_marque_btn .el-button{
    width: 150px;
    padding: 15px 0;
    background-color: black;
    border: unset;
    color: #fff;
    font-size: 100%;
    letter-spacing: .2rem;
}
.bnss_content_container .bnss_marque_btn .el-button:hover{
    background-image: linear-gradient(125deg, #000,#0080FF, #FFD700);
    background-size: 400%;
    animation: bganimation 2s infinite;
}
.bnss_marque_title span{
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 160%;
}
// 第三屏
.section3_title {
  width: 100%;
  height: 100px;
  padding: 0 20px;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section3_title span{
  font-size: 200%;
}
.jdal_btns_container button{
  margin-left: 10px;
  font-size: 90%;
  border: unset;
  padding: 8px 30px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #E8E8E8;
}
.jdal_btns_container button:hover{
  background-color:  #F5F5F5;
}
.jdal_container {
  width: 100%;
  height: 82%;
  padding: 20px 0;
}
.jdal_item_container {
  height: 50%;
  position: relative;
  margin: 10px 0;
  padding: 0 20px;
}
.jdal_item_container .jdal_item_img{
  height: 90%;
  width: 95%;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
  left: 50%;
  transform: translateX(-50%);
}
.jdal_item_container .jdal_item_img:hover{
  animation: jdal_item_imgChange .2s forwards;
}
@keyframes jdal_item_imgChange {
  0% {
    top: 0px;
  }
  100% {
    top: -10px;
  }
}
.jdal_item_container .jdal_item_text{
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.jdal_btns_small_container {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}
.jdal_btns_small_container button{
  font-size: 90%;
  border: unset;
  cursor: pointer;
  border-radius: 2px;
  padding: 5px 10px;
  background-color: #E8E8E8;
}
// 第四屏
.lxwm_container {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.lxwm_container .lxwm_left_container {
  height: 60%;
}
.lxwm_container .lxwm_left_container .section_4_title {
  width: 100%;
  height: 10%;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 200%;
  // border-bottom: 1px solid black;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_move {
  // transition: all 0.5s;
  margin-top: -100px;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_moveUp {
  transition: all 0.5s;
  margin-top: -200px;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_moveDown {
  transition: all .5s;
  margin-top: 0px;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container .marqueePrevious , .lxwm_container .lxwm_left_container .lxwm_marquee_container .marqueeNext {
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200%;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container .marqueePrevious i, .lxwm_container .lxwm_left_container .lxwm_marquee_container .marqueeNext i{
  cursor: pointer;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  {
  width: 100%;
  height: 400px;
  overflow: hidden;
  // border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.lxwm_container .lxwm_right_container {
  height: 40%;
}
.lxwm_container .lxwm_right_container .el-image{
  width: 100%;
  height: 100%;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid black;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container .lxwm_marquee_item_Name{
  height: 80%;
  width: 40%;
  // min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container .lxwm_marquee_item_Line{
  height: 80%;
  width: 2px;
  background-color: #000;
}
.lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container .lxwm_marquee_item_address{
  height: 80%;
  width: 55%;
  // min-width: 200px;
  font-size: 110%;
}
.lxwm_marquee_item_address .lxwm_marquee_item_address_tel, .lxwm_marquee_item_address .lxwm_marquee_item_address_add {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
// 第五屏 最后一屏
.section_5 {
  width: 100%;
  height: 100%;
  position: relative;
}
.section_5 .section5_video_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 99;
}
.section_5 .section5_video_container .video-player{
  width: 100%;
  height: 100%;
  object-fit:fill;
}
video {
  width: 100% !important;
  height: 100vh !important;
  // 我的容器高度设置的是100vh-95px，你们根据你们容器高度设置视频高度即可
  object-fit:fill;
  // 消除两边留白
}
//为了填满整个my_video的高度，设置video外层div高度（就是移动端效果图包含黑色部分和视频部分的整个div），使之为容器高度
#videoDiv>div {
  height: 100vh !important;
  width: 100%;
  overflow: hidden;
}
.my_video {
  // 这是我放视频播放器的容器
  width: 100%;
  height: 100vh;
  background-color: white;
}
.section_5 .section5_navBottom_container {
  width: 100%;
  max-height: 40%;
  background-color: rgba(53, 53, 53, 0.9) ;
  position: absolute;
  bottom: 0;
  z-index: 100;
}
.section_5 .section5_navBottom_container .bottomNavBar_container {
  height: 90%;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
}
.section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container {
  // max-width:15%;
  height: 100%;
  padding: 0 20px;
}
.section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_menu{
  width:100%;
  padding: 10px 5px;
  font-size: 1.2rem;
  cursor: pointer;
}
.section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_menu:hover,
.section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu:hover{
  color: #0080FF;
}
.section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu{
  width:100%;
  padding: 10px 5px;
  font-size: 1rem;
  cursor: pointer;
}
.section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu_container  .el-image{
  width: 100px;
}
.section_5 .section5_navBottom_container .bottomEWM_contaienr {
  height: 90%;
  padding: 10px;
}
.section_5 .section5_navBottom_container .bottomEWM_contaienr .bottomEWM_img{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.section_5 .section5_navBottom_container .bottomEWM_contaienr .bottomEWM_img .el-image{
  width: 250px;
  height: 250px;
  //  display: flex;
  // justify-content: center;
  // align-items: flex-start;
}
.section_5 .section5_navBottom_container .bottomEWM_contaienr .bottomEWM_text{
  width: 100%;
  height: 20%;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}
.section_5 .section5_navBottom_container .bottomText_container {
  height: 10%;
  background-color: #2E2E2E;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: .8rem;
  color: #fff;
  padding: 5px;
}
@media screen and (min-width: 1200px){
  .btns {
    justify-content: center;
    height: 100%;
  }
  .nav .el-button{
    font-size: 110%;
    margin: 0 8px;
    font-weight: 300;
  }
  .showText_container {
    position: absolute;
    bottom: 50px;
  }
  .showText_container .title{
    width: 50%;
  }
  .showText_container .content{
    /* 最多50字 */
    width: 50%;
  }
  // 第二屏
  .bnss_continer {
    // max-height: 800px;
  }
  .bnss_content_container {
    padding: 0 50px;
  }
  // 第四屏
  .lxwm_container {
    padding: 20px;
  }
  .lxwm_container .lxwm_left_container {
    height: 100%;
  }
  .lxwm_container .lxwm_right_container {
    height: 100%;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  {
    width: 100%;
    height: 500px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 500px){
  .bnss_continer {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 2px solid black;
  }
  .bnss_caousel_container {
    height: 42%;
    // min-height: 350px;
    width: 100%;
    padding: 10px;
  }
  .bnss_content_container {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
  }
  .bnss_content_container .bnss_marque_body {
    height: 200px;
    overflow: hidden;
  }
  .bnss_content_container .bnss_marque_title {
    height: 50px;
  }
  .bnss_marque_title span{
    font-size: 130%;
  }
  // 第三屏
  .section3_title {
    width: 100%;
    height: 80px;
    padding: 0 20px;
    border-bottom: 2px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section3_title span{
    font-size: 200%;
  }
  .jdal_btns_container  {
    display: none;
  }
  .jdal_btns_container button{
    margin: 0 10px;
    font-size: 90%;
    border: unset;
    padding: 8px 30px;
    cursor: pointer;
    border-radius: 2px;
    background-color: #E8E8E8;
  }
  .jdal_btns_container button:hover{
    background-color:  #F5F5F5;
  }
  .jdal_container {
    width: 100%;
    height: 82%;
    padding: 10px 0;
  }
  .jdal_item_container {
    height: 25%;
    min-height: 190px;
    // padding: 20px;
    position: relative;
  }
  .jdal_item_container .jdal_item_img{
    height: 80%;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .jdal_item_container .jdal_item_text{
    font-size: 80%;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 81%;
  }
  .jdal_btns_small_container {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 30px;
  }
  .jdal_btns_small_container button{
    font-size: 90%;
    border: unset;
    margin-left: 15px;
    cursor: pointer;
    border-radius: 2px;
    padding: 8px 20px;
    background-color: #E8E8E8;
  }
    // 第四屏
  // .lxwm_container .lxwm_left_container .section_4_title {
  //   border-bottom: 2px solid black;
  //   height: 60px;
  //   font-size: 130%;
  // }
  .section_5 {
    width: 100%;
    height: 100%;
  }
  .section_5 .section5_video_container {
    width: 100%;
    height: 40%;
  }
  .section_5 .section5_video_container .video-player{
    width: 100%;
    height: 100%;
    object-fit:fill;
  }
  video {
    width: 100%;
    height: 100% !important;
    // 我的容器高度设置的是100vh-95px，你们根据你们容器高度设置视频高度即可
    object-fit:fill;
    // 消除两边留白
  }
  //为了填满整个my_video的高度，设置video外层div高度（就是移动端效果图包含黑色部分和视频部分的整个div），使之为容器高度
  #videoDiv>div {
    height: 100% !important;
    width: 100%;
    overflow: hidden;
  }
  .my_video {
    // 这是我放视频播放器的容器
    width: 100%;
    height: 100%;
    background-color: white;
  }
  .section_5 .section5_navBottom_container {
    width: 100%;
    height: 60%;
    max-height: 60%;
    background-color: rgba(53, 53, 53, 1);
  }
  // .section_5 .section5_navBottom_container .bottomNavBar_container {
  //   height: 60%;
  //   padding: 5px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   justify-content: center;
  //   color: #fff;
  // }
  .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container {
    // max-width:15%;
    padding: 0 5px;
  }
  // .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_menu{
  //   width: 100%;
  //   padding: 2px;
  //   font-size: 1.1rem;
  //   cursor: pointer;
  // }
  // .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_menu:hover,
  // .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu:hover{
  //   color: #0080FF;
  // }
  // .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu_container {
  //   width: 100%;
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;
  // }
  // .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu{
  //   width: 80px;
  //   max-width: 70px;
  //   min-width: 70px;
  //   margin: 1px;
  //   padding: 2px;
  //   font-size: .9rem;
  //   cursor: pointer;
  // }
  // .section_5 .section5_navBottom_container .bottomEWM_contaienr {
  //   height: 30%;
  //   padding: 10px;
  // }
  // .section_5 .section5_navBottom_container .bottomEWM_contaienr .bottomEWM_img{
  //   max-width: 100%;
  //   max-height: 100px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: flex-start;
  // }
  // .section_5 .section5_navBottom_container .bottomEWM_contaienr .bottomEWM_img .el-image{
  //   width:100px;
  //   height: 100px;
  // }
  // .section_5 .section5_navBottom_container .bottomEWM_contaienr .bottomEWM_text{
  //   width: 100%;
  //   height: 20%;
  //   color: #fff;
  //   padding: 10px 0;
  //   text-align: center;
  // }
  // .section_5 .section5_navBottom_container .bottomText_container {
  //   height: 10%;
  //   background-color: #2E2E2E;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: .8rem;
  //   color: #fff;
  //   padding: 5px;
  // }
  // .section_5 .section5_navBottom_container .bottomText_container div{
  //   width: 100%;
  //   padding: 5px 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
}
@media screen and (max-width: 500px){
  .navbar_container {
    height: 80px;
  }
  .navSM_container {
    width: 100%;
    // height: 300px;
    // background-color: red;
    position: fixed;
    top: 80px;
    z-index: 99;
    overflow: hidden;
  }
  .logo .el-image {
    width: 160px;
    height: 40px;
    cursor: pointer;
  }
  // 第二屏
  .section_2 {
    overflow: hidden;
  }
  .section_title{
    width: 100%;
    height: 80px;
    border-bottom: 2px solid black;
  }
  .section_title span{
    height: 100%;
    font-size: 150%;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .bnss_continer {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: unset;
  }
  .bnss_caousel_container {
    width: 100%;
    height: 25%;
    padding: 5px;
  }
  .bnss_content_container {
    min-height: 60%;
    max-height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
  }
  .bnss_content_container .bnss_marque_body {
    height: 400px;
    overflow: hidden;
  }
  .bnss_content_container .bnss_marque_title {
    height: 50px;
  }
  .bnss_marque_title span{
    font-size: 120%;
  }
  .bnss_content_container .bnss_marque_body .bnss_marque_container{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    font-size: 80%;
    // font-weight: 200;
    border-top: 1px dashed black;
    cursor: pointer;
  }
  .bnss_content_container .bnss_marque_body .bnss_marque_container .bnss_marque_date{
    min-width: 80px;
    max-width: 8px;
  }
  .bnss_content_container .bnss_marque_body .bnss_marque_container .bnss_marque_articleTile{
    min-width: 200px;
    line-height: 150%;
  }
  // 第三屏
  .section_3 {
    overflow: hidden;
  }
  .section3_title {
    width: 100%;
    height: 80px;
    padding: 0 10px;
    border-bottom: 2px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .section3_title span{
    font-size: 150%;
  }
  .jdal_btns_container  {
    display: none;
  }
  .jdal_btns_container button{
    margin: 0 10px;
    font-size: 90%;
    border: unset;
    padding: 8px 30px;
    cursor: pointer;
    border-radius: 2px;
    background-color: #E8E8E8;
  }
  .jdal_btns_container button:hover{
    background-color:  #F5F5F5;
  }
  .jdal_container {
    width: 100%;
    height: 82%;
    padding: 10px 0;
  }
  .jdal_item_container {
    height: 25%;
    // padding: 20px;
    position: relative;
  }
  .jdal_item_container .jdal_item_img{
    height: 80%;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .jdal_item_container .jdal_item_text{
    font-size: 80%;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 82%;
  }
  // 第四屏
  .lxwm_container .lxwm_left_container .section_4_title {
    font-size: 150%;
    line-height: 50px;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container {
    width: 100%;
    height: 85%;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  {
    width: 100%;
    height: 300px;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container {
    height: 100px;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container .marqueePrevious , .lxwm_container .lxwm_left_container .lxwm_marquee_container .marqueeNext {
    padding: 2px 0;
    font-size: 100%;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container .lxwm_marquee_item_Name{
    font-size: 120%;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container .lxwm_marquee_item_Line{
    height: 80%;
    width: 2px;
    background-color: #000;
  }
  .lxwm_container .lxwm_left_container .lxwm_marquee_container .lxwm_marquee  .lxwm_marquee_item_container .lxwm_marquee_item_address{
    font-size: 90%;
  }
  .lxwm_marquee_item_address .lxwm_marquee_item_address_tel, .lxwm_marquee_item_address .lxwm_marquee_item_address_add {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  // 第五屏 最后一屏
  .section_5 {
    width: 100%;
    height: 100%;
  }
  .section_5 .section5_video_container {
    width: 100%;
    height: 25%;
    background-color: red;
  }
  .section_5 .section5_video_container .video-player{
    width: 100%;
    height: 100%;
    object-fit:fill;
  }
  video {
    width: 100%;
    height: 100% !important;
    // 我的容器高度设置的是100vh-95px，你们根据你们容器高度设置视频高度即可
    object-fit:fill;
    // 消除两边留白
  }
  //为了填满整个my_video的高度，设置video外层div高度（就是移动端效果图包含黑色部分和视频部分的整个div），使之为容器高度
  #videoDiv>div {
    height: 100% !important;
    width: 100%;
    overflow: hidden;
  }
  .my_video {
    // 这是我放视频播放器的容器
    width: 100%;
    height: 100%;
    background-color: white;
  }
  .section_5 .section5_navBottom_container {
    width: 100%;
    height: 75%;
    max-height: 75%;
    background-color: rgba(53, 53, 53, 1);
    // position: relative;
  }
  .section_5 .section5_navBottom_container .bottomNavBar_container {
    height: 90%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #fff;
  }
  .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container {
    width: 100%;
    height: 12%;
    padding: 0;
    margin: 1px;
  }
  .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_menu{
    padding: 5px 0;
    color: grey;
    font-size: 100%;
  }
  .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu_container{
    padding: 0;
    color: grey;
    display: flex;
    justify-content: flex-start;
  }
  .section_5 .section5_navBottom_container .bottomNavBar_container .bottomNavBar_item_container .bottomNavBar_item_subMenu_container .bottomNavBar_item_subMenu{
    padding: 5px 0;
    color: #fff;
    font-size: 80%;
    width: 80px;
  }
  .bottomNavBar_item_container .bottomNavBar_item_subMenu_container .el-image {
    max-width: 80px;
    // height: 60px;
    display: flex;
    justify-content: flex-start;
    background-color: red;
  }
  .section_5 .section5_navBottom_container .bottomText_container {
    position: absolute;
    bottom: 0;
    height: 10%;
    background-color: #2E2E2E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 50%;
    color: #fff;
    padding: 5px;
    letter-spacing: .2rem;
  }
  .section_5 .section5_navBottom_container .bottomText_container div{
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
