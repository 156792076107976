// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css'
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import Vue from 'vue'
import App from './App.vue'
// js
import all from '@/js/all'
// animate
import animated from 'animate.css'
// icon
import '@/assets/icon/iconfont.css'
// axios
import axios from 'axios'
// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 路由
import router from './router'
import './plugins/element.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
// FullPage
import 'fullpage.js/vendors/scrolloverflow.min.js' // Optional. When using scrollOverflow:true
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import Swiper2, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper'
// Video
import '../src/css/video.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
// querystring
import querystring from 'querystring'
import BaiduMap from 'vue-baidu-map'
// wangEditor
import E from 'wangeditor'
// 引入echarts
import * as echarts from 'echarts'
// qiniu.js
import * as qiniu from 'qiniu-js'
Vue.prototype.$qiniu = qiniu
Vue.prototype.$echarts = echarts
Vue.prototype.E = E
Vue.prototype.querystring = querystring
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.use(VueVideoPlayer)
Swiper2.use([Navigation, Pagination, EffectFade, Autoplay])
Vue.use(VueAwesomeSwiper)
Vue.use(VueFullPage)
Vue.use(ElementUI)
Vue.use(animated)
// 将全局函数当做插件来进行注册
Vue.use(all)
Vue.prototype.np = NProgress

Vue.use(BaiduMap, {
  /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
  ak: 'VgSLGUz2DBAxKRwoivwHIUbM69rANB5t'
})

Vue.config.productionTip = false
Vue.prototype.$http = axios
// axios配置
axios.defaults.timeout = 30000
// 本地测试http地址
// axios.defaults.baseURL = 'http://192.168.10.2:8082'
// 服务器运行地址
axios.defaults.baseURL = 'http://120.195.178.158:8081'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
router.beforeEach((to, from, next) => {
/* 进度条 */
  NProgress.start()
  if (to.path === '/admin') {
    const token = window.localStorage.getItem('isLogin')
    if (!token) return next('/adminLogin')
    next()
  } else {
    NProgress.done()
    return next()
  }
})
router.afterEach(() => {
  NProgress.done()
})

/* eslint-disable */
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
