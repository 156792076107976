import { render, staticRenderFns } from "./sswh.vue?vue&type=template&id=d884a46e&scoped=true&"
import script from "./sswh.vue?vue&type=script&lang=js&"
export * from "./sswh.vue?vue&type=script&lang=js&"
import style0 from "./sswh.vue?vue&type=style&index=0&id=d884a46e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d884a46e",
  null
  
)

export default component.exports