<template>
    <div class="search_container">
        <div class="tab_container">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>关键词搜索</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="search_input_container">
            <el-input placeholder="请输入内容" v-model="searchParam.serachData" class="input-with-select" >
                <el-select v-model="searchParam.selectType" slot="prepend" placeholder="选择查询类型" style="width: 150px" @change="clear">
                    <el-option label="文章" value="文章"></el-option>
                    <el-option label="案例" value="案例"></el-option>
                    <el-option label="景点" value="景点"></el-option>
                    <el-option label="岗位" value="岗位"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="seachAll"></el-button>
            </el-input>
        </div>
        <div class="search_data_container">
            <el-collapse accordion v-if="searchParam.selectType === '文章'">
                <el-collapse-item v-for="item in dataList" :key="item.articleid" :title="item.articletitle">
                    <div>{{item.articledate}}</div>
                    <div v-html="item.articletext"></div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse accordion v-if="searchParam.selectType === '案例'">
                <el-collapse-item v-for="item in dataList" :key="item.achievementid" :title="item.achievementname">
                    <div><img width="100%" :src="item.achievementfaceimg" alt="" style="width: 300px; heigth: 200px;"></div>
                    <div v-html="item.achievementtext"></div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse accordion v-if="searchParam.selectType === '景点'">
                <el-collapse-item v-for="item in dataList" :key="item.travelid" :title="item.travelname">
                    <div><img width="100%" :src="item.travelimgurl" alt="" style="width: 300px; heigth: 200px;"></div>
                    <div>{{item.travetext}}</div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse accordion v-if="searchParam.selectType === '岗位'">
                <el-collapse-item v-for="item in dataList" :key="item.positionid" :title="item.positionname">
                    <div>发布时间：{{item.createtime}}</div>
                    <div>招聘人数:{{item.positionnumber}}</div>
                    <div>工作地点:{{item.positionplace}}</div>
                    <div v-html="item.positionskill"></div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      searchParam: {},
      activeName: '1',
      dataList: []
    }
  },
  methods: {
    clear () {
      this.dataList = []
    },
    seachAll () {
      if (this.searchParam.selectType === null || this.searchParam.selectType === '' || this.searchParam.selectType === undefined) {
        this.$message.warning('请选择查询类型')
        return false
      } else if (this.searchParam.serachData === null || this.searchParam.serachData === '' || this.searchParam.serachData === undefined) {
        this.$message.warning('请输入查询内容')
        return false
      } else {
        let requestUrl = ''
        if (this.searchParam.selectType === '文章') {
          requestUrl = 'admin/searchArticleByTitle/' + this.searchParam.serachData
        } else if (this.searchParam.selectType === '案例') {
          requestUrl = 'admin/searchAchieveByName/' + this.searchParam.serachData
        } else if (this.searchParam.selectType === '景点') {
          requestUrl = 'admin/searchTravelByName/' + this.searchParam.serachData
        } else if (this.searchParam.selectType === '岗位') {
          requestUrl = 'admin/searchPositionByName/' + this.searchParam.serachData
        }
        // 查询操作
        this.$http.post(requestUrl).then(res => {
          console.log(res)
          this.dataList = res.data.data
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
    .search_container{
        width: 100%;
        background-color: #fff;
        border-radius: 5px;
        // box-shadow: 1px 1px 5px lightgray;
        padding: 5px;
    }
    .tab_container {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid black;
    }
    .search_input_container {
        width: 95%;
        margin:  15px auto;
    }
    .search_data_container {
        width: 95%;
        min-height: 200px;
        margin:  50px auto 0 auto;
        // border-left: 1px solid #EBEEF5;
        // border-right: 1px solid #EBEEF5;
    }
    .search_input_container .el-input .el-select {
            width: 80px;
        }
    @media screen and (max-width: 1024px){
        .search_input_container , .search_data_container {
            width: 95%;
        }
    }
</style>
