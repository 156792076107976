<template>
    <!-- 首页  案例控制 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <!-- <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button> -->
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="业绩案例标题"
                width="300"
                prop="achievementname">
                </el-table-column>
                <el-table-column
                label="封面"
                prop="achievementfaceimg"
                width="300">
                </el-table-column>
                <el-table-column
                label="图片"
                prop="achievementshowimgs">
                </el-table-column>
                <!-- <el-table-column
                label="描述"
                prop="achievementtext"
                width="400"
                sortable>
                </el-table-column> -->
                <el-table-column
                prop="achievementshowhomepage"
                label="首页展示"
                sortable
                width="100">
                    <template slot-scope="scope">
                        <el-switch
                          v-model="scope.row.achievementshowhomepage"
                          active-value="1"
                          inactive-value="0"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          disabled>
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                fixed="right"
                width="90"
                align="center"
                prop="achievementtype"
                label="类型"
                sortable
                :filters="achievementtypeInfo"
                :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.achievementtype === '0'" type="success">设计</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '1'" type="info">施工</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '2'" type="warning">监理</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '3'" type="danger">咨询</el-tag>
                    <el-tag v-if="scope.row.achievementtype === '4'" >科研</el-tag>
                </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia lunbo -->
        <el-dialog title="添加展示案例" :visible.sync="addDialogVisable" width="600px" :close-on-click-modal="false">
            <el-form :model="achievementInfo">
                <el-form-item label="选择案例" label-width="100px">
                    <el-select v-model="achievementInfo.achievementid" placeholder="请选择案例" filterable >
                        <el-option v-for="item in achievementSelectionInfo" :key="item.achievementid" :label="item.achievementname" :value="item.achievementid"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="addHomePageAchievement()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {
      listCardImg: '',
      achievementSelectionInfo: [],
      showImgsDialogVisible: false,
      achievementtypeInfo: [
        {
          text: '设计',
          value: '0'
        },
        {
          text: '施工',
          value: '1'
        },
        {
          text: '监理',
          value: '2'
        },
        {
          text: '咨询',
          value: '3'
        },
        {
          text: '科研',
          value: '4'
        }
      ],
      achievementInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        moreFileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      }
    }
  },
  methods: {
    // 获取首页展示 / 未展示的案例列表
    getAchievement () {
      this.loading = true
      this.$http.get('/admin/getAchievement').then(res => {
        // console.log(res)
        const info = res.data.data
        const tableData = []
        const achievementSelectionInfo = []
        for (let index = 0; index < info.length; index++) {
          // 首页展示案例
          if (info[index].achievementshowhomepage === '1') {
            tableData.push(info[index])
          } else {
            achievementSelectionInfo.push(info[index])
          }
        }
        this.tableData = tableData
        this.achievementSelectionInfo = achievementSelectionInfo
        this.loading = false
      })
    },
    // 提交dialog内容
    addHomePageAchievement () {
      console.log(this.achievementInfo)
      this.loading = true
      this.$http.post('/admin/updateAchievementShowHomePage', this.achievementInfo).then(res => {
        this.addDialogVisable = false
        this.loading = false
        this.getAchievement()
        this.$notify.success({
          title: '成功',
          message: '添加成功'
        })
      }).catch(error => {
        console.log(error)
        this.loading = false
        this.$notify.error({
          title: '错误',
          message: '内部错误'
        })
      })
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    filterTag  (value, row) {
      return row.achievementtype === value
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        this.loading = true
        this.$http.post('/admin/deleteHomePageAchievement', this.multipleSelection).then(res => {
          this.loading = false
          this.getAchievement()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
    this.getAchievement()
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
