
<template>
    <div class="homeView_container">
        <el-row :class="navBar.ifTransparent ? 'navbar_containerTrans' : 'navbar_container'">
            <el-col :xs="12" :sm="6" :md="5" :lg="5" :xl="4" class="logo">
                <router-link to="/">
                  <el-image :src="navBar.sslogo"
                    >
                      <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                      </div>
                  </el-image>
                </router-link>
            </el-col>
            <el-col :sm="15" :md="15" :lg="15" :xl="16" class="nav hidden-sm-and-down">
                <div v-for="nav in navBar.navName" :key="nav.index" :class="navBar.isShowNavBar?'animate__animated animate__fadeInRight':'animate__animated animate__fadeOutRight'" @click="goIndex(nav.index)">
                    <el-button type="text">{{nav.name}}</el-button>
                </div>
                <a target="_blank" href="http://cwxt.buildone.com.cn:20005/#/login" :class="navBar.isShowNavBar?'animate__animated animate__fadeInRight':'animate__animated animate__fadeOutRight'">
                  <el-button type="text">山水学堂</el-button>
                </a>
            </el-col>
            <el-col :xs="12" :sm="18" :md="4" :lg="4" :xl="4" class="btns">
                <span class="iconfont icon-line-search" @click="$jumpTo('/search')"></span>
                <span class="iconfont icon-line-open" @click="clickNavBar"></span>
            </el-col>
            <div  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="navSM_container hidden-md-and-up ">
                <div :class="!navBar.isShowNavBar?'navSM animate__animated animate__slideInDown':'navSM display_none'">
                    <div v-for="nav in navBar.navName" :key="nav.index" @click="goIndex(nav.index)">
                        <el-button type="text">{{nav.name}}</el-button>
                    </div>
                    <div @click="goExam">
                        <el-button type="text">山水学堂</el-button>
                    </div>
                </div>
            </div>
        </el-row>
        <div class="homeRouterView_container">
            <div class="img_container"></div>
            <!-- 背景图片 -->
            <div class="subPage_container">
                <router-view />
            </div>
        </div>
        <!-- 底部 -->
        <div class="navBottom_container">
          <div class="bottomNavBar_container hidden-sm-and-down">
              <div class="bottomNavBar_item_container" v-for="menu in bootomInfo.bottomNavInfo" :key="menu.menuId">
                  <div class="bottomNavBar_item_menu"  @click="$jumpTo(menu.menuIndex)">
                      {{menu.menuName}}
                  </div>
                  <div class="bottomNavBar_item_subMenu" v-for="subMenu in menu.subMenu" :key="subMenu.subMenuId" @click="jumpToSubMenu(menu.menuIndex, subMenu.subMenuId)">
                      {{subMenu.subMenuName}}
                  </div>
              </div>
              <div class="bottomNavBar_item_container">
                  <div class="bottomNavBar_item_menu">
                      关注我们
                  </div>
                  <div class="bottomNavBar_item_subMenu_container">
                     <el-image :src="bootomInfo.emwUrl" fit="contain" style="width:90px;height:90px;"
                     :preview-src-list="imgList">
                        <div slot="placeholder" class="image-slot">
                          加载中<span class="dot">...</span>
                        </div>
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                  </div>
              </div>
          </div>
           <div  class="bottomText_container">
              <div>江苏山水环境建设集团股份有限公司 版权所有</div>
              <div>苏ICP备10039575号 江苏必得网络有限公司承建</div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      imgList: ['https://ssjt.buildone.com.cn/ewm1.jpg'],
      navBar: {
        ifTransparent: false,
        isShowNavBar: true,
        sslogo: 'https://ssjt.buildone.com.cn/sslogo.png',
        navName: [
          {
            index: '/gyss',
            name: '关于山水'
          },
          {
            index: '/xwzx',
            name: '新闻中心'
          },
          {
            index: '/yjzs',
            name: '业绩展示'
          },
          {
            index: '/sswh',
            name: '山水文化'
          },
          {
            index: '/zxns',
            name: '招贤纳士'
          },
          {
            index: '/lxss',
            name: '联系山水'
          },
          {
            index: '/dgbsfw',
            name: '党工办事服务'
          }
        ]
      },
      bootomInfo: {
        emwUrl: 'https://ssjt.buildone.com.cn/ewm1.jpg',
        bottomNavInfo: [
          {
            menuId: 0,
            menuIndex: 'gyss',
            menuName: '关于山水',
            subMenu: [
              {
                subMenuId: 1,
                subMenuName: '山水介绍',
                subMenuIndex: '/ssjs'
              },
              {
                subMenuId: 2,
                subMenuName: '山水荣誉',
                subMenuIndex: '/ssry'
              },
              {
                subMenuId: 3,
                subMenuName: '成长历程',
                subMenuIndex: '/cclc'
              },
              {
                subMenuId: 4,
                subMenuName: '核心板块',
                subMenuIndex: '/hcmk'
              },
              {
                subMenuId: 5,
                subMenuName: '山水党建',
                subMenuIndex: '/ssdj'
              }
            ]
          },
          {
            menuId: 1,
            menuIndex: 'xwzx',
            menuName: '新闻中心',
            subMenu: [
              {
                subMenuId: 1,
                subMenuName: '企业新闻',
                subMenuIndex: '/ssjs'
              },
              {
                subMenuId: 2,
                subMenuName: '业内资讯',
                subMenuIndex: '/ssry'
              },
              {
                subMenuId: 3,
                subMenuName: '相关法规',
                subMenuIndex: '/cclc'
              }
            ]
          },
          {
            menuId: 2,
            menuIndex: 'yjzs',
            menuName: '业绩展示',
            subMenu: [
              {
                subMenuId: 1,
                subMenuName: '设计',
                subMenuIndex: '/sj'
              },
              {
                subMenuId: 2,
                subMenuName: '施工',
                subMenuIndex: '/sg'
              },
              {
                subMenuId: 3,
                subMenuName: '监理',
                subMenuIndex: '/jl'
              },
              {
                subMenuId: 4,
                subMenuName: '资讯',
                subMenuIndex: '/zx'
              },
              {
                subMenuId: 5,
                subMenuName: '科研',
                subMenuIndex: '/ky'
              }
            ]
          },
          {
            menuId: 3,
            menuIndex: 'sswh',
            menuName: '山水文化',
            subMenu: [
              {
                subMenuId: 1,
                subMenuName: '宣传片',
                subMenuIndex: '/xcp'
              },
              {
                subMenuId: 2,
                subMenuName: '员工风采',
                subMenuIndex: '/ssr'
              },
              {
                subMenuId: 3,
                subMenuName: '项目展示',
                subMenuIndex: '/ylzs'
              },
              {
                subMenuId: 4,
                subMenuName: '推荐文章',
                subMenuIndex: '/zwy'
              }
            ]
          },
          {
            menuId: 4,
            menuIndex: 'zxns',
            menuName: '招贤纳士',
            subMenu: [
              {
                subMenuId: 1,
                subMenuName: '人才招聘',
                subMenuIndex: '/rczp'
              },
              {
                subMenuId: 2,
                subMenuName: '人才理念',
                subMenuIndex: '/rcln'
              }
            ]
          },
          {
            menuId: 5,
            menuIndex: 'lxss',
            menuName: '联系山水',
            subMenu: [
              {
                subMenuId: 1,
                subMenuName: '联系我们',
                subMenuIndex: '/lxwm'
              },
              {
                subMenuId: 2,
                subMenuName: '资讯服务',
                subMenuIndex: '/ssry'
              },
              {
                subMenuId: 3,
                subMenuName: '周边旅游',
                subMenuIndex: '/zbly'
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    goExam () {
      window.location = 'http://cwxt.buildone.com.cn:20005/#/login'
    },
    jumpToSubMenu (url, typeid) {
      console.log(url)
      console.log(typeid)
      sessionStorage.setItem('tabTypeId', typeid)
      this.$router.push(url)
    },
    clickNavBar () {
      this.navBar.isShowNavBar = !this.navBar.isShowNavBar
    },
    goIndex (index) {
      // console.log(index)
      this.navBar.isShowNavBar = true
      this.$router.push(index)
    },
    // 页面滚动 导航透明
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
      if (scrollTop > 100) {
        this.navBar.ifTransparent = true
      } else {
        this.navBar.ifTransparent = false
      }
    },
    testList () {
      console.log(this.navBar.navName)
      this.$http.post('/testList', this.navBar.navName)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="less" scoped>
.display_none {
  display: none !important;
}
.homeView_container {
  width: 100%;
//   min-height: 100vh;
  background-color: #fff;
}
.navbar_container {
  width: 100%;
  height: 100px;
  background-color: #000;
  position: fixed;
  top: 0;
  z-index: 999;
}
.navbar_containerTrans {
  width: 100%;
  height: 100px;
  background-color: rgba(0, 0, 0,0.5);
//   background-color: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
}
.logo {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  align-items: center;
}
.logo .el-image {
  width: 240px;
  height: 60px;
  cursor: pointer;
}
@media screen and (max-width: 500px){
  .navbar_container {
    width: 100%;
    height: 80px;
    background-color: #000;
    position: fixed;
    top: 0;
  }
  .navbar_containerTrans {
    width: 100%;
    height: 80px;
    background-color: rgba(0, 0, 0,0.5);
    position: fixed;
    top: 0;
  }
  .logo .el-image {
    width: 160px;
    height: 40px;
    cursor: pointer;
  }
}
.nav {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nav .el-button--text:hover{
  color: #0080FF;
}
.nav .el-button{
  font-size: 100%;
  color: #fff;
  margin: 0 5px;
}
// sm 及以下显示 下拉菜单
.navSM_container {
  width: 100%;
  // height: 300px;
  position: absolute;
  top: 100px;
  z-index: 99;
  overflow: hidden;
}
.navSM .el-button--text:hover{
  color: #0080FF;
}
.navSM .el-button{
  // border-top: 1px solid #fff;
  font-size: 100%;
  width: 100%;
  padding: 10px 0;
  color: #fff;
  letter-spacing: .1rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: unset;
}
.btns {
  padding: 0 20px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btns span{
  color: #fff;
  font-size: 200%;
  margin: 0 10px;
  cursor: pointer;
}
.btns span:hover{
  color: #0080FF;
}
.img_container {
    position:relative;
    box-shadow: 0 1px 5px rgba(0,0,0,0.3),0 0 40px rgba(0,0,0,0.1) inset;
    margin-top: 100px;
    width: 100%;
    height: 400px;
    background-image: url('https://ssjt.buildone.com.cn/child_bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
// 动态内容区域
.homeRouterView_container {
    width: 100%;
    background-color: #fff;
}
.subPage_container {
  width: 100%;
  min-height: 450px;
  padding: 5px;
  margin: 0 auto;
    // background-color: #F6F6F6;
}
@media screen and (min-width: 1200px){
  .subPage_container {
    width: 1200px;
    min-height: 450px;
  }
}
@media screen and (max-width: 500px){
    .navSM_container {
        width: 100%;
        // height: 300px;
        // background-color: red;
        position: fixed;
        top: 80px;
        z-index: 99;
        overflow: hidden;
    }
    .img_container {
        width: 100%;
        margin-top: 80px;
        height: 200px;
    }
    .subPage_container {
      width: 100%;
      min-height: 450px;
      padding: 1px;
        // background-color: #F6F6F6;
    }
}

// 底部
.navBottom_container {
    width: 100%;
}
.bottomNavBar_container {
    width: 100%;
    height: 90%;
    padding: 20px 0;
    background-color: rgb(53, 53, 53);
    color: #fff;
    display: flex;
    justify-content: center;
}
.bottomNavBar_item_container {
    height: 100%;
    padding: 0 2%;
}
.bottomNavBar_item_menu{
    width: 100%;
    padding: 10px 5px;
    font-size: 100%;
    cursor: pointer;
}
.bottomNavBar_item_subMenu {
    width: 100%;
    padding: 5px;
    cursor: pointer;
    font-size: 90%;
}
.bottomNavBar_item_menu:hover ,.bottomNavBar_item_subMenu:hover{
    color: #0080FF;
}
// 底部备案
.bottomText_container {
    width: 100%;
    height: 30px;
    background-color: #2E2E2E;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 50%;
    color: #fff;
}
</style>
