<template>
<!-- 山水荣誉 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="荣誉名称"
                width="300"
                prop="honourname">
                </el-table-column>
                <el-table-column
                label="图片链接"
                prop="honourimgurl">
                </el-table-column>
                <el-table-column
                fixed="right"
                align="center"
                prop="honourtype"
                label="类型"
                width="180"
                sortable
                :filters="ssryTypeInfo"
                :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.honourtype === '0'" type="success">山水资质</el-tag>
                    <el-tag v-if="scope.row.honourtype === '1'" type="info">企业荣誉</el-tag>
                    <el-tag v-if="scope.row.honourtype === '2'" type="warning">工程荣誉</el-tag>
                    <el-tag v-if="scope.row.honourtype === '3'" type="danger">科研荣誉</el-tag>
                    <el-tag v-if="scope.row.honourtype === '4'" >山水形象</el-tag>
                </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia lunbo -->
        <el-dialog title="添加山水荣誉" :visible.sync="addDialogVisable" width="600px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="ssryInfo" ref="addDialogRef" :rules="addDialogRules">
                <el-form-item label="荣誉名称" label-width="100px" prop="honourname">
                    <el-input type="text" v-model="ssryInfo.honourname"></el-input>
                </el-form-item>
                <el-form-item label="获取时间"  label-width="100px" prop="honourdate">
                    <el-date-picker
                      v-model="ssryInfo.honourdate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="所属分类" label-width="100px" prop="honourtype">
                    <el-select v-model="ssryInfo.honourtype" placeholder="请选择文章">
                        <el-option v-for="item in ssryTypeInfo" :key="item.value" :label="item.text" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px" prop="honourimgurl">
                    <el-upload
                      :auto-upload="true"
                      ref="honourimgurl"
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :on-error="handleError"
                      :on-success="uploadSuccess"
                      :file-list="QiniuData.fileList"
                      :before-upload="beforeAvatarUpload">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="修改业绩" :visible.sync="editDialogVisable"  width="600px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="editSsryInfo" ref="addDialogRef" :rules="addDialogRules">
                <el-form-item label="荣誉名称" label-width="100px" prop="honourname">
                    <el-input type="text" v-model="editSsryInfo.honourname"></el-input>
                </el-form-item>
                <el-form-item label="获取时间"  label-width="100px" prop="honourdate">
                    <el-date-picker
                      v-model="editSsryInfo.honourdate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="所属分类" label-width="100px" prop="honourtype">
                    <el-select v-model="editSsryInfo.honourtype" placeholder="请选择文章">
                        <el-option v-for="item in ssryTypeInfo" :key="item.value" :label="item.text" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px" prop="honourimgurl">
                    <el-upload
                      :auto-upload="true"
                      ref="honourimgurl"
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      :on-error="handleError"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :on-success="uploadEditSuccess"
                      :file-list="QiniuData.fileList"
                      :before-upload="beforeAvatarUpload">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="SubmitEditDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {
      listCardImg: '',
      showImgsDialogVisible: false,
      ssryTypeInfo: [
        {
          text: ' 山水资质',
          value: '0'
        },
        {
          text: '企业荣誉',
          value: '1'
        },
        {
          text: '工程荣誉 ',
          value: '2'
        },
        {
          text: '科研荣誉',
          value: '3'
        },
        {
          text: '山水形象',
          value: '4'
        }
      ],
      editSsryInfo: {},
      ssryInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        moreFileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      },
      addDialogRules: {
        honourname: [
          { required: true, message: '请输入荣誉名称', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        honourtype: [
          { required: true, message: '请选择所属分类', trigger: 'change' }
        ],
        honourdate: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        honourimgurl: [
          { required: true, message: '请选择封面', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    handleError (file) {
      console.log(file)
      this.$message.error('上传错误，请刷新页面重新上传')
    },
    getHonour () {
      this.loading = true
      this.$http.get('/admin/getHonour').then(res => {
        console.log(res)
        this.loading = false
        this.tableData = res.data.data
      })
    },
    // editDialog
    SubmitEditDialog () {
      this.loading = true
      // console.log(this.ssryInfo)
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.$http.post('/admin/editHonour', this.editSsryInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.getHonour()
              this.editDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
            this.getHonour()
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    // 提交dialog内容 addDialog
    submitDialog () {
      this.loading = true
      // console.log(this.ssryInfo)
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.$http.post('/admin/addHonour', this.ssryInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.addDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
            this.getHonour()
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    dialogClose () {
      this.ssryInfo = {}
      this.editSsryInfo = {}
      this.QiniuData.fileList = []
      this.loading = false
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    filterTag  (value, row) {
      return row.honourtype === value
    },
    // 修改操作
    editSelection () {
      if (this.multipleSelection.length === 1) {
        this.editSsryInfo = this.multipleSelection[0]
        console.log(this.ssryInfo)
        if (this.editSsryInfo.honourimgurl !== '' && this.editSsryInfo.honourimgurl !== null) {
          this.QiniuData.fileList.push({
            name: this.editSsryInfo.honourimgurl.replace('https://ssjt.buildone.com.cn/', ''),
            url: this.editSsryInfo.honourimgurl
          })
        }
        this.editDialogVisable = true
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    // 删除单张
    handleRemove (file, fileList) {
      this.ssryInfo.honourimgurl = ''
      this.QiniuData.fileList = []
    },
    handlePreview (file) {
      console.log(file)
      this.showImgsDialogVisible = true
      this.listCardImg = file.url
    },
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取token数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    beforeAvatarUpload (file) {
      const isPNG = file.type === 'image/png'
      const isJPEG = file.type === 'image/jpeg'
      const isJPG = file.type === 'image/jpg'
      const isGIF = file.type === 'image/gif'
      // 本地测试8M
      // const isLt8M = file.size / 1024 / 1024 < 8
      if (!isPNG && !isJPEG && !isJPG && !isGIF) {
        this.$message.error('上传头像图片只能是 jpg、png、jpeg、gif 格式!')
        return false
      }
      // if (!isLt8M) {
      //   this.$message.error('上传头像图片大小不能超过 8MB!')
      //   return false
      // }
      this.QiniuData.data.key = `${file.name}-sf`
    },
    uploadSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.fileList = []
      this.ssryInfo.honourimgurl = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: this.ssryInfo.honourimgurl
      })
      this.loading = false
    },
    uploadEditSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.fileList = []
      this.editSsryInfo.honourimgurl = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: `${this.QiniuData.qiniuaddr}${response.key}`
      })
      this.loading = false
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        console.log('edit')
        this.loading = true
        this.$http.post('/admin/deleteHonour', this.multipleSelection).then(res => {
          this.loading = false
          this.getHonour()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
    this.getQiniuToken()
  },
  created () {
    this.getHonour()
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
