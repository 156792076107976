<template>
  <div class="container">
      1
  </div>
</template>

<script>
export default {
  data () {
    return {
      ifDisable: false
    }
  },
  methods: {
    // todo
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 800px;
  padding: 20px 10px;
  // background-color: red;
}
</style>
