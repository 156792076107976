<template>
    <div class="container" style="background-image">
        <div class="login_box">
            <el-avatar size="large" @error="errorHandler" :src="avatorUrl" fit="cover"></el-avatar>
            <div class="login_form">
                <el-form ref="loginForm" :model="loginInfo" :rules="loginFormRules" label-width="80px">
                    <el-form-item label="账号:" prop="username">
                        <el-input v-model="loginInfo.username" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="密码:" prop="password">
                        <el-input v-model="loginInfo.password" show-password @keypress.native.enter="handleLoginInfo"></el-input>
                    </el-form-item>
                    <el-form-item class="login_btns">
                        <el-button type="primary" :loading="loginLoading" @click="handleLoginInfo">{{ loginMsg }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      avatorUrl: 'https://ssjt.buildone.com.cn/avator.jpg',
      loginInfo: {},
      isRemember: true,
      loginLoading: false,
      loginMsg: '登陆',
      loginFormRules: {
        username: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在3到10个字符之间', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 16, message: '长度在5到16个字符之间', trigger: 'blur' }
        ]
      },
      operationInfo: {
        operationId: '',
        loginName: '',
        operationTime: '',
        operations: ''
      }
    }
  },
  methods: {
    onEnterPress () {
      console.log('enetr')
    },
    errorHandler () {
      return true
    },
    handleLoginInfo () {
      this.loginLoading = true
      this.loginMsg = '登陆中'
      // 加密
      this.$refs.loginForm.validate((valid) => {
        if (!valid) {
          this.loginMsg = '登陆'
          this.loginLoading = false
          return false
        } else {
          this.$http.post('/admin/login', this.loginInfo).then(res => {
            // console.log(res)
            if (res.data.code === 200) {
              this.$message.success('登录成功')
              window.sessionStorage.setItem('isLogin', true)
              this.loginInfo.password = '***'
              window.sessionStorage.setItem('userInfo', JSON.stringify(this.loginInfo))
              this.$router.push('/adminHome')
            } else {
              this.$message.error('密码错误或者账户不存在')
              this.loginInfo.password = ''
              this.loginMsg = '登陆'
              this.loginLoading = false
            }
          })
        }
      })
    }
  },
  created () {
  }
}
</script>

<style lang="less" scoped>
    .container {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('http://www.buildone.com.cn/adminBJFM.png');
        background-size: cover;
        background-position: center;
    }
    .login_box {
        width: 400px;
        height: 350px;
        background-color: #fff;
        border-radius: 10px;
        position: relative;
    }
    .el-avatar{
        width: 100px;
        height: 100px;
        border: 2px solid lightgray;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        margin: 5px;
    }
    .login_form{
        max-width: 90%;
        min-width: 300px;
        height: 200px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%);
    }
    .rememberBtn{
        margin-right: 50px !important;
    }
</style>
