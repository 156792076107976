<template>
    <div class="gyss_container">
        <div class="tabs_btns_container">
            <div class="gyss_btn" v-for="item in tabBtnInfo" :key="item.typeId" @click="changeType(item.typeId)">
                {{item.typeName}}
            </div>
        </div>
        <div class="tabs_content_container">
            <div :class="tabTypeId == 1 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="lxwm_container">
                  <div class="lxwm_map_container">
                      <baidu-map id="map" class="map"
                      :scroll-wheel-zoom="true"
                      center="句容市"
                      :zoom="zoom"
                      @ready="ready"
                      @click="getLocationPoint">
                      </baidu-map>
                  </div>
                  <div class="lxwm__content_container">
                      <div class="lxwm__content_body">
                          <div class="lxwm__name_container">
                            {{companyInfo.name}}
                          </div>
                          <div class="lxwm__tel_container">
                            <i class="el-icon-phone"></i>
                            {{companyInfo.tel}}
                          </div>
                          <div class="lxwm__address_container">
                            <i class="el-icon-map-location"></i>
                            {{companyInfo.province + companyInfo.city + companyInfo.address}}
                          </div>
                      </div>
                      <div class="lxwm__btns_body">
                          <el-button type="primary" v-for="item in addressInfo" :key="item.id" @click="changeIt(item)">{{item.reName}}</el-button>
                      </div>
                  </div>
                </div>
            </div>
            <div :class="tabTypeId == 2 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="zxfw_container">
                    <div class="zxfw_zxly_container" v-loading.lock="loading">
                        <div class="zxfw_zxly_title">
                            <div class="zxfw_zxly_title_ch">
                                在线留言
                            </div>
                            <div class="dot"></div>
                            <div class="zxfw_zxly_title_en">
                                ONLINE MESSAGES
                            </div>
                        </div>
                        <div class="zxfw_zxly_form_container">
                            <div class="zxfw_zxly_form_body">
                                <el-form ref="form" :model="zxlyFormInfo" :rules="rules" label-width="60px">
                                    <el-form-item label="姓名" prop="msgname">
                                      <el-input v-model="zxlyFormInfo.msgname"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机" prop="msgtel">
                                      <el-input v-model="zxlyFormInfo.msgtel"></el-input>
                                    </el-form-item>
                                    <el-form-item label="留言" prop="msgtext">
                                      <el-input type="textarea" v-model="zxlyFormInfo.msgtext"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                      <el-button type="primary" @click="submitForm('form')">立即提交</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div class="zxfw_csxx_container">
                      <div class="zxfw__content_body">
                          <div class="zxfw__name_container">
                            江苏山水环境建设集团股份有限公司
                          </div>
                          <div class="zxfw__net_container">
                            <span class="iconfont icon-net"></span>http://www.jsssjt.com
                          </div>
                          <div class="zxfw__tel_container">
                            <i class="el-icon-phone"></i>0511-87708008
                          </div>
                          <div class="zxfw__address_container">
                            <i class="el-icon-map-location"></i>江苏省镇江市句容市长江路399号
                          </div>
                          <div class="zxfw__gzhewm_container">
                              <el-image :src="gzhRwmImgUrl" fit="cover"></el-image>
                              <div class="zxfw__gzhewm_content">微信公众号</div>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
            <div :class="tabTypeId == 3 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="zxly_container">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide class="slide-1"  v-for="item in zblyInfo"  :key="item.travelid">
                            <div class="zbly_item_container">
                                <div class="zbly_img_container">
                                    <el-image :src="item.travelimgurl" fit="cover"></el-image>
                                </div>
                                <div class="zbly_content_container">
                                  <div class="zbly_title_container">
                                      {{item.travelname}}
                                  </div>
                                  <div class="zbly_text_container">
                                      {{item.travetext}}
                                  </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination swiper-pagination-grey" slot="pagination"></div>
                        <!-- <div class="swiper-button-prev swiper-button-grey" slot="button-prev"></div>
                        <div class="swiper-button-next swiper-button-grey" slot="button-next"></div> -->
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      gzhRwmImgUrl: 'https://ssjt.buildone.com.cn/ewm1.jpg',
      ak: 'VgSLGUz2DBAxKRwoivwHIUbM69rANB5t',
      tabTypeId: 1,
      activeCzlcId: '',
      location: {
        lng: 116.331398,
        lat: 39.897445
      },
      zoom: 16,
      addressKeyword: '',
      tabBtnInfo: [
        {
          typeName: '联系我们',
          typeId: 1
        },
        {
          typeName: '咨询服务',
          typeId: 2
        },
        {
          typeName: '周边旅游',
          typeId: 3
        }
      ],
      addressInfo: [],
      companyInfo: {},
      zxlyFormInfo: {
        msgname: '',
        msgtel: '',
        msgtext: ''
      },
      rules: {
        msgname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, message: '长度在 2 个字符以上', trigger: 'blur' }
        ],
        msgtel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        msgtext: [
          { required: true, message: '请填写留言', trigger: 'blur' }
        ]
      },
      swiperOption: {
        spaceBetween: 30,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      zblyInfo: []
    }
  },
  methods: {
    // 获取公司列表
    getCompanyList () {
      this.$http.get('/admin/getCompanyList').then(res => {
        // console.log(res)
        this.addressInfo = res.data.data
        this.namedAddress()
      })
    },
    // 获取周边旅游
    getTableData () {
      this.$http.get('/admin/getTravel').then(res => {
        // console.log(res)
        this.zblyInfo = res.data.data
      })
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    changeType (typeId) {
      // console.log(typeId)
      this.tabTypeId = typeId
      this.$refs.form.resetFields()
    },
    ready (e) {
      const reallyAddress = '江苏省镇江市句容市长江路399号'
      this.showMaps(reallyAddress)
    },
    getLocationPoint (e) {
      // console.log(e)
      this.lng = e.point.lng
      this.lat = e.point.lat
      /* 创建地址解析器的实例 */
      const geoCoder = new window.BMap.Geocoder()
      /* 获取位置对应的坐标 */
      geoCoder.getPoint(this.addressKeyword, point => {
        this.selectedLng = point.lng
        this.selectedLat = point.lat
      })
      /* 利用坐标获取地址的详细信息 */
      geoCoder.getLocation(e.point, res => {
        // console.log(res)
      })
    },
    showMaps (address) {
      const map = new window.BMap.Map('map')
      const myGeo = new window.BMap.Geocoder()
      // 将地址解析结果显示在地图上,并调整地图视野
      myGeo.getPoint(address, function (point) {
        // console.log(point)
        map.centerAndZoom(point, 16)
        map.addOverlay(new window.BMap.Marker(point))
      })
    },
    namedAddress () {
      const addressInfo = this.addressInfo
      for (let i = 0; i < addressInfo.length; i++) {
        if (addressInfo[i].name === '集团总部') {
          this.addressInfo[i].reName = addressInfo[i].name.substring(2, 4)
          let data = {}
          this.companyInfo = addressInfo[i]
          data = this.addressInfo[0]
          this.addressInfo[0] = this.addressInfo[i]
          this.addressInfo[i] = data
        } else {
          this.addressInfo[i].reName = addressInfo[i].city.substring(0, 2)
        }
      }
    },
    changeIt (data) {
      const address = data.province + data.city + data.address
      // console.log(data)
      this.companyInfo = data
      this.showMaps(address)
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.zxlyFormInfo.msgstate = '0'
          this.$http.post('/admin/addMsg', this.zxlyFormInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              alert('提交成功')
            } else {
              alert('提交失败')
            }
            this.zxlyFormInfo = {
              msgname: '',
              msgtel: '',
              msgtext: ''
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  },
  created () {
    this.getCompanyList()
    this.getTableData()
  },
  mounted () {
    // this.$nextTick(() => {
    //   const swiperTop = this.$refs.swiperTop.$el.swiper
    //   const swiperThumbs = this.$refs.swiperThumbs.$el.swiper
    //   console.log(swiperTop)
    //   console.log(swiperThumbs)
    //   swiperTop.controller.control = swiperThumbs
    //   swiperThumbs.controller.control = swiperTop
    // })
  }
}
</script>

<style lang="less" scoped>
// 新的
.gyss_container {
    width: 100%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
}
.tabs_btns_container {
    width: 100%;
    //  background-color: red;
    padding: 10px 5px;
    font-size: 100%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
}
.gyss_btn {
    padding: 10px 20px;
    background-color: transparent;
    text-align: center;
    border-radius: 5px;
    color: #808080;
    cursor: pointer;
}
.gyss_btn:hover{
    background-color: #F5F5F5;
    color: #000;
}
.gyss_btn_click{
    background-color: #F5F5F5;
    color: #000;
}
.tabs_content_container {
    width: 100%;
    min-height: 200px;
    padding: 5px;
    overflow: hidden;
}
.tabs_content_item {
    width: 100%;
    min-height: 200px;
    overflow: hidden;
}
.tabs_content_item_hide {
    width: 100%;
    height: 0;
    overflow: hidden;
}
@media screen and (max-width: 500px){
  .tabs_btns_container {
      width: 100%;
      //  background-color: red;
      padding: 5px;
      font-size: 10px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
  }
  .gyss_btn {
      padding: 10px 10px;
      background-color: #F5F5F5;
      text-align: center;
      border-radius: 5px;
      color: #808080;
      cursor: pointer;
  }
  .gyss_btn:hover{
      background-color: #F5F5F5;
      color: #000;
  }
  .gyss_btn_click{
      background-color: #F5F5F5;
      color: #000;
  }
  .tabs_content_container {
      width: 100%;
      min-height: 200px;
      padding: 5px;
      overflow: hidden;
  }
  .tabs_content_item {
      width: 100%;
      min-height: 200px;
  }
}
// 联系我们
.lxwm_container {
  width: 100%;
  // display: flex;
}
.lxwm_map_container {
  width: 50%;
  // height: 400px;
  float: left;
}
.map {
  width: 100%;
  height: 400px;
}
.lxwm__content_container {
  width: 50%;
  // height: 400px;
  padding: 5px 10px;
  float: left;
}
.lxwm__content_body {
  width: 100%;
  padding: 10px 0;
}
.lxwm__name_container {
  width: 100%;
  padding: 10px;
  font-size: 150%;
}
.lxwm__tel_container {
  width: 100%;
  padding: 5px 10px;
  font-size: 100%;
}
.lxwm__address_container {
  width: 100%;
  padding: 5px 10px;
  font-size: 100%;
}
.lxwm__btns_body {
  width: 100%;
  padding: 10px 0;
}
.lxwm__btns_body .el-button{
    margin: 5px 8px;
    border:0;
    border-radius: 6px;
    color: white;
    background: #5f55af;
    padding: 10px 20px;
    text-transform: uppercase;
    background: linear-gradient(to right,#f72585 50%, #5f55af 50%);
    background-size: 200%,100%;
    background-position: right bottom;
    transition: all 1s ease;
    font-size: 1rem;
    cursor: pointer;
}
.lxwm__btns_body > .el-button:hover {
    background-position: left bottom;
}
@media screen and (max-width: 768px){
  .lxwm_map_container {
    width: 100%;
    // height: 400px;
    float: left;
  }
  .map {
    width: 100%;
    height: 300px;
  }
  .lxwm__content_container {
    width: 100%;
    // height: 400px;
    padding: 0;
    float: left;
  }
  .lxwm__content_body {
    width: 100%;
    height: 150px;
  }
  .lxwm__name_container {
    width: 100%;
    padding: 10px;
    font-size: 150%;
  }
  .lxwm__tel_container {
    width: 100%;
    padding: 5px 10px;
    font-size: 100%;
  }
  .lxwm__address_container {
    width: 100%;
    padding: 5px 10px;
    font-size: 100%;
  }
  .lxwm__btns_body {
    width: 100%;
    padding: 0;
  }
}
// 资讯服务
.zxfw_container {
  width: 100%;
  padding: 10px 0;
}
.zxfw_zxly_container {
  width: 55%;
  margin: 0 2%;
  float: left;
  border: 1px solid lightgrey;
  border-radius: 5px;
}
.zxfw_csxx_container {
  width: 40%;
  height: 500px;
  float: left;
}
.zxfw_zxly_title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  letter-spacing: 0.1rem;
}
.zxfw_zxly_title_ch {
  color: #000;
  font-size: 200%;
  font-weight: 200;
}
.zxfw_zxly_title_en {
  color: orangered;
  font-size: 200%;
  font-weight: 200;
}
.dot {
  height: 10px;
  width: 10px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: orangered;
}
.zxfw_zxly_form_container {
  width: 100%;
  padding: 10px 0;
  min-height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zxfw_zxly_form_body {
  width: 80%;
  margin: 0 auto;
}
.zxfw__content_body {
  width: 100%;
  padding: 10px 0;
}
.zxfw__name_container {
  width: 100%;
  padding: 10px;
  font-size: 150%;
}
.zxfw__net_container, .zxfw__tel_container, .zxfw__address_container{
  width: 100%;
  padding: 10px;
  font-size: 100%;
}
.zxfw__gzhewm_container {
  width: 200px;
  padding: 10px;
  font-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zxfw__gzhewm_container .el-image{
  width: 200px;
}
@media screen and (max-width: 768px) and (min-width: 500px){
  .zxfw_container {
    width: 100%;
    padding: 10px 0;
  }
  .zxfw_zxly_container {
    width: 60%;
    margin: 0;
    float: left;
  }
  .zxfw_csxx_container {
    width: 40%;
    height: 500px;
    float: left;
  }
  .zxfw_zxly_title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    letter-spacing: 0.1rem;
  }
  .zxfw_zxly_title_ch {
    color: #000;
    font-size: 150%;
    font-weight: 200;
  }
  .zxfw_zxly_title_en {
    color: orangered;
    font-size: 150%;
    font-weight: 200;
  }
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: orangered;
  }
  .zxfw_zxly_form_container {
    width: 100%;
    padding: 10px 0;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .zxfw_zxly_form_body {
    width: 90%;
    margin: 0 auto;
  }
  .zxfw__content_body {
    width: 100%;
    padding: 10px 0;
  }
  .zxfw__name_container {
    width: 100%;
    padding: 10px;
    font-size: 150%;
  }
  .zxfw__net_container, .zxfw__tel_container, .zxfw__address_container{
    width: 100%;
    padding: 10px;
    font-size: 100%;
  }
  .zxfw__gzhewm_container {
    width: 200px;
    padding: 10px;
    font-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .zxfw__gzhewm_container .el-image{
    width: 200px;
  }
}
@media screen and (max-width: 500px) {
  .zxfw_container {
    width: 100%;
    padding: 10px 0;
  }
  .zxfw_zxly_container {
    width: 100%;
    margin: 0;
    float: left;
  }
  .zxfw_csxx_container {
    width: 100%;
    height: 500px;
    float: left;
  }
  .zxfw_zxly_title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    // background-color: red;
    letter-spacing: 0.1rem;
  }
  .zxfw_zxly_title_ch {
    color: #000;
    font-size: 120%;
    font-weight: 200;
  }
  .zxfw_zxly_title_en {
    color: orangered;
    font-size: 120%;
    font-weight: 200;
  }
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: orangered;
  }
  .zxfw_zxly_form_container {
    width: 100%;
    padding: 10px 2px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .zxfw_zxly_form_body {
    width: 90%;
    margin: 0 auto;
  }
  .zxfw__content_body {
    width: 100%;
    padding: 10px 0;
  }
  .zxfw__name_container {
    width: 100%;
    padding: 10px;
    font-size: 150%;
  }
  .zxfw__net_container, .zxfw__tel_container, .zxfw__address_container{
    width: 100%;
    padding: 10px;
    font-size: 100%;
  }
  .zxfw__gzhewm_container {
    width: 200px;
    padding: 10px;
    font-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .zxfw__gzhewm_container .el-image{
    width: 200px;
  }
}
.zxly_container {
  width: 100%;
  height: 500px;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper .swiper-slide {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.zbly_item_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.zbly_img_container {
  width: 60%;
  height: 100%;
}
.zbly_img_container .el-image{
  width: 100%;
  height: 100%;
}
.zbly_content_container {
  width: 40%;
  height: 100%;
  padding: 5px 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.zbly_title_container {
  width: 100%;
  padding: 2px 0;
  font-size: 150%;
  font-weight: 200;
  color: #000;
  letter-spacing: 0.1rem;
}
.zbly_text_container {
  width: 100%;
  padding: 2px 0;
  font-size: 100%;
  line-height: 150%;
  font-weight: 200;
  color: #000;
  letter-spacing: 0.1rem;
  text-indent:2em;
}
@media screen and (max-width: 500px) {
  .zbly_item_container {
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
  }
  .zbly_img_container {
    width: 100%;
    height: 50%;
  }
  .zbly_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .zbly_content_container {
    width: 100%;
    height: 50%;
    padding: 5px 10px;
    overflow: hidden;
    display: unset;
  }
  .zbly_title_container {
    width: 100%;
    padding: 2px 0;
    font-size: 150%;
    font-weight: 200;
    color: #000;
    letter-spacing: 0.1rem;
  }
  .zbly_text_container {
    width: 100%;
    overflow: hidden;
    padding: 2px 0;
    font-size: 100%;
    line-height: 150%;
    font-weight: 200;
    color: #000;
    letter-spacing: 0.1rem;
    text-overflow: ellipsis;
    word-wrap: none;
  }
}
</style>
