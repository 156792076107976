<template>
    <div class="gyss_container">
        <div class="tabs_btns_container">
            <div class="gyss_btn" v-for="item in yjzsInfo" :key="item.typeId" @click="changeType(item.typeId)">
                {{item.typeName}}
            </div>
        </div>
        <div class="tabs_content_container">
            <div class="tabs_content_item">
                <div class="sheji_container">
                  <div class="sheji_itemList_container">
                      <div class="sheji_item_container" v-for="item in showData" :key="item.achievementid" @click="showDetails(item)">
                        <div class="sheji_item_img_container">
                            <el-image :src="item.achievementfaceimg" fit="cover">
                                <div slot="error" class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>
                        <div class="sheji_item_text_container">
                            {{item.achievementname}}
                        </div>
                      </div>
                  </div>
                </div>
                <div class="sheji_page_container">
                    <el-pagination
                      layout="prev, pager, next"
                      @current-change="pageChanged"
                      :current-page="pageInfo.currentPage"
                      :total="pageInfo.totals"
                      :page-size="pageInfo.pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      tabTypeId: 1,
      activeCzlcId: '',
      yjzsInfo: [
        {
          typeName: '设计',
          typeId: 0
        },
        {
          typeName: '施工',
          typeId: 1
        },
        {
          typeName: '监理',
          typeId: 2
        },
        {
          typeName: '资讯',
          typeId: 3
        },
        {
          typeName: '科研',
          typeId: 4
        }
      ],
      showData: [],
      shejiInfo: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 8,
        totals: 0
      }
    }
  },
  methods: {
    changeType (typeId) {
      this.tabTypeId = typeId
      this.pageInfo = {
        currentPage: 1,
        pageSize: 8,
        totals: 0
      }
      this.getYjInfo(typeId)
    },
    getYjInfo (typeId) {
      this.$http.post('/admin/getAchievementByPageType/' + typeId, this.pageInfo).then(res => {
        this.showData = res.data.achievements
        this.pageInfo.totals = res.data.totals
      })
    },
    showDetails (data) {
      window.sessionStorage.setItem('jdalDetails', JSON.stringify(data))
      this.$router.push('jdalDetails')
    },
    pageChanged (currentPage) {
      this.pageInfo.currentPage = currentPage
      this.getYjInfo(this.tabTypeId)
    }
  },
  created () {
    this.changeType(0)
  }
}
</script>

<style lang="less" scoped>
// 新的
.gyss_container {
    width: 100%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
}
.tabs_btns_container {
    width: 100%;
    //  background-color: red;
    padding: 10px 5px;
    font-size: 100%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
}
.gyss_btn {
    padding: 10px 20px;
    background-color: transparent;
    text-align: center;
    border-radius: 5px;
    color: #808080;
    cursor: pointer;
}
.gyss_btn:hover{
    background-color: #F5F5F5;
    color: #000;
}
.gyss_btn_click{
    background-color: #F5F5F5;
    color: #000;
}
.tabs_content_container {
    width: 100%;
    min-height: 200px;
    padding: 5px;
    overflow: hidden;
}
.tabs_content_item {
    width: 100%;
    min-height: 200px;
}
@media screen and (max-width: 500px){
  .tabs_btns_container {
      width: 100%;
      //  background-color: red;
      padding: 5px;
      font-size: 10px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
  }
  .gyss_btn {
      padding: 10px 10px;
      background-color: #F5F5F5;
      text-align: center;
      border-radius: 5px;
      color: #808080;
      cursor: pointer;
  }
  .gyss_btn:hover{
      background-color: #F5F5F5;
      color: #000;
  }
  .gyss_btn_click{
      background-color: #F5F5F5;
      color: #000;
  }
  .tabs_content_container {
      width: 100%;
      min-height: 200px;
      padding: 5px;
      overflow: hidden;
  }
  .tabs_content_item {
      width: 100%;
      min-height: 200px;
  }
}
// 设计
.sheji_container {
  width: 100%;
  min-height: 200px;
}
.sheji_itemList_container {
  width: 1130px;
  height: 450px;
  padding: 5px;
  margin: 0 auto;
}
.sheji_page_container {
  width: 100%;
  height: 30px;
  text-align: center;
  margin-top: 10px;
}
.sheji_item_container {
  width: 260px;
  height: 200px;
  float: left;
  margin: 10px;
  position: relative;
  cursor: pointer;
}
.sheji_item_img_container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 400;
  overflow: hidden;
}
.sheji_item_img_container .el-image{
  width: 100%;
  height: 100%;
  transition:all 2s;
  -moz-transition:all 2s; /* Firefox 4 */
  -webkit-transition:all 2s; /* Safari and Chrome */
  -o-transition:all 2s; /* Opera */
}
.sheji_item_img_container .el-image:hover{
  animation-name: Big;
  animation-duration: 5s;
}
@keyframes Big {
  100% {
    transform: scale(1.2);
  }
}
.sheji_item_text_container {
  width: 100%;
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  z-index: 500;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 90%;
  color: #f5f5f5;
  text-align: center;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 1024px){
  .sheji_itemList_container {
    width: 740px;
    height: 900px;
    padding: 5px;
  }
  .sheji_item_container {
    width: 345px;
    height: 200px;
    float: left;
    margin: 10px;
    position: relative;
    cursor: pointer;
  }
}
@media screen and (max-width: 760px){
  .sheji_itemList_container {
    width: 350px;
    height: 1770px;
    padding: 0;
  }
  .sheji_item_container {
    width: 350px;
    height: 200px;
    float: left;
    margin: 10px 0;
    position: relative;
    cursor: pointer;
  }
}
</style>
