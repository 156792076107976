<!--山水介绍-->
<template>
    <div class="wysp-container" v-loading.lock="loading" element-loading-text="获取数据中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button  type="primary" size="small" icon="el-icon-edit" @click="editBtn()">替换内容</el-button>
            </el-button-group>
        </div>
        <div class="body_container">
            <div class="wysp_body_urls" v-html="ssjsInfo.text"></div>
        </div>
        <el-dialog title="替换人才理念"  @close="dialogClose" :close-on-click-modal="false" v-loading.lock="loading"  element-loading-text="上传中" :visible.sync="addDialogVisable" width="900px">
            <editor-bar  v-model="editSsjsInfo.text" :isClear="isClear" @change="change"></editor-bar>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import EditorBar from './WangEditor/Editor.vue'
export default {
  components: { 'editor-bar': EditorBar },
  data () {
    return {
      // todo
      isClear: true,
      loading: false,
      addDialogVisable: false,
      ssjsInfo: {},
      editSsjsInfo: {}
    }
  },
  methods: {
    open () {
      console.log('open')
    },
    editBtn () {
      this.addDialogVisable = true
      this.editSsjsInfo = this.ssjsInfo
    },
    submitDialog () {
      console.log(this.ssjsInfo)
      this.editSsjsInfo.type = '1'
      this.$http.post('/admin/updateSimpleText', this.editSsjsInfo).then(res => {
        // console.log(res)
        this.addDialogVisable = false
        this.getSimpleText()
      })
    },
    // 关闭dialog
    dialogClose () {
      console.log('关闭dialog')
      this.ssjsInfo = {}
    },
    getSimpleText () {
      this.$http.post('/admin/getSimpleTextByType/1').then(res => {
        if (res.data.data.length > 0) {
          this.ssjsInfo = res.data.data[0]
        }
      })
    },
    // 富文本编辑器change事假
    change (val) {
      // console.log(val)
    }
  },
  created () {
    this.getSimpleText()
  }
}
</script>

<style lang="less" scoped>
.wysp-container {
    width: 100%;
    min-height: 500px;
    padding: 10px;
    margin-top: 50px;
}
.sylb_btns_container {
    width: 100%;
    padding: 20px 5px;
    background-color: #FFFFFF;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.body_container {
    width: 100%;
    margin: 10px 0;
    min-height: 700px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
}
.wysp_body_urls {
    width: 800px;
    padding: 5px;
    min-height: 700px;
    background-color: #FFFFFF;
}
</style>
