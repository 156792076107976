import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import homeView from '../views/homeView.vue'
import gyss from '../views/gyss.vue'
import xwzx from '../views/xwzx.vue'
import yjzs from '../views/yjzs.vue'
import sswh from '../views/sswh.vue'
import zxns from '../views/zxns.vue'
import lxss from '../views/lxss.vue'
import dgbsfw from '../views/dgbsfw.vue'
import search from '../views/search.vue'
import test from '../views/test.vue'
import articleDetail from '../components/articleDetail.vue'
import jdalDetails from '../components/jdalDetails.vue'
import adminLogin from '../admin/adminLogin.vue'
import adminHome from '../admin/adminHome.vue'
import overView from '../admin/overView.vue'
import carousel from '../admin/carousel.vue'
import alkz from '../admin/alkz.vue'
import yjkz from '../admin/yjkz.vue'
import lxfs from '../admin/lxfs.vue'
import wysp from '../admin/wysp.vue'
import adminSsjs from '../admin/admin_ssjs.vue'
import adminSsry from '../admin/admin_ssry.vue'
import adminCzlc from '../admin/admin_czlc.vue'
import adminQyhx from '../admin/admin_qyhx.vue'
import adminSsdj from '../admin/admin_ssdj.vue'
import adminQyxw from '../admin/admin_qyxw.vue'
import adminXgfg from '../admin/admin_xgfg.vue'
import adminYnzx from '../admin/admin_ynzx.vue'
import adminXcp from '../admin/admin_xcp.vue'
import adminYgfc from '../admin/admin_ygfc.vue'
import adminXmzs from '../admin/admin_xmzs.vue'
import adminTjwz from '../admin/admin_tjwz.vue'
import adminRczp from '../admin/admin_rczp.vue'
import adminCrLn from '../admin/admin_rcln.vue'
import adminLxwm from '../admin/admin_lxwm.vue'
import adminZbly from '../admin/admin_zbly.vue'
import adminSylb from '../admin/admin_sylb.vue'
import adminDgbs from '../admin/admin_dgbs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/homeView',
    name: 'homeView',
    component: homeView,
    children: [
      {
        path: '/gyss',
        name: 'gyss',
        component: gyss
      },
      {
        path: '/search',
        name: 'search',
        component: search
      },
      {
        path: '/test',
        name: 'test',
        component: test
      },
      {
        path: '/xwzx',
        name: 'xwzx',
        component: xwzx
      },
      {
        path: '/yjzs',
        name: 'yjzs',
        component: yjzs
      },
      {
        path: '/sswh',
        name: 'sswh',
        component: sswh
      },
      {
        path: '/zxns',
        name: 'zxns',
        component: zxns
      },
      {
        path: '/lxss',
        name: 'lxss',
        component: lxss
      },
      {
        path: '/dgbsfw',
        name: 'dgbsfw',
        component: dgbsfw
      },
      {
        path: '/articleDetail',
        name: 'articleDetail',
        component: articleDetail
      },
      {
        path: '/jdalDetails',
        name: 'jdalDetails',
        component: jdalDetails
      }
    ]
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: adminLogin
  },
  {
    path: '/adminHome',
    name: 'adminHome',
    redirect: '/overView',
    component: adminHome,
    children: [
      {
        path: '/overView',
        name: 'overView',
        component: overView
      },
      {
        path: '/carousel',
        name: 'carousel',
        component: carousel
      },
      {
        path: '/alkz',
        name: 'alkz',
        component: alkz
      },
      {
        path: '/yjkz',
        name: 'yjkz',
        component: yjkz
      },
      {
        path: '/lxfs',
        name: 'lxfs',
        component: lxfs
      },
      {
        path: '/wysp',
        name: 'wysp',
        component: wysp
      },
      {
        path: '/admin_ssjs',
        name: 'admin_ssjs',
        component: adminSsjs
      },
      {
        path: '/admin_ssry',
        name: 'admin_ssry',
        component: adminSsry
      },
      {
        path: '/admin_czlc',
        name: 'admin_czlc',
        component: adminCzlc
      },
      {
        path: '/admin_qyhx',
        name: 'admin_qyhx',
        component: adminQyhx
      },
      {
        path: '/admin_ssdj',
        name: 'admin_ssdj',
        component: adminSsdj
      },
      {
        path: '/admin_qyxw',
        name: 'admin_qyxw',
        component: adminQyxw
      },
      {
        path: '/admin_xgfg',
        name: 'admin_xgfg',
        component: adminXgfg
      },
      {
        path: '/admin_ynzx',
        name: 'admin_ynzx',
        component: adminYnzx
      },
      {
        path: '/admin_xcp',
        name: 'admin_xcp',
        component: adminXcp
      },
      {
        path: '/admin_ygfc',
        name: 'admin_ygfc',
        component: adminYgfc
      },
      {
        path: '/admin_xmzs',
        name: 'admin_xmzs',
        component: adminXmzs
      },
      {
        path: '/admin_tjwz',
        name: 'admin_tjwz',
        component: adminTjwz
      },
      {
        path: '/admin_rczp',
        name: 'admin_rczp',
        component: adminRczp
      },
      {
        path: '/admin_rcln',
        name: 'admin_rcln',
        component: adminCrLn
      },
      {
        path: '/admin_lxwm',
        name: 'admin_lxwm',
        component: adminLxwm
      },
      {
        path: '/admin_zbly',
        name: 'admin_zbly',
        component: adminZbly
      },
      {
        path: '/admin_sylb',
        name: 'admin_sylb',
        component: adminSylb
      },
      {
        path: '/admin_dgbs',
        name: 'admin_dgbs',
        component: adminDgbs
      }
    ]
  }
]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
