<template>
<!-- 山水荣誉 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="视频名称"
                width="300"
                prop="videoname">
                </el-table-column>
                <el-table-column
                label="视频日期"
                prop="videodate">
                </el-table-column>
                <el-table-column
                label="视频链接地址(点击预览)"
                prop="videourl">
                  <template slot-scope="scope">
                      <el-link @click="previewVideo(scope.row.videourl)" :underline="false" type="primary">{{scope.row.videourl}}</el-link>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                fixed="right"
                align="center"
                prop="honourtype"
                label="类型"
                width="180"
                sortable
                :filters="ssryTypeInfo"
                :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.honourtype === '0'" type="success">山水资质</el-tag>
                    <el-tag v-if="scope.row.honourtype === '1'" type="info">企业荣誉</el-tag>
                    <el-tag v-if="scope.row.honourtype === '2'" type="warning">工程荣誉</el-tag>
                    <el-tag v-if="scope.row.honourtype === '3'" type="danger">科研荣誉</el-tag>
                    <el-tag v-if="scope.row.honourtype === '4'" >山水形象</el-tag>
                </template>
                </el-table-column> -->
            </el-table>
        </div>
        <!-- tianjia lunbo -->
        <el-dialog title="添加员工风采视频" :visible.sync="addDialogVisable" width="600px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="ygfcInfo" ref="addDialogRef" :rules="addDialogRules">
                <el-form-item label="视频描述" label-width="100px" prop="videoname">
                    <el-input type="text" placeholder="请输入视频描述" v-model="ygfcInfo.videoname"></el-input>
                </el-form-item>
                <el-form-item label="视频日期"  label-width="100px" prop="videodate">
                    <el-date-picker
                      v-model="ygfcInfo.videodate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传视频"  label-width="100px" prop="videourl">
                    <el-upload
                      :auto-upload="true"
                      ref="videourl"
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :on-error="handleError"
                      :on-success="uploadSuccess"
                      :file-list="QiniuData.fileList"
                      :before-upload="beforeAvatarUpload">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="修改员工风采" :visible.sync="editDialogVisable"  width="600px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="ygfcInfo" ref="addDialogRef" :rules="addDialogRules">
                <el-form-item label="视频描述" label-width="100px" prop="videoname">
                    <el-input type="text" placeholder="请输入视频描述" v-model="ygfcInfo.videoname"></el-input>
                </el-form-item>
                <el-form-item label="视频日期"  label-width="100px" prop="videodate">
                    <el-date-picker
                      v-model="ygfcInfo.videodate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传视频"  label-width="100px" prop="videourl">
                    <el-upload
                      :auto-upload="true"
                      ref="videourl"
                      :action="QiniuData.domain"
                      :data="QiniuData.data"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :on-error="handleError"
                      :on-success="uploadSuccess"
                      :file-list="QiniuData.fileList"
                      :before-upload="beforeAvatarUpload">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="SubmitEditDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="预览" :visible.sync="priviewVideoVisable" center width="600px" @close="dialogClose">
            <div class="priviewVideo_item">
                  <video-player  class="video-player vjs-custom-skin" id="videoDiv"
                      ref="videoPlayer"
                      :options="playerOptions"
                      :playsinline="true"
                      :webkit-playsinline="true"
                      customEventName="customstatechangedeventname">
                  </video-player>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {
      listCardImg: '',
      showImgsDialogVisible: false,
      ssryTypeInfo: [
        {
          text: ' 山水资质',
          value: '0'
        },
        {
          text: '企业荣誉',
          value: '1'
        },
        {
          text: '工程荣誉 ',
          value: '2'
        },
        {
          text: '科研荣誉',
          value: '3'
        },
        {
          text: '山水形象',
          value: '4'
        }
      ],
      ygfcInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      priviewVideoVisable: false,
      addDialogVisable: false,
      editDialogVisable: false,
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        fileList: [],
        moreFileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      },
      addDialogRules: {
        videoname: [
          { required: true, message: '请输入视频描述', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        videodate: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        videourl: [
          { required: true, message: '请选择视频', trigger: 'change' }
        ]
      },
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  methods: {
    handleError (file) {
      console.log(file)
      this.$message.error('上传错误，请刷新页面重新上传')
    },
    // 预览
    previewVideo (url) {
      this.playerOptions.sources[0].src = url
      this.priviewVideoVisable = true
    },
    getVideoByType (type) {
      this.loading = true
      this.$http.get('/admin/getVideoByType/' + type).then(res => {
        console.log(res)
        this.loading = false
        this.tableData = res.data.data
      })
    },
    // editDialog
    SubmitEditDialog () {
      this.loading = true
      // console.log(this.ssryInfo)
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.$http.post('/admin/updateVideo', this.ygfcInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.editDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
            this.getVideoByType(1)
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    // 提交dialog内容 addDialog
    submitDialog () {
      this.loading = true
      console.log(this.ygfcInfo)
      this.ygfcInfo.videotype = '1'
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.$http.post('/admin/insertVideo', this.ygfcInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.addDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
            this.getVideoByType(1)
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    dialogClose () {
      this.ygfcInfo = {}
      this.QiniuData.fileList = []
      this.loading = false
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    filterTag  (value, row) {
      return row.honourtype === value
    },
    // 修改操作
    editSelection () {
      if (this.multipleSelection.length === 1) {
        this.ygfcInfo = this.multipleSelection[0]
        console.log(this.ssryInfo)
        if (this.ygfcInfo.videourl !== '' && this.ygfcInfo.videourl !== null && this.ygfcInfo.videourl !== undefined) {
          this.QiniuData.fileList.push({
            name: this.ygfcInfo.videourl.replace('https://ssjt.buildone.com.cn/', ''),
            url: this.ygfcInfo.videourl
          })
        }
        this.editDialogVisable = true
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    // 删除单张
    handleRemove (file, fileList) {
      this.ygfcInfo.videourl = ''
      this.QiniuData.fileList = []
    },
    handlePreview (file) {
      console.log(file)
      this.showImgsDialogVisible = true
      this.listCardImg = file.url
    },
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取token数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    beforeAvatarUpload (file) {
      // this.getQiniuToken()
      const isMP4 = file.type === 'video/mp4'
      // 本地测试8M
      // const isLt2M = file.size / 1024 / 1024 < 8
      if (!isMP4) {
        this.$message.error('上传视频只能是 MP4 格式!')
        return false
      }
      // if (!isLt8M) {
      //   this.$message.error('上传头像图片大小不能超过 8MB!')
      //   return false
      // }
      this.QiniuData.data.key = `${file.name}`
    },
    uploadSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.fileList = []
      this.ygfcInfo.videourl = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: this.ygfcInfo.videourl
      })
      this.loading = false
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        // console.log('edit')
        this.loading = true
        this.$http.post('/admin/deleteVideo', this.multipleSelection).then(res => {
          this.loading = false
          this.getVideoByType(1)
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
    this.getQiniuToken()
  },
  created () {
    this.getVideoByType(1)
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
.priviewVideo_item {
    width: 100%;
    // height: 213px;
    overflow: hidden;
}
</style>
