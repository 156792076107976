<template>
    <div class="articleDetail_container">
        <div class="articleDetail_nav_container">
            <el-page-header @back="goBack"></el-page-header>
        </div>
        <div class="articleDetail_content_container">
            <div class="articleDetail_title_container">
                {{articleDetail.articletitle}}
            </div>
            <div class="articleDetail_date_container">
                文章日期：{{articleDetail.articledate}}
            </div>
            <div class="articleDetail_text_container" v-html="articleDetail.articletext">
            </div>
            <div class="articleDetail_next_container">
                <el-button type="text" icon="el-icon-back">上一篇</el-button>
                <el-button type="text">下一篇<i class="el-icon-right el-icon--right"></i></el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      // data
      articleDetail: '',
      activeIndex: ''
    }
  },
  methods: {
    getArticleDetail () {
      const JSONArticleDetail = window.sessionStorage.getItem('articleDetail')
      this.activeIndex = window.sessionStorage.getItem('activeIndex')
      this.articleDetail = JSON.parse(JSONArticleDetail)
    },
    goBack () {
      this.$router.push(this.activeIndex)
    }
  },
  created () {
    // created todo
    this.getArticleDetail()
  }
}
</script>

<style lang="less" scoped>
.articleDetail_container {
    width: 100%;
    min-height: 450px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
    padding: 5px;
}
.articleDetail_nav_container {
    width: 100%;
    font-size: 100% !important;
    padding: 5px 0;
    border-bottom: 1px solid lightgrey;
}
.articleDetail_content_container {
    width: 100%;
    min-height: 400px;
    padding: 10px 0 50px 0;
    position: relative;
}
.articleDetail_title_container {
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 150%;
    letter-spacing: .1rem;
}
.articleDetail_date_container {
    width: 100%;
    text-align: left;
    padding: 2px 20px;
    color: lightgrey;
    font-size: 14px;
}
.articleDetail_text_container {
    width: 100%;
    padding: 5px;
}
.articleDetail_next_container {
    width: 100%;
    height: 50px;
    padding: 0 5px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
