<template>
  <div class="overView_container">
    <div class="daliyRate_container">
      <el-card class="box-card" shadow="always">
        <div class="box_card_title">
          <i class="el-icon-data-line"></i>
          <span>近日流量</span>
        </div>
        <div class="box_card_content">
          <el-table
            :data="daliyRate"
            style="width: 100%">
            <el-table-column
              prop="date"
              label="日期"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="pv"
              label="浏览量(PV)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="uv"
              label="访客数(UV)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="iv"
              label="IP数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="vv"
              align="center"
              label="访问量">
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <!-- <el-card class="box-card dateBtns_container" shadow="always">
      <el-button-group>
        <el-button type="info" plain @click="getChartDataInfo(type = 'week')">最近7天</el-button>
        <el-button type="info" plain @click="getChartDataInfo(type = 'month')">最近30天</el-button>
      </el-button-group>
    </el-card> -->
    <el-card class="box-card chart_container" shadow="always">
      <div  ref="myChart" id="myChart_body" class="myChart_body" :style="{width: '100%', height: '380px'}"></div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      daliyRate: [
        { ref_date: '2020-06-14', visit_pv: 100, visit_uv: 200, stay_time_uv: '50s' }
      ],
      chartDataInfo: {
        xAxisData: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        seriesData: [
          {
            name: '页面被打开的次数(PV)',
            type: 'line',
            stack: '总量',
            color: '#00BFFF',
            data: [120, 232, 101, 90, 230, 210, 200]
          },
          {
            name: '访客数(UV)',
            type: 'line',
            stack: '总量',
            color: '#FF4500',
            data: [5, 10, 30, 40, 35, 38, 45]
          },
          {
            name: '访问量(VV)',
            type: 'line',
            stack: '总量',
            color: '#FFFF00',
            data: [5, 10, 30, 40, 35, 38, 45]
          },
          {
            name: '独立IP数(IV)',
            type: 'line',
            stack: '总量',
            color: '#54FF9F',
            data: [5, 10, 30, 40, 35, 38, 45]
          }
        ]
      },
      // 流量类型
      type: 'week',
      origin_data: [],
      weekdata: [],
      visit_pv: [],
      visit_uv: []
    }
  },
  mounted () {
    this.getDaliyRate()
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      // 基于准备好的dom，初始化echarts实例
      const bardv = this.$refs.myChart
      const myChart = this.$echarts.init(bardv)
      // 绘制图表
      myChart.setOption({
        title: { text: '访问数据统计' },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['页面被打开的次数(PV)', '访客数(UV)', '访问量(VV)', '独立IP数(IV)']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.chartDataInfo.xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: this.chartDataInfo.seriesData
      })
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
    getDaliyRate () {
      // todo
      this.$http.get('/admin/getHistoryData').then(res => {
        console.log(res)
        const originData = JSON.parse(res.data.msg)
        // console.log(originData)
        if (originData.code === 0) {
          const newData = originData.data
          this.chartDataInfo.xAxisData = []
          this.daliyRate = []
          // 获取最近三天的数据
          var curDate = new Date()
          for (let i = 0; i < 3; i++) {
            var preDate = new Date(curDate.getTime() - i * 24 * 60 * 60 * 1000).toJSON().substring(0, 10)
            const date = preDate.replaceAll('-', '')
            if (newData[date] !== null && newData[date] !== undefined && newData[date] !== '') {
              this.daliyRate.push({
                date: preDate,
                pv: newData[date].pv,
                uv: newData[date].uv,
                vv: newData[date].vv,
                iv: newData[date].iv
              })
            }
          }
          // 获取图标数据
          for (const item in newData) {
            this.chartDataInfo.xAxisData.push(item.substring(0, 4) + '-' + item.substring(4, 6) + '-' + item.substring(6))
            // PV 浏览量或点击量
            this.chartDataInfo.seriesData[0].data.push(parseInt(newData[item].pv))
            // UV 访客数
            this.chartDataInfo.seriesData[1].data.push(parseInt(newData[item].uv))
            // VV 所有访客一天内访问量多少次网站
            this.chartDataInfo.seriesData[2].data.push(parseInt(newData[item].vv))
            // iv 独立IP数
            this.chartDataInfo.seriesData[3].data.push(parseInt(newData[item].iv))
            // console.log(item.substring(0, 4) + '-' + item.substring(4, 6) + '-' + item.substring(6))
          }
          this.drawLine()
        } else {
          this.$message.info('数据拉取频繁，请稍后再试')
        }
      }).catch(error => {
        console.log(error)
        this.$message.info('腾讯数据接口关闭')
      })
    },
    getChartDataInfo (data) {
      // todo
    }
  },
  created () {
    // this.getDaliyRate()
  }
}
</script>

<style lang="less" scoped>
.overView_container {
  background-color: #F5F5F5;
  height: auto;
  margin-top: 50px;
  padding: 10px;
}
.daliyRate_container {
  width: auto;
}
.box_card_title {
  height: 20px;
  line-height: 20px;
}
.box_card_title > i {
  font-size: 22px;
}
.box_card_title > span {
  font-size: 16px;
  font-weight: 700;
  margin-left: 5px;
}
.box_card_content {
  margin: 10px 0 0 0;
}
.box-card {
  padding-bottom: 10px;
}
.dateBtns_container {
  margin: 10px 0;
  padding: 0;
}
.chart_container {
  margin: 10px 0;
  height: 500px;
}
.myChart_body {
  width: 100%;
  height: 100%;
}
</style>
