<template>
    <!-- 业内资讯 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="文章标题"
                prop="articletitle">
                </el-table-column>
                <el-table-column
                label="日期"
                sortable
                prop="articledate"
                width="150">
                </el-table-column>
                <!-- <el-table-column
                label="内容"
                prop="articletext"
                width="300">
                </el-table-column> -->
                <el-table-column
                label="封面地址"
                prop="articlefaceimg"
                width="300"
                sortable>
                </el-table-column>
                <el-table-column
                prop="articlecarouselstate"
                width="120"
                align="center"
                label="是否开启轮播">
                    <template slot-scope="scope">
                        <el-switch
                          v-model="scope.row.articlecarouselstate"
                          active-value="1"
                          inactive-value="0"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          disabled>
                        </el-switch>
                    </template>
                </el-table-column>
                   <el-table-column
                label="轮播图片"
                prop="articlecarouselimg">
                </el-table-column>
                <el-table-column
                label="轮播介绍"
                prop="articlecarouseltext">
                </el-table-column>
                <el-table-column
                fixed="right"
                width="100"
                align="center"
                prop="articlecarouseltype"
                label="轮播类型"
                :filters="carouselTypeInfo"
                :filter-method="filterTag"
                filter-placement="bottom-end">
                  <template slot-scope="scope">
                      <el-tag v-if="scope.row.articlecarouseltype === '0'" type="success">首页轮播</el-tag>
                      <el-tag v-if="scope.row.articlecarouseltype === '1'" type="info">首页资讯轮播</el-tag>
                      <el-tag v-if="scope.row.articlecarouseltype === '2'" type="warning">山水党建轮播</el-tag>
                      <el-tag v-if="scope.row.articlecarouseltype === '3'" type="danger">新闻轮播</el-tag>
                      <el-tag v-if="scope.row.articlecarouseltype === '4'" >资讯轮播</el-tag>
                  </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia lunbo -->
        <el-dialog title="添加文章" :visible.sync="addDialogVisable"  width="1200px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="articleInfo" ref="addDialogRef" :rules="DialogRules">
               <el-form-item label="文章标题" label-width="100px" prop="articletitle">
                    <el-input type="text" v-model="articleInfo.articletitle" placeholder="请输入标题" maxlength="30" style="width: 400px;"></el-input>
                </el-form-item>
                 <el-form-item label="文章日期" label-width="100px" prop="articledate">
                    <el-date-picker
                      v-model="articleInfo.articledate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px" prop="articlefaceimg">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    :limit="1"
                    :action="QiniuData.domain"
                    :data="QiniuData.data"
                    :on-preview="handlePreview"
                    :on-remove="handleSingleRemove"
                    :on-success="uploadSingleSuccess"
                    :before-upload="beforeAvatarUpload"
                    :file-list="QiniuData.SingleFileList"
                    :auto-upload="true">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="文章内容" label-width="100px" prop="articletext">
                    <editor-bar  v-model="articleInfo.articletext" :isClear="isClear" @change="change"></editor-bar>
                </el-form-item>
                <el-form-item label="是否轮播" label-width="100px" prop="articlecarouselstate">
                    <el-radio v-model="articleInfo.articlecarouselstate" label="1">是</el-radio>
                    <el-radio v-model="articleInfo.articlecarouselstate" label="0" @change="clearCarousel">否</el-radio>
                </el-form-item>
                <el-form-item v-if="articleInfo.articlecarouselstate === '1'" label="选择轮播图"  label-width="100px" prop="articlecarouselimg">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    :limit="1"
                    :action="QiniuData.domain"
                    :data="QiniuData.data"
                    :on-preview="handlePreview"
                    :on-remove="handleCarouselRemove"
                    :on-success="uploadCarouselSuccess"
                    :before-upload="beforeAvatarUpload"
                    :file-list="QiniuData.fileList"
                    :auto-upload="true">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item v-if="articleInfo.articlecarouselstate === '1'" label="轮播描述"  label-width="100px" prop="articlecarouseltext">
                  <el-input type="textarea" placeholder="请输入轮播描述" :rows="5" v-model="articleInfo.articlecarouseltext" maxlength="200"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="修改文章" :visible.sync="editDialogVisable" width="1200px" :close-on-click-modal="false" @close="dialogClose">
            <el-form :model="articleInfo" ref="addDialogRef" :rules="DialogRules">
               <el-form-item label="文章标题" label-width="100px" prop="articletitle">
                    <el-input type="text" v-model="articleInfo.articletitle" placeholder="请输入标题" maxlength="30" style="width: 400px;"></el-input>
                </el-form-item>
                 <el-form-item label="文章日期" label-width="100px" prop="articledate">
                    <el-date-picker
                      v-model="articleInfo.articledate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="选择封面"  label-width="100px" prop="articlefaceimg">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    :limit="1"
                    :action="QiniuData.domain"
                    :data="QiniuData.data"
                    :on-error="handleError"
                    :on-preview="handlePreview"
                    :on-remove="handleSingleRemove"
                    :on-success="uploadSingleSuccess"
                    :before-upload="beforeAvatarUpload"
                    :file-list="QiniuData.SingleFileList"
                    :auto-upload="true">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="文章内容" label-width="100px" prop="articletext">
                    <editor-bar  v-model="articleInfo.articletext" :isClear="isClear" @change="change"></editor-bar>
                </el-form-item>
                <el-form-item label="是否轮播" label-width="100px" prop="articlecarouselstate">
                    <el-radio v-model="articleInfo.articlecarouselstate" label="1">是</el-radio>
                    <el-radio v-model="articleInfo.articlecarouselstate" label="0" @change="clearCarousel">否</el-radio>
                </el-form-item>
                <el-form-item v-if="articleInfo.articlecarouselstate === '1'" label="选择轮播图"  label-width="100px" prop="articlecarouselimg">
                    <el-upload
                    class="upload-demo"
                    ref="upload"
                    :limit="1"
                    :action="QiniuData.domain"
                    :data="QiniuData.data"
                    :on-error="handleError"
                    :on-preview="handlePreview"
                    :on-remove="handleCarouselRemove"
                    :on-success="uploadCarouselSuccess"
                    :before-upload="beforeAvatarUpload"
                    :file-list="QiniuData.fileList"
                    :auto-upload="true">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                    <el-dialog :visible.sync="showImgsDialogVisible" append-to-body>
                      <img width="100%" :src="listCardImg" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item v-if="articleInfo.articlecarouselstate === '1'" label="轮播描述"  label-width="100px" prop="articlecarouseltext">
                  <el-input type="textarea" placeholder="请输入轮播描述" :rows="5" v-model="articleInfo.articlecarouseltext" maxlength="200"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="SubmitEditDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import EditorBar from './WangEditor/Editor.vue'
export default {
  components: { 'editor-bar': EditorBar },
  data () {
    return {
      isClear: true,
      listCardImg: '',
      achievementSelectionInfo: [],
      showImgsDialogVisible: false,
      // editArticleInfo: {},
      articleInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      QiniuData: {
        domain: 'https://up-z2.qiniup.com',
        SingleFileList: [],
        fileList: [],
        data: {
          key: '', // 图片文字处理
          token: ''
        },
        qiniuaddr: 'https://ssjt.buildone.com.cn/',
        uptokenURL: 'http://120.195.178.158:8081/upload/getQNUploadToken'
      },
      DialogRules: {
        articletitle: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 3, max: 40, message: '长度在 3 到 40 个字符', trigger: 'blur' }
        ],
        articlefaceimg: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ],
        articledate: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ],
        articlecarouselstate: [
          { required: true, message: '请选择是否轮播', trigger: 'change' }
        ],
        articletext: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      },
      // 轮播类型
      carouselTypeInfo: [
        {
          value: '0',
          text: '首页轮播 ',
          label: '首页轮播 '
        },
        {
          value: '1',
          text: '首页资讯轮播 ',
          label: '首页资讯轮播 '
        },
        {
          value: '2',
          text: '山水党建轮播 ',
          label: '山水党建轮播 '
        },
        {
          value: '3',
          text: '新闻轮播 ',
          label: '新闻轮播 '
        },
        {
          value: '4',
          text: '资讯轮播 ',
          label: '资讯轮播 '
        }
      ]
    }
  },
  methods: {
    handleError (file) {
      console.log(file)
      this.$message.error('上传错误，请刷新页面重新上传')
    },
    // 清理轮播信息
    clearCarousel () {
      // this.articleInfo.articlecarouselimg = ''
      // this.articleInfo.articlecarouseltext = ''
    },
    // 提交新增
    submitDialog () {
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.articleInfo.articletype = '3'
          this.articleInfo.articlestate = '1'
          this.articleInfo.articlecarouseltype = '4'
          this.$http.post('/admin/addArticle', this.articleInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.getSsdj()
              this.addDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    // 模态框关闭
    dialogClose () {
      this.QiniuData.SingleFileList = []
      this.QiniuData.fileList = []
      this.articleInfo = {}
    },
    // 提交修改
    SubmitEditDialog () {
      this.$refs.addDialogRef.validate((valid) => {
        if (!valid) {
          this.loading = false
          return false
        } else {
          this.$http.post('/admin/editArticle', this.articleInfo).then(res => {
            // console.log(res)
            this.loading = false
            if (res.data.code === 200) {
              this.getSsdj()
              this.editDialogVisable = false
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.addDialogRef.resetFields()
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
          }).catch(error => {
            console.log(error)
            this.$notify.warn({
              title: '警告',
              message: '操作失败'
            })
          })
        }
      })
    },
    // edit change
    change (val) {
      console.log(val)
    },
    // 获取党建文章
    getSsdj () {
      this.loading = true
      // 山水党建type：1
      this.$http.get('/admin/getArticle/3').then(res => {
        // console.log(res)
        this.loading = false
        this.tableData = res.data.data
      })
    },
    // 修改操作
    editSelection () {
      if (this.multipleSelection.length === 1) {
        this.articleInfo = this.multipleSelection[0]
        if (this.articleInfo.articlefaceimg !== '' && this.articleInfo.articlefaceimg !== null && this.articleInfo.articlefaceimg !== undefined) {
          this.QiniuData.SingleFileList.push({
            name: this.articleInfo.articlefaceimg.replace('https://ssjt.buildone.com.cn/', ''),
            url: this.articleInfo.articlefaceimg
          })
        }
        if (this.articleInfo.articlecarouselimg !== '' && this.articleInfo.articlecarouselimg !== null && this.articleInfo.articlecarouselimg !== undefined) {
          this.QiniuData.fileList.push({
            name: this.articleInfo.articlecarouselimg.replace('https://ssjt.buildone.com.cn/', ''),
            url: this.articleInfo.articlecarouselimg
          })
        }
        console.log(this.articleInfo)
        this.editDialogVisable = true
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    filterTag  (value, row) {
      return row.achievementtype === value
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        this.loading = true
        this.$http.post('/admin/deleteArticle', this.multipleSelection).then(res => {
          this.loading = false
          this.getSsdj()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    },
    // 图片部分
    handleSingleRemove (file, fileList) {
      this.articleInfo.articlefaceimg = ''
      this.QiniuData.SingleFileList = []
    },
    handlePreview (file) {
      console.log(file)
      this.showImgsDialogVisible = true
      this.listCardImg = file.url
    },
    getQiniuToken () {
      this.$http.get(this.QiniuData.uptokenURL).then(res => {
        if (res.data.code === 200) {
          this.QiniuData.data.token = res.data.token
        } else {
          this.$message.error('拉取token数据失败，请联系管理员')
        }
      }).catch(error => {
        console.log(error)
        this.$notify.info({
          title: '警告',
          message: '获取token失败'
        })
      })
    },
    beforeAvatarUpload (file) {
      const isPNG = file.type === 'image/png'
      const isJPEG = file.type === 'image/jpeg'
      const isJPG = file.type === 'image/jpg'
      const isGIF = file.type === 'image/gif'
      // 本地测试8M
      // const isLt8M = file.size / 1024 / 1024 < 8
      if (!isPNG && !isJPEG && !isJPG && !isGIF) {
        this.$message.error('上传头像图片只能是 jpg、png、jpeg、gif 格式!')
        return false
      }
      // if (!isLt8M) {
      //   this.$message.error('上传头像图片大小不能超过 8MB!')
      //   return false
      // }
      this.QiniuData.data.key = `${file.name}-sf`
    },
    uploadSingleSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.SingleFileList = []
      this.articleInfo.articlefaceimg = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.SingleFileList.push({
        name: this.QiniuData.data.key,
        url: `${this.QiniuData.qiniuaddr}${response.key}`
      })
      this.loading = false
    },
    // 轮播图
    handleCarouselRemove (file, fileList) {
      this.articleInfo.articlecarouselimg = ''
      this.QiniuData.fileList = []
    },
    uploadCarouselSuccess (response, file, fileList) {
      // console.log(fileList)
      this.QiniuData.fileList = []
      this.articleInfo.articlecarouselimg = `${this.QiniuData.qiniuaddr}${response.key}`
      this.QiniuData.fileList.push({
        name: this.QiniuData.data.key,
        url: `${this.QiniuData.qiniuaddr}${response.key}`
      })
      this.loading = false
    }
  },
  mounted () {
    this.getSsdj()
    this.getQiniuToken()
  },
  created () {
    // this.getSsdj()
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
</style>
