<template>
    <div class="gyss_container">
        <div class="tabs_btns_container">
            <div class="gyss_btn" v-for="item in yjzsInfo" :key="item.typeId" @click="changeType(item.typeId)">
                {{item.typeName}}
            </div>
        </div>
        <div class="tabs_content_container">
            <div :class="tabTypeId == 1 ? 'tabs_content_item animate__animated animate__slideInLeft' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="xcp_container">
                    <div class="xcp_title_container">
                        企业宣传片
                    </div>
                    <div class="xcp_subTitle_container">
                        <div class="hr_line_left"></div>
                        <div class="xcp_center_container">
                            源于自然 高于自然
                        </div>
                        <div class="hr_line_right"></div>
                    </div>
                    <div class="xcp_video_container">
                        <!-- <div class="xcp_btn_left_container">
                          <i class="el-icon-d-arrow-left"></i>
                        </div> -->
                        <div class="xcp_video_content">
                                <video-player  class="video-player vjs-custom-skin"
                                  ref="videoPlayer"
                                  :playsinline="true"
                                  poster
                                  webkit-playsinline="true"
                                  x-webkit-airplay="true"
                                  :options="playerOptions">
                                </video-player>
                        </div>
                        <!-- <div class="xcp_btn_right_container">
                          <i class="el-icon-d-arrow-right"></i>
                        </div> -->
                    </div>
                </div>
            </div>
            <div :class="tabTypeId == 2 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="ygfc_caontainer" v-if="PlayerOptionsList.length > 0">
                    <div class="ygfc_video_container">
                        <!-- PC端 -->
                        <div class="ygfc_item_container pc_video" v-for="item in PlayerOptionsList" :key="item.videoname">
                            <div class="ygfc_item_video__container">
                                <video-player  class="video-player vjs-custom-skin"
                                  ref="videoPlayer"
                                  :playsinline="true"
                                  poster
                                  webkit-playsinline="true"
                                  x-webkit-airplay="true"
                                  :options="item">
                                </video-player>
                            </div>
                            <div class="ygfc_item_words__container" @click="priewVideo(item)">
                                <el-link type="primary" :underline="false">{{item.videoname}}</el-link>
                            </div>
                        </div>
                        <!-- 手机端 -->
                        <div class="ygfc_item_container mb_video" v-for="item in MBPlayerOptionsList" :key="item.videoname">
                            <div class="ygfc_item_video__container">
                                <video-player  class="video-player vjs-custom-skin"
                                  ref="videoPlayer"
                                  :playsinline="true"
                                  poster
                                  webkit-playsinline="true"
                                  x-webkit-airplay="true"
                                  :options="item">
                                </video-player>
                            </div>
                            <div class="ygfc_item_words__container">
                                <el-link type="primary" :underline="false">{{item.videoname}}</el-link>
                            </div>
                        </div>
                    </div>
                    <div class="ygfc_page_container">
                        <el-pagination
                          layout="prev, pager, next"
                            @current-change="videoPageChanged"
                            :current-page="videoPageInfo.currentPage"
                          :total="videoPageInfo.totals"
                          :page-size="videoPageInfo.pageSize">
                        </el-pagination>
                    </div>
                </div>
                <div class="ygfc_caontainer" v-else>
                    暂无数据
                </div>
            </div>
            <div :class="tabTypeId == 3 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="ygfc_caontainer" v-if="PlayerOptionsList.length > 0">
                    <div class="ygfc_video_container">
                        <!-- PC端 -->
                        <div class="ygfc_item_container pc_video" v-for="item in PlayerOptionsList" :key="item.videoname">
                            <div class="ygfc_item_video__container">
                                <video-player  class="video-player vjs-custom-skin"
                                  ref="videoPlayer"
                                  :playsinline="true"
                                  poster
                                  webkit-playsinline="true"
                                  x-webkit-airplay="true"
                                  :options="item">
                                </video-player>
                            </div>
                            <div class="ygfc_item_words__container" @click="priewVideo(item)">
                                <el-link type="primary" :underline="false">{{item.videoname}}</el-link>
                            </div>
                        </div>
                        <!-- 手机端 -->
                        <div class="ygfc_item_container mb_video" v-for="item in MBPlayerOptionsList" :key="item.videoname">
                            <div class="ygfc_item_video__container">
                                <video-player  class="video-player vjs-custom-skin"
                                  ref="videoPlayer"
                                  :playsinline="true"
                                  poster
                                  webkit-playsinline="true"
                                  x-webkit-airplay="true"
                                  :options="item">
                                </video-player>
                            </div>
                            <div class="ygfc_item_words__container">
                                <el-link type="primary" :underline="false">{{item.videoname}}</el-link>
                            </div>
                        </div>
                    </div>
                    <div class="ygfc_page_container">
                        <el-pagination
                          layout="prev, pager, next"
                          @current-change="videoPageChanged"
                          :current-page="videoPageInfo.currentPage"
                          :total="videoPageInfo.totals"
                          :page-size="videoPageInfo.pageSize">
                        </el-pagination>
                    </div>
                </div>
                 <div class="ygfc_caontainer" v-else>
                    暂无数据
                </div>
            </div>
            <div :class="tabTypeId == 4 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <div class="ssdj_articleList_container" v-if="tjwzInfo.length > 0">
                      <div class="ssdj_articleItem_container" v-for="item in tjwzInfo" :key="item.articleid">
                        <div class="ssdj_article_img_container">
                            <el-image :src="item.articlefaceimg" fit="cover">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline" style="font-size: 40px;"></i>
                              </div>
                            </el-image>
                        </div>
                        <div class="ssdj_article_text_container">
                            <div class="ssdj_article_title_container">
                                {{item.articletitle}}
                            </div>
                            <div class="ssdj_article_date_container">
                                日期：{{item.articledate}}
                                <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                            </div>
                            <div class="ssdj_article_words_container" v-html="item.articletext"></div>
                        </div>
                      </div>
                      <!-- 分页 -->
                      <div class="ssdj_articlePage_container">
                          <el-pagination
                            layout="prev, pager, next"
                             @current-change="pageChanged"
                             :current-page="pageInfo.currentPage"
                            :total="pageInfo.totals"
                            :page-size="pageInfo.pageSize">
                          </el-pagination>
                      </div>
                </div>
                <div class="ssdj_articleList_container" v-else>
                    暂无数据
                </div>
            </div>
            <div :class="tabTypeId == 5 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item_hide animate__animated animate__slideOutLeft'">
                <iframe src="https://www.iznhc.com/index/ebook/book?ids=4052" width="100%" height="600px" border="0"></iframe>
            </div>
        </div>
        <!-- video yulan  -->
        <el-dialog :visible.sync="videoDialogVisiable" class="videoDialog" width="700px" @close="dialogClose">
            <!-- <video ref="videoPlay" width="100%" controls="" autoplay="" name="media">
                <source :src="previewVieo" type="video/mp4">
                您的浏览器不支持 video 标签。
            </video> -->
            <video-player  class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              poster
              webkit-playsinline="true"
              x-webkit-airplay="true"
              :options="previewVieoOptions">
            </video-player>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {
      previewVieo: '',
      previewVieoOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        // poster: 'https://ssjt.buildone.com.cn/v9m2r8.png',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      videoDialogVisiable: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        // poster: 'https://ssjt.buildone.com.cn/v9m2r8.png',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      tabTypeId: 1,
      activeCzlcId: '',
      yjzsInfo: [
        {
          typeName: '宣传片',
          typeId: 1
        },
        {
          typeName: '员工风采',
          typeId: 2
        },
        {
          typeName: '项目展示',
          typeId: 3
        },
        // {
        //   typeName: '推荐文章',
        //   typeId: 4
        // },
        {
          typeName: '企业宣传册',
          typeId: 5
        }
      ],
      videoPageInfo: {
        currentPage: 1,
        totals: 0,
        pageSize: 12
      },
      pageInfo: {
        currentPage: 1,
        totals: 0,
        pageSize: 3
      },
      // 员工风采
      videoList: [],
      // 手机端
      MBPlayerOptionsList: [],
      PlayerOptionsList: [],
      tjwzInfo: [],
      videoType: 1
    }
  },
  methods: {
    dialogClose () {
      // this.previewVieo = []
    },
    priewVideo (url) {
      console.log(url)
      this.previewVieoOptions.sources[0].src = url.sources[0].src
      this.videoDialogVisiable = true
    },
    showArticleDeltail (data) {
      // console.log(data)
      window.sessionStorage.setItem('articleDetail', JSON.stringify(data))
      window.sessionStorage.setItem('activeIndex', '/sswh')
      this.$router.push('articleDetail')
    },
    getTjwzByPage () {
      // console.log(this.pageInfo)
      this.$http.post('/admin/getArticleByType/5', this.pageInfo).then(res => {
        console.log(res)
        this.tjwzInfo = res.data.articles
        // console.log(res.data.articles[0].articleid)
        this.pageInfo.totals = res.data.totals
      })
    },
    // 获取宣传片
    getXuanChuanPian () {
      this.$http.get('/admin/getVideoByType/3').then(res => {
        if (res.data.data.length > 0) {
          this.playerOptions.sources[0].src = res.data.data[0].videourl
        }
      })
    },
    // 获取员工风采 1 项目展示 2
    getVideoByType (type) {
      this.$http.post('/admin/getVideoByTypeLimit/' + type, this.videoPageInfo).then(res => {
        // console.log(res)
        const videoList = res.data.data
        this.videoPageInfo.totals = res.data.totals
        // this.videoList = res.data.data
        this.PlayerOptionsList = []
        this.MBPlayerOptionsList = []
        for (let index = 0; index < videoList.length; index++) {
          const options = {
            videoname: videoList[index].videoname,
            playbackRates: false,
            autoplay: false,
            muted: false,
            loop: true,
            preload: 'auto',
            language: 'zh-CN',
            aspectRatio: '16:9',
            fluid: true,
            sources: [{
              type: 'video/mp4',
              src: videoList[index].videourl
            }],
            poster: '',
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            controlBar: false
          }
          const MBoptions = {
            videoname: videoList[index].videoname,
            playbackRates: false,
            autoplay: false,
            muted: false,
            loop: true,
            preload: 'auto',
            language: 'zh-CN',
            aspectRatio: '16:9',
            fluid: true,
            sources: [{
              type: 'video/mp4',
              src: videoList[index].videourl
            }],
            poster: '',
            notSupportedMessage: '此视频暂无法播放，请稍后再试',
            controlBar: {
              timeDivider: false, // 当前时间和持续时间的分隔符
              durationDisplay: false, // 显示持续时间
              remainingTimeDisplay: false, // 是否显示剩余时间功能
              fullscreenToggle: true // 是否显示全屏按钮
            }
          }
          this.MBPlayerOptionsList.push(MBoptions)
          this.PlayerOptionsList.push(options)
        }
        // console.log(this.PlayerOptionsList)
      })
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    changeType (typeId) {
      // console.log(typeId)
      this.tabTypeId = typeId
      this.showData = this.shejiInfo
      this.videoPageInfo = {
        currentPage: 1,
        totals: 0,
        pageSize: 12
      }
      if (typeId === 1) {
        this.getXuanChuanPian()
      } else if (typeId === 2) {
        this.videoType = 1
        this.getVideoByType(1)
      } else if (typeId === 3) {
        this.videoType = 2
        this.getVideoByType(2)
      } else if (typeId === 4) {
        this.getTjwzByPage()
      }
    },
    showDetails (data) {
      console.log(data)
    },
    pageChanged (currentPage) {
      this.pageInfo.currentPage = currentPage
      this.getTjwzByPage()
    },
    videoPageChanged (currentPage) {
      console.log(currentPage)
      this.videoPageInfo.currentPage = currentPage
      this.getVideoByType(this.videoType)
    }
  },
  created () {
    this.showData = this.shejiInfo
    this.changeType(1)
  }
}
</script>

<style lang="less" scoped>
// 新的
.gyss_container {
    width: 100%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
}
.tabs_btns_container {
    width: 100%;
    //  background-color: red;
    padding: 10px 5px;
    font-size: 100%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
}
.gyss_btn {
    padding: 10px 20px;
    background-color: transparent;
    text-align: center;
    border-radius: 5px;
    color: #808080;
    cursor: pointer;
}
.gyss_btn:hover{
    background-color: #F5F5F5;
    color: #000;
}
.gyss_btn_click{
    background-color: #F5F5F5;
    color: #000;
}
.tabs_content_container {
    width: 100%;
    min-height: 200px;
    padding: 5px;
    overflow: hidden;
}
.tabs_content_item {
    width: 100%;
    min-height: 200px;
    overflow: hidden;
}
.tabs_content_item_hide {
    width: 100%;
    height: 0;
    overflow: hidden;
}
@media screen and (max-width: 500px){
  .tabs_btns_container {
      width: 100%;
      //  background-color: red;
      padding: 5px;
      font-size: 10px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
  }
  .gyss_btn {
      padding: 10px 10px;
      background-color: #F5F5F5;
      text-align: center;
      border-radius: 5px;
      color: #808080;
      cursor: pointer;
  }
  .gyss_btn:hover{
      background-color: #F5F5F5;
      color: #000;
  }
  .gyss_btn_click{
      background-color: #F5F5F5;
      color: #000;
  }
  .tabs_content_container {
      width: 100%;
      min-height: 200px;
      padding: 5px;
      overflow: hidden;
  }
  .tabs_content_item {
      width: 100%;
      min-height: 200px;
  }
}
.xcp_container {
  width: 100%;
  min-height: 450px;
  // height: 600px;
  // background-color: pink;
}
.xcp_title_container {
  width: 100%;
  padding: 10px 0;
  // background-color: red;
  font-size: 200%;
  font-weight: 200;
  text-align: center;
  letter-spacing: .1rem;
}
.xcp_subTitle_container {
  width: 100%;
  height: 100px;
  // background-color: yellow;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hr_line_left {
  width: 300px;
  height: 1px;
  background-color: black;
  position: absolute;
  left: 150px;
}
.hr_line_right {
  width: 300px;
  height: 1px;
  background-color: black;
  position: absolute;
  right: 150px;
}
.xcp_center_container {
  width: 300px;
  height: 80px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
  font-weight: 200;
}
.xcp_video_container {
  width: 100%;
  // height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.xcp_video_content {
  width:  80%;
  height: 100%;
}
.xcp_btn_left_container {
  width: 10%;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
  cursor: pointer;
}
.xcp_btn_right_container {
  width: 10%;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200%;
  cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 1200px){
  .hr_line_left {
    width: 300px;
    height: 1px;
    background-color: black;
    position: absolute;
    left: 80px;
  }
  .hr_line_right {
    width: 300px;
    height: 1px;
    background-color: black;
    position: absolute;
    right: 80px;
  }
  .xcp_video_content {
    width: 800px;
    height: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px){
  .hr_line_left {
    width: 250px;
    height: 1px;
    background-color: black;
    position: absolute;
    left: 10px;
  }
  .hr_line_right {
    width: 250px;
    height: 1px;
    background-color: black;
    position: absolute;
    right: 10px;
  }
  // .xcp_video_content {
  //   width: 600px;
  //   height: 100%;
  // }
}
@media screen and (max-width: 500px){
  .xcp_title_container {
    width: 100%;
    padding: 5px 0;
    // background-color: red;
    font-size: 150%;
    font-weight: 200;
    text-align: center;
    letter-spacing: .1rem;
  }
  .xcp_center_container {
    width: 180px;
    height: 50px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 120%;
    font-weight: 200;
  }
  .xcp_subTitle_container {
    width: 100%;
    height: 70px;
    // background-color: yellow;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hr_line_left {
    width: 100px;
    height: 1px;
    background-color: black;
    position: absolute;
    left: 5px;
  }
  .hr_line_right {
    width: 100px;
    height: 1px;
    background-color: black;
    position: absolute;
    right: 5px;
  }
}
  // 员工风采
  .ygfc_caontainer {
    width: 100%;
    // height: 800px;
  }
  .ygfc_video_container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    // height: 750px;
  }
  .ygfc_page_container {
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ygfc_item_container {
    width: 30%;
    // height:220px;
    cursor: pointer;
    float: left;
    padding: 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px){
    .ygfc_item_container {
      width: 33%;
      float: left;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 768px){
    .ygfc_item_container {
      width: 50%;
      float: left;
    }
  }
  @media screen and (max-width: 500px){
    .ygfc_item_container {
      width: 100%;
      float: left;
      padding: 5px;
    }
  }
  .ygfc_item_words__container {
    width: 100%;
    height: 24px;
    line-height: 20px;
    padding: 5px;
    font-size: 15px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    background-color: #fff;
    overflow: hidden;
  }
  // 列表
  .ssdj_articleList_container {
    width: 100%;
    min-height: 500px;
    // background-color: yellow;
    padding: 20px 0;
  }
  .ssdj_articleItem_container {
    width: 90%;
    height: 300px;
    // background-color: red;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    box-shadow: 1px 1px 5px 1px lightgray;
  }
  .ssdj_article_img_container {
    width: 40%;
    height: 100%;
  }
  .ssdj_article_img_container .el-image{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ssdj_article_text_container {
    width: 60%;
    height: 100%;
    padding: 5px 10px;
  }
  .ssdj_articlePage_container {
    width: 100%;
    height: 50px;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ssdj_article_title_container {
    width: 100%;
    font-size: 24px;
    height: 34px;
    padding: 5px 0;
    text-align: center;
    overflow : hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .ssdj_article_date_container {
    width: 100%;
    font-size: 14px;
    text-align: left;
    color: #C9C9C9;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ssdj_article_words_container {
    width: 100%;
    height: 216px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
  }
@media screen and (max-width: 500px){
  .ssdj_articleList_container {
    width: 100%;
    min-height: 500px;
    // background-color: yellow;
    padding: 10px 0;
  }
  .ssdj_articleItem_container {
    width: 100%;
    height: 170px;
    // background-color: red;
    margin: 30px auto;
    display: flex;
    // border: 1px solid lightgray;
    box-shadow: 1px 1px 5px 1px lightgray;
  }
  .ssdj_article_img_container {
    width: 40%;
    height: 100%;
  }
  .ssdj_article_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .ssdj_article_text_container {
    width: 60%;
    height: 100%;
    padding: 5px;
  }
  .ssdj_articlePage_container {
    // width: 90%;
    height: 50px;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ssdj_article_title_container {
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    height: 28px;
    padding: 2px 0;
    text-align: left;
    overflow : hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .ssdj_article_date_container {
    width: 100%;
    font-size: 10px;
    text-align: left;
    height: 30px;
    color: #C9C9C9;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ssdj_article_words_container {
    width: 100%;
    height: 100px;
    font-size: 14px;
    letter-spacing: 0.1rem;
    line-height: 20px;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.videoDialog {
  display: block;
}
.mb_video {
  display: none;
}
@media screen and (max-width: 500px){
    .videoDialog {
        display: none;
    }
    .pc_video {
        display: none;
    }
    .mb_video {
        display: block;
    }
}
</style>
