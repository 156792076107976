<template>
   <el-container>
      <el-aside :width="isCollapse?'64px':'200px'">
          <div class="adminLogin">
            <div class="adminLogin_container"></div>
          </div>
          <el-menu
            router
            :collapse-transition="false"
            :collapse="isCollapse"
            :default-active="adActiveIndex"
            class="el-menu-vertical-demo"
            unique-opened
            text-color="#fff"
            background-color="#252526"
            active-text-color="#ffd04b"
            @open="handleOpen"
            @close="handleClose">
            <el-menu-item index="/overView"  @click="clickIndex('/overView')">
              <i class="el-icon-menu"></i>
              <span slot="title">概况</span>
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <i class="el-icon-s-platform"></i>
                <span>首页控制</span>
              </template>
              <!-- <el-menu-item index="/carousel" @click="clickIndex('/carousel')">轮播控制</el-menu-item> -->
              <el-menu-item index="/admin_sylb" @click="clickIndex('/admin_sylb')">首页轮播</el-menu-item>
              <el-menu-item index="/alkz" @click="clickIndex('/alkz')">案例控制</el-menu-item>
              <el-menu-item index="/lxfs" @click="clickIndex('/lxfs')">联系方式</el-menu-item>
              <el-menu-item index="/wysp" @click="clickIndex('/wysp')">尾页视频</el-menu-item>
            </el-submenu>
            <el-menu-item index="/yjkz" @click="clickIndex('/yjkz')">
              <i class="el-icon-s-data"></i>
              <span slot="title">业绩展示</span>
            </el-menu-item>
            <el-submenu index="3" v-show="showManager">
              <template slot="title">
                <i class="el-icon-office-building"></i>
                <span>关于山水</span>
              </template>
              <el-menu-item index="/admin_ssjs" @click="clickIndex('/admin_ssjs')">山水介绍</el-menu-item>
              <el-menu-item index="/admin_ssry" @click="clickIndex('/admin_ssry')">山水荣誉</el-menu-item>
              <el-menu-item index="/admin_czlc" @click="clickIndex('/admin_czlc')">成长历程</el-menu-item>
              <el-menu-item index="/admin_qyhx" @click="clickIndex('/admin_qyhx')">核心板块</el-menu-item>
              <el-menu-item index="/admin_ssdj" @click="clickIndex('/admin_ssdj')">山水党建</el-menu-item>
            </el-submenu>
            <el-submenu index="4" v-show="showManager">
              <template slot="title">
                <i class="el-icon-document"></i>
                <span>新闻中心</span>
              </template>
              <el-menu-item index="/admin_qyxw" @click="clickIndex('/admin_qyxw')">企业新闻</el-menu-item>
              <el-menu-item index="/admin_ynzx" @click="clickIndex('/admin_ynzx')">业内资讯</el-menu-item>
              <el-menu-item index="/admin_xgfg" @click="clickIndex('/admin_xgfg')">相关法规</el-menu-item>
            </el-submenu>
            <el-submenu index="5" v-show="showManager">
              <template slot="title">
                <i class="el-icon-s-flag"></i>
                <span>山水文化</span>
              </template>
              <el-menu-item index="/admin_xcp" @click="clickIndex('/admin_xcp')">宣传片</el-menu-item>
              <el-menu-item index="/admin_ygfc" @click="clickIndex('/admin_ygfc')">员工风采</el-menu-item>
              <el-menu-item index="/admin_xmzs" @click="clickIndex('/admin_xmzs')">项目展示</el-menu-item>
              <el-menu-item index="/admin_tjwz" @click="clickIndex('/admin_tjwz')">推荐文章</el-menu-item>
            </el-submenu>
            <el-submenu index="6" v-show="showManager">
              <template slot="title">
                <i class="el-icon-s-custom"></i>
                <span>招贤纳士</span>
              </template>
              <el-menu-item index="/admin_rczp" @click="clickIndex('/admin_rczp')">人才招聘</el-menu-item>
              <el-menu-item index="/admin_rcln" @click="clickIndex('/admin_rcln')">人才理念</el-menu-item>
            </el-submenu>
            <el-submenu index="7" v-show="showManager">
              <template slot="title">
                <i class="el-icon-phone-outline"></i>
                <span>联系山水</span>
              </template>
              <!-- <el-menu-item index="/admin_rczp" @click="clickIndex('/admin_lxwm')">联系我们</el-menu-item> -->
              <el-menu-item index="/admin_zbly" @click="clickIndex('/admin_zbly')">周边旅游</el-menu-item>
            </el-submenu>
            <el-submenu index="8" v-show="showManager">
              <template slot="title">
                <i class="el-icon-phone-outline"></i>
                <span>党工办事服务</span>
              </template><el-menu-item index="/admin_dgbs" @click="clickIndex('/admin_dgbs')">党工办事</el-menu-item>
            </el-submenu>
          </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="navMenuBtn_container" @click="changeAdminLogo">
            <i :class="navMenuBtn.current"></i>
          </div>
          <!-- <div class="breadcrumb_container">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
              <el-breadcrumb-item>活动列表</el-breadcrumb-item>
              <el-breadcrumb-item>活动详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div> -->
          <div class="navSysBtn_container">
              <div class="msg_container">
                <i class="el-icon-message-solid" style="font-size: 18px;" @click="openMsgBox"></i>
                <el-badge :value="msgInfo.msgNumb" class="item" type="primary"></el-badge>
              </div>
              <div class="avatar_container">
                <el-avatar size="medium" @error="errorHandler" :src="userInfo.avatorUrl" fit="cover"></el-avatar>
                <span>{{ userInfo.userName }}</span>
              </div>
              <div class="exit_container" @click="exitLogin">
                <el-tooltip class="item" effect="dark" content="退出" placement="top">
                  <i class="el-icon-moon"></i>
                </el-tooltip>
              </div>
          </div>
        </el-header>
        <el-main id="nproress_container">
          <div class="tabs_container">
              提示：建议正文字号 18px 行高 2
          </div>
          <router-view></router-view>
        </el-main>
      </el-container>
      <el-dialog class="msgbox_dialog_container" title="留言盒子" :visible.sync="DialogVisable"  width="800px" :close-on-click-modal="false" @close="dialogClose">
        <div class="msgbox_container">
          <div class="msgbox_aside_container">
              <div class="msgbox_btn_item">
                  <el-link :underline="false" :type="msgState? 'primary' : 'info'" @click="getMsg(0)">未读留言</el-link>
              </div>
              <div class="msgbox_btn_item">
                  <el-link :underline="false" :type="msgState? 'info' : 'primary'" @click="getMsg(1)">历史留言</el-link>
              </div>
          </div>
          <div class="msgbox_main_container">
              <el-table
              :data="msgInfo.msgList"
              border
              tooltip-effect="dark"
              show-overflow-tooltip
              fit
              height="500"
              style="width: 100%">
              <el-table-column
                prop="msgname"
                label="姓名"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="msgtel"
                label="联系方式"
                width="150"
              >
              </el-table-column>
              <el-table-column
                prop="msgtext"
                label="留言"
              >
              </el-table-column>
              <el-table-column
                width="80"
                fixed="right"
                label="操作">
                  <template v-if="msgState" slot-scope="scope">
                      <el-button type="primary" size="mini" @click="read(scope.row)">已读</el-button>
                  </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-dialog>
    </el-container>
</template>

<script>
export default {
  data () {
    return {
      DialogVisable: false,
      beforeUnloadTime: '',
      gapTime: '',
      adActiveIndex: '/overView',
      navMenuBtn: {
        open: 'el-icon-s-fold',
        close: 'el-icon-s-unfold',
        current: 'el-icon-s-fold'
      },
      adminLogo: '../assets/img/sslogo.png',
      isCollapse: false,
      breadcrub: [
        { index: '/', name: '/' }
      ],
      showManager: true,
      msgInfo: {
        msgNumb: 0,
        msgList: []
      },
      msgState: true,
      userInfo: {
        userName: '管理员',
        avatorUrl: 'https://ssjt.buildone.com.cn/avator.jpg'
      }
    }
  },
  methods: {
    read (data) {
      data.msgstate = '1'
      this.$http.post('/admin/editMsg', data).then(res => {
        // console.log(res)
        this.getMsg(0)
      })
    },
    openMsgBox () {
      this.DialogVisable = true
    },
    dialogClose () {
      console.log('close')
    },
    // 获取留言
    getMsg (state) {
      this.$http.get('/admin/getMsgByState/' + state).then(res => {
        // console.log(res)
        if (state === 0) {
          this.msgInfo.msgNumb = res.data.data.length
          this.msgState = true
        } else {
          this.msgState = false
        }
        this.msgInfo.msgList = res.data.data
      })
    },
    changeAdminLogo () {
      this.isCollapse = !this.isCollapse
      if (this.navMenuBtn.current === this.navMenuBtn.open) {
        this.navMenuBtn.current = this.navMenuBtn.close
      } else {
        this.navMenuBtn.current = this.navMenuBtn.open
      }
    },
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    errorHandler () {
      return true
    },
    open (index) {
      // console.log(index)
    },
    // 注销
    exitLogin () {
      window.localStorage.removeItem('isLogin')
      this.$router.push('/adminLogin')
    },
    clickIndex (index) {
      console.log(index)
      window.sessionStorage.setItem('adActiveIndex', index)
    }
  },
  created () {
    const userInfo = this.adActiveIndex = window.sessionStorage.getItem('userInfo')
    this.userInfo.userName = JSON.parse(userInfo).username
    this.adActiveIndex = window.sessionStorage.getItem('adActiveIndex')
    this.getMsg(0)
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
  .el-container {
    width: 100%;
    height: 100vh;
    background-color: #F8F8FF;
  }
  .el-container > .el-aside {
    height: 100%;
    background-color: #3C3C3C;
    overflow: hidden;
  }
  .el-container > .el-aside > .el-menu{
    border-right: none;
  }
  .el-container > .el-container {
    width: 100%;
    background-color: #F4F9F2;
  }
  .el-container > .el-container > .el-main{
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #F5F5F5;
  }
  .el-header {
    line-height: 80px;
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 0;
    position: relative;
  }
  // 侧边栏logo
  .adminLogin {
    height: 60px;
    width: 100%;
    background-color: #000;
  }
  .adminLogin_container {
    height: 60px;
    width: 100%;
    line-height: 80px;
    background-image: url('../assets/img/sslogo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    text-align: center;
  }
  .navMenuBtn_container {
    width: 60px;
    height: 60px;
    font-size: 25px;
    padding: 0 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #252526;
    position: absolute;
  }
  .navSysBtn_container {
    width: 300px;
    // background-color: red;
    height: 100%;
    display: flex;
    position: absolute;
    right: 0;
    align-items: center;
    justify-content: space-between;
  }
  .avatar_container {
    display: flex;
    align-items: center;
  }
  .msg_container {
    // display: flex;
    // align-items: center;
    // height: 100%;
  }
  .msg_container i{
    cursor: pointer;
  }
  .exit_container {
    width: 60px;
    height: 60px;
    background-color: #252526;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
  }
  .exit_container :hover{
   color: gray;
  }
  .breadcrumb_container {
    position: absolute;
    left: 100px;
    width: auto;
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
  }
  .el-breadcrumb {
    font-size: 14px;
    line-height: 1;
    font-weight: 200;
  }
  .tabs_container {
    position: absolute;
    width: 100%;
    top: 0;
    height: 40px;
    line-height: 40px;
    padding: 0px 20px;
    border-bottom: 1px solid lightgray;
    background-color: #fff;
    font-size: 14px;
  }
  // 留言盒子
  .msgbox_container {
    width: 100%;
    height: 500px;
    display: flex;
  }
  .msgbox_aside_container {
    width: 100px;
    height: 100%;
    padding-top: 15px;
  }
  .msgbox_main_container {
    width: 700px;
    height: 100%;
    padding-left: 5px;
  }
  .msgbox_btn_item {
    width: 100%;
    height: 40px;
    text-align: center;
  }
</style>
