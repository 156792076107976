<template>
    <div class="gyss_container">
        <div class="tabs_btns_container">
            <div class="gyss_btn" v-for="item in gyssInfo" :key="item.typeId" @click="changeTab(item.typeId)">
                {{item.typeName}}
            </div>
        </div>
        <div class="tabs_content_container">
            <div v-if="tabTypeId == 1" :class="tabTypeId == 1 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="ssjs_container">
                    <p v-if="ssjsInfo!= null && ssjsInfo.text != ''"  v-html="ssjsInfo.text"></p>
                    <i v-else class="el-icon-loading"></i>
                </div>
            </div>
            <div v-if="tabTypeId == 2" :class="tabTypeId == 2 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="ssry_container">
                    <div class="ssry_img_container">
                        <div class="ssry_carousel_container">
                            <swiper class="swiper" :options="swiperOption" v-if="ssryInfo.length > 0">
                                <swiper-slide v-for="item in ssryInfo" :key="item.honourid">
                                    <img v-if="item" :src="item.honourimgurl" alt="">
                                    <i v-else class="el-icon-picture-outline"></i>
                                </swiper-slide>
                                <div class="swiper-pagination" slot="pagination"></div>
                            </swiper>
                            <span v-else>暂无数据</span>
                        </div>
                        <!-- 分类 -->
                        <div class="ssry_type_container">
                            <el-button  type="info"  v-for="item in ssryTypeInfo" :key="item.value" @click="showSsryInfoFun(item.value)">{{item.text}}</el-button>
                        </div>
                    </div>
                    <!-- <i v-else class="el-icon-loading"></i> -->
                </div>
            </div>
            <div v-if="tabTypeId == 3" :class="tabTypeId == 3 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="czlc_container">
                    <!-- <div class="czlc_px_container">
                          时间顺序:
                          <el-radio-group v-model="reverse">
                            <el-radio :label="true">倒序</el-radio>
                            <el-radio :label="false">正序</el-radio>
                          </el-radio-group>
                    </div> -->
                    <div class="czlc_body_container">
                        <el-timeline :reverse="false">
                          <el-timeline-item :timestamp="item.year" placement="top" v-for="item in cclcInfo" :key="item.year">
                            <el-card>
                              <el-collapse v-if="item.data.length > 1" v-model="activeCzlcId" accordion>
                                <el-collapse-item v-for="items in item.data" :key="items.czlcdate" :title="items.czlcdate" :name="items.czlcdate">
                                  <div  v-html="items.czlctext"></div>
                                </el-collapse-item>
                              </el-collapse>
                              <div v-else v-html="item.data[0].czlctext"></div>
                            </el-card>
                          </el-timeline-item>
                        </el-timeline>
                    </div>
                  </div>
            </div>
            <div v-if="tabTypeId == 4" :class="tabTypeId == 4 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="hxbk_container">
                  <div class="hxbk_item_container" v-if="hxbk.length > 0">
                      <div class="hxbk_item" v-for="item in hxbk" :key="item.typeId">
                          <div class="hxbk_item_content_container">
                              <div class="hxbk_item_img_container">
                                  <el-image :src="item.bkimgurl" fit="cover">
                                      <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                      </div>
                                  </el-image>
                              </div>
                              <div class="hxbk_item_text_container">
                                  {{item.bkname}}
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            <div v-if="tabTypeId == 5" :class="tabTypeId == 5 ? 'tabs_content_item animate__animated animate__slideInRight' : 'tabs_content_item animate__animated animate__slideInLeft'">
                <div class="ssdj_container">
                  <div class="ssdj_carousel_container" v-if="ssdj.carousel.length > 0">
                      <el-carousel arrow="never" height="100%" indicator-position="none" :interval="2000">
                        <el-carousel-item v-for="item in ssdj.carousel" :key="item.articleid">
                          <div class="ssdj_carousel_content_container">
                            <div class="ssdj_carousel_img_container">
                                <el-image :src="item.articlecarouselimg" fit="cover">
                                  <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                  </div>
                                </el-image>
                            </div>
                            <div class="ssdj_carousel_words_container">
                                <div class="ssdj_carousel_title_container">
                                    {{item.articletitle}}
                                </div>
                                <div class="ssdj_carousel_date_container">
                                    日期：{{item.articledate}}
                                    <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                                </div>
                                <div class="ssdj_carousel_text_container" v-html="item.articletext"></div>
                            </div>
                          </div>
                        </el-carousel-item>
                      </el-carousel>
                  </div>
                  <div class="ssdj_articleList_container" v-if="ssdj.articleContent.length > 0">
                      <div class="ssdj_articleItem_container" v-for="item in ssdj.articleContent" :key="item.articleid">
                        <div class="ssdj_article_img_container">
                            <el-image :src="item.articlefaceimg" fit="cover">
                              <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                              </div>
                            </el-image>
                        </div>
                        <div class="ssdj_article_text_container">
                            <div class="ssdj_article_title_container">
                                {{item.articletitle}}
                            </div>
                            <div class="ssdj_article_date_container">
                                日期：{{item.articledate}}
                                <el-button type="text" @click="showArticleDeltail(item)">查看详情</el-button>
                            </div>
                            <div class="ssdj_article_words_container" v-html="item.articletext"></div>
                        </div>
                      </div>
                      <!-- 分页 -->
                      <div class="ssdj_articlePage_container">
                          <el-pagination
                            layout="prev, pager, next"
                            @current-change="pageChanged"
                            :current-page="pageInfo.currentPage"
                            :total="pageInfo.totals"
                            :page-size="pageInfo.pageSize">
                          </el-pagination>
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      tabTypeId: 1,
      activeCzlcId: '',
      gyssInfo: [
        {
          typeName: '山水介绍',
          typeId: 1
        },
        {
          typeName: '山水荣誉',
          typeId: 2
        },
        {
          typeName: '成长历程',
          typeId: 3
        },
        {
          typeName: '企业核心',
          typeId: 4
        },
        {
          typeName: '山水党建',
          typeId: 5
        }
      ],
      ssjsInfo: {},
      ssryInfo: [],
      swiperOption: {
        autoplay: {
          delay: 500,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        observer: true,
        observeParents: true,
        loop: true,
        autoplayDisableOnInteraction: false,
        speed: 1000,
        grabCursor: true,
        setWrapperSize: true,
        centeredSlides: true,
        spaceBetween: 30
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: false
        // }
      },
      reverse: true,
      cclcInfo: [],
      hxbk: [
        {
          typeId: 1,
          typeName: '设计',
          imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E7%8E%B0%E5%9C%A8%E7%9A%84%E6%80%BB%E9%83%A8%E5%A4%A7%E6%A5%BC%20%282%29202010281646554895.jpg'
        },
        {
          typeId: 2,
          typeName: '咨询',
          imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E7%8E%B0%E5%9C%A8%E7%9A%84%E6%80%BB%E9%83%A8%E5%A4%A7%E6%A5%BC%20%282%29202010281646554895.jpg'
        },
        {
          typeId: 3,
          typeName: '监理',
          imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E9%9B%86%E5%9B%A2%E6%80%BB%E9%83%A8%E8%8A%B1%E5%9B%AD%E5%81%87%E5%B1%B1ds202011111006121048.jpg'
        },
        {
          typeId: 4,
          typeName: '施工',
          imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E9%9B%86%E5%9B%A2%E6%80%BB%E9%83%A8%E8%8A%B1%E5%9B%AD%E5%81%87%E5%B1%B1ds202011111006121048.jpg'
        },
        {
          typeId: 5,
          typeName: '科研',
          imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E9%9B%86%E5%9B%A2%E6%80%BB%E9%83%A8%E8%8A%B1%E5%9B%AD%E5%81%87%E5%B1%B1ds202011111006121048.jpg'
        }
      ],
      ssdj: {
        carousel: [
          {
            carouselId: 1,
            carouselImgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E7%8E%B0%E5%9C%A8%E7%9A%84%E6%80%BB%E9%83%A8%E5%A4%A7%E6%A5%BC%20%282%29202010281646554895.jpg',
            router: '/',
            title: '2012年监理中标120余项，监理s费累计达3600万元2012年监理中标120余项，监理费累计达3600万元',
            date: '2020年10月9日',
            text: '2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。'
          },
          {
            carouselId: 2,
            carouselImgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E7%8E%B0%E5%9C%A8%E7%9A%84%E6%80%BB%E9%83%A8%E5%A4%A7%E6%A5%BC%20%282%29202010281646554895.jpg',
            router: '/',
            title: '2012年监理中标120余项，监理费累计达3600万元2012年监理中标120余项，监理s费累计达3600万元2012年监理中标120余项，监理费累计达3600万元',
            date: '2020年10月9日',
            text: '2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。'
          }
        ],
        articleContent: [
          {
            articleId: 1,
            title: '2012年监理中标120余项，监理费累计达3600万元',
            date: '2020年10月9日',
            text: '2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。',
            imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E7%8E%B0%E5%9C%A8%E7%9A%84%E6%80%BB%E9%83%A8%E5%A4%A7%E6%A5%BC%20%282%29202010281646554895.jpg'
          },
          {
            articleId: 2,
            title: '2012年监理中标120余项，监理费累计达3600万元',
            date: '2020年10月9日',
            text: '2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。',
            imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E7%8E%B0%E5%9C%A8%E7%9A%84%E6%80%BB%E9%83%A8%E5%A4%A7%E6%A5%BC%20%282%29202010281646554895.jpg'
          },
          {
            articleId: 3,
            title: '2012年监理中标120余项，监理费累计达3600万元',
            date: '2020年10月9日',
            text: '2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。2012年10月，公司承建的“临汾市汾河城区段生态环境综合治理修复与保护工程望河楼及附属工程”获得中国风景园林学会优秀园林古建工程金奖，昆山市夏驾河“水之韵”公园贰区景观绿化工程获中国风景园林学会“优秀园林绿化工程奖”银奖。',
            imgUrl: 'https://ssjt.buildone.com.cn//hxbk/%E7%8E%B0%E5%9C%A8%E7%9A%84%E6%80%BB%E9%83%A8%E5%A4%A7%E6%A5%BC%20%282%29202010281646554895.jpg'
          }
        ]
      },
      pageInfo: {
        currentPage: 1,
        totals: 50,
        pageSize: 3
      },
      ssryTypeInfo: [
        {
          text: ' 山水资质',
          value: '0'
        },
        {
          text: '企业荣誉',
          value: '1'
        },
        {
          text: '工程荣誉 ',
          value: '2'
        },
        {
          text: '科研荣誉',
          value: '3'
        },
        {
          text: '山水形象',
          value: '4'
        }
      ]
    }
  },
  methods: {
    // 获取党建文章轮播
    queryCarouselByType () {
      // 山水党建type：1
      this.$http.post('/admin/queryCarouselByType/2').then(res => {
        console.log(res)
        this.ssdj.carousel = res.data.data
      })
    },
    getSsdjByPage () {
      // console.log(this.pageInfo)
      this.$http.post('/admin/getArticleByType/1', this.pageInfo).then(res => {
        this.ssdj.articleContent = res.data.articles
        // console.log(res.data.articles[0].articleid)
        this.pageInfo.totals = res.data.totals
      })
    },
    // 获取企业核心
    getHxbk () {
      this.loading = true
      this.$http.get('/admin/getHxbk').then(res => {
        // console.log(res)
        this.loading = false
        this.hxbk = res.data.data
      })
    },
    // 获取成长历程
    getCzlc () {
      this.$http.get('/admin/getCzlc').then(res => {
        const _data = res.data.data
        const year = []
        const dataArray = []
        _data.forEach(item => {
          // 去重获取年份
          if (!year.includes(item.czlcdate.substring(0, 4))) {
            year.push(item.czlcdate.substring(0, 4))
          }
        })
        // 获取格式为{year: '', data: ''}的数据
        year.forEach(itemY => {
          const text = []
          _data.forEach(item => {
            if (itemY === item.czlcdate.substring(0, 4)) {
              text.push(item)
            }
          })
          dataArray.push({
            year: itemY,
            data: text
          })
        })
        // console.log(dataArray)
        this.cclcInfo = dataArray
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取山水介绍
    getSimpleText () {
      this.$http.post('/admin/getSimpleTextByType/0').then(res => {
        // console.log(res.data.data)
        if (res.data.data.length > 0) {
          this.ssjsInfo = res.data.data[0]
        }
      })
    },
    handleClick (tab, event) {
      // console.log(tab, event)
    },
    // 获取山水荣誉
    showSsryInfoFun (typeId) {
      // this.showSsryInfo = this.ssryInfo[typeId]
      // 根据类型获取对应荣誉信息
      this.ssryInfo = []
      this.$http.post('/admin/getHonourByType/' + typeId).then(res => {
        // console.log(res)
        this.ssryInfo = res.data.data
      })
    },
    changeTab (typeId) {
      window.sessionStorage.setItem('tabTypeId', typeId)
      this.tabTypeId = typeId
    },
    showArticleDeltail (data) {
      // console.log(data)
      window.sessionStorage.setItem('articleDetail', JSON.stringify(data))
      window.sessionStorage.setItem('activeIndex', '/gyss')
      this.$router.push('articleDetail')
    },
    pageChanged (currentPage) {
      // console.log(currentPage)
      this.pageInfo.currentPage = currentPage
      this.getSsdjByPage()
    }
  },
  watch: {
    tabTypeId: {
      handler: function (val, oldval) {
        console.log(val)
        this.tabTypeId = val
      }
    }
  },
  mounted () {
  },
  created () {
    this.getSimpleText()
    this.showSsryInfoFun(0)
    this.getCzlc()
    this.getHxbk()
    this.queryCarouselByType()
    this.getSsdjByPage()
    this.tabTypeId = window.sessionStorage.getItem('tabTypeId')
    this.changeTab(this.tabTypeId)
  }
}
</script>

<style lang="less" scoped>
// 新的
.gyss_container {
    width: 100%;
    min-height: 200px;
    background-color: #fff;
    border-radius: 5px;
    // box-shadow: 1px 1px 5px lightgray;
}
.tabs_btns_container {
    width: 100%;
    //  background-color: red;
    padding: 10px 5px;
    font-size: 100%;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
}
.gyss_btn {
    padding: 10px 20px;
    background-color: transparent;
    text-align: center;
    border-radius: 5px;
    color: #808080;
    cursor: pointer;
}
.gyss_btn:hover{
    background-color: #F5F5F5;
    color: #000;
}
.gyss_btn_click{
    background-color: #F5F5F5;
    color: #000;
}
.tabs_content_container {
    width: 100%;
    min-height: 200px;
    padding: 5px;
    overflow: hidden;
}
.tabs_content_item {
    width: 100%;
    min-height: 450px;
}
@media screen and (max-width: 500px){
  .tabs_btns_container {
      width: 100%;
      //  background-color: red;
      padding: 5px;
      font-size: 10px;
      border-bottom: 1px solid black;
      display: flex;
      justify-content: space-between;
  }
  .gyss_btn {
      padding: 10px 10px;
      background-color: #F5F5F5;
      text-align: center;
      border-radius: 5px;
      color: #808080;
      cursor: pointer;
  }
  .gyss_btn:hover{
      background-color: #F5F5F5;
      color: #000;
  }
  .gyss_btn_click{
      background-color: #F5F5F5;
      color: #000;
  }
  .tabs_content_container {
      width: 100%;
      min-height: 200px;
      padding: 5px;
      overflow: hidden;
  }
  .tabs_content_item {
      width: 100%;
      min-height: 200px;
  }
}
// 后
.ssjs_container {
    width: 100%;
    padding: 20px 0;
    min-height: 450px;
    position: relative;
}
.ssjs_container i {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    transform: translate(-50%, -50%);
}
// 山水荣誉
.ssry_container {
    width: 100%;
    padding-top: 20px;
    overflow: hidden;
}
.ssry_img_container {
    width: 100%;
}
.ssry_carousel_container {
    width: 100%;
    height: 400px;
    // background-color: pink;
}
.ssry_carousel_container .swiper{
    height: 100%;
}
.swiper-slide {
    width: 450px;
    height: 100%;
    padding: 2px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.swiper-slide img{
    max-height: 100%;
    max-width: 100%;
    box-shadow: 1px 1px 5px black;
}
.ssry_type_container {
    width: 100%;
    height: 50px;
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
@media screen and (max-width: 500px){
    // 山水荣誉
    .ssry_container {
        width: 100%;
    }
    .ssry_img_container {
        width: 100%;
    }
    .ssry_carousel_container {
        width: 100%;
        height: 250px;
        // background-color: pink;
    }
    .ssry_carousel_container .swiper{
        height: 100%;
    }
    .swiper-slide {
        width: 300px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-slide img{
        max-height: 100%;
        max-width: 100%;
        box-shadow: 1px 1px 5px black;
    }
    .ssry_type_container {
        width: 100%;
        padding: 10px 0;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .ssry_type_container .el-button{
        width: 17%;
        height: 30px;
        font-size: 10px;
        text-align: center;
        padding: 0;
    }
}
// 成长历程
.czlc_container {
  width: 100%;
  min-height: 500px;
}
.czlc_px_container , .czlc_body_container{
  width: 100%;
  // background-color: red;
  padding: 10px 0;
}
.hxbk_container {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hxbk_item_container {
  width: 100%;
  min-height: 400px;
  display: flex;
}
.hxbk_item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
}
.hxbk_item_content_container {
  width: 100%;
  height: 300px;
  position: relative;
}
.hxbk_item_img_container {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 300px;
}
.hxbk_item_img_container .el-image{
  width: 100%;
  height: 100%;
}
.hxbk_item_text_container {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 200;
  top: 240px;
  width: 80%;
  height: 50px;
  // background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
  color: #fff;
  box-shadow: 1px 1px 5px black;
}
@media screen and (max-width: 500px){
  .hxbk_item_container {
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }
  .hxbk_item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  .hxbk_item_content_container {
    width: 100%;
    height: 250px;
    position: relative;
  }
  .hxbk_item_img_container {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 250px;
  }
  .hxbk_item_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .hxbk_item_text_container {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 200;
    top: 190px;
    width: 90%;
    height: 50px;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150%;
    color: #fff;
    box-shadow: 1px 1px 5px black;
  }
}
// 山水党建
.ssdj_container {
  width: 100%;
  min-height: 450px;
}
.ssdj_carousel_container {
  width: 100%;
  height: 450px;
  background-color: #E6E6E6;
}
.ssdj_carousel_container .el-carousel {
  height: 100%;
}
.ssdj_carousel_content_container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
}
.ssdj_carousel_img_container {
  width: 40%;
  height: 100%;
}
.ssdj_carousel_img_container .el-image{
  width: 100%;
  height: 100%;
}
.ssdj_carousel_words_container {
  width: 60%;
  height: 100%;
  padding: 5px 20px;
  overflow: hidden;
}
.ssdj_carousel_title_container {
  width: 100%;
  font-size: 30px;
  text-align: center;
  height: 45px;
  padding: 10px 0;
  text-align: center;
  overflow : hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ssdj_carousel_date_container {
  width: 100%;
  color: #C9C9C9;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ssdj_carousel_text_container {
  width: 100%;
  height: 330px;
  color: #000;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0.2rem;
  display: -webkit-box;
  overflow : hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 768px) and (min-width: 500px){
  .ssdj_carousel_title_container {
    width: 100%;
    font-size: 24px;
    height: 40px;
    text-align: center;
    padding: 10px 0;
    text-align: center;
  }
  .ssdj_carousel_date_container {
    width: 100%;
    color: #C9C9C9;
    font-size: 14px;
  }
  .ssdj_carousel_text_container {
    width: 100%;
    height: 300px;
    color: #000;
    font-size: 17px;
    line-height: 30px;
    letter-spacing: 0.2rem;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}
@media screen and (max-width: 500px){
  .ssdj_carousel_container {
    width: 100%;
    height: 170px;
    background-color: #E6E6E6;
  }
  .ssdj_carousel_container .el-carousel {
    height: 100%;
  }
  .ssdj_carousel_content_container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 5px;
  }
  .ssdj_carousel_img_container {
    width: 40%;
    height: 100%;
  }
  .ssdj_carousel_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .ssdj_carousel_words_container {
    width: 60%;
    height: 100%;
    padding: 5px;
  }
  .ssdj_carousel_title_container {
    width: 100%;
    font-size: 15px;
    height: 24px;
    text-align: center;
    padding: 5px 0;
  }
  .ssdj_carousel_date_container {
    width: 100%;
    color: #C9C9C9;
    height: 25px;
    // background-color: red;
    font-size: 10px;
  }
  .ssdj_carousel_text_container {
    width: 100%;
    height: 90px;
    color: #000;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2rem;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
// 列表
.ssdj_articleList_container {
  width: 100%;
  min-height: 500px;
  // background-color: yellow;
  padding: 20px 0;
}
.ssdj_articleItem_container {
  width: 90%;
  height: 320px;
  // background-color: red;
  margin: 30px auto;
  display: flex;
  box-shadow: 1px 1px 5px 1px lightgray;
}
.ssdj_article_img_container {
  width: 40%;
  height: 100%;
}
.ssdj_article_img_container .el-image{
  width: 100%;
  height: 100%;
}
.ssdj_article_text_container {
  width: 60%;
  height: 100%;
  padding: 10px 20px;
}
.ssdj_articlePage_container {
  width: 100%;
  height: 50px;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ssdj_article_title_container {
  width: 100%;
  font-size: 24px;
  height: 34px;
  padding: 5px 0;
  text-align: center;
  overflow : hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.ssdj_article_date_container {
  width: 100%;
  font-size: 14px;
  text-align: left;
  color: #C9C9C9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ssdj_article_words_container {
  width: 100%;
  height: 216px;
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  overflow : hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 500px){
  .ssdj_articleList_container {
    width: 100%;
    min-height: 500px;
    // background-color: yellow;
    padding: 10px 0;
  }
  .ssdj_articleItem_container {
    width: 100%;
    height: 170px;
    // background-color: red;
    margin: 30px auto;
    display: flex;
    // border: 1px solid lightgray;
    box-shadow: 1px 1px 5px 1px lightgray;
  }
  .ssdj_article_img_container {
    width: 40%;
    height: 100%;
  }
  .ssdj_article_img_container .el-image{
    width: 100%;
    height: 100%;
  }
  .ssdj_article_text_container {
    width: 60%;
    height: 100%;
    padding: 5px;
  }
  .ssdj_articlePage_container {
    // width: 90%;
    height: 50px;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ssdj_article_title_container {
    width: 100%;
    font-size: 16px;
    line-height: 28px;
    height: 28px;
    padding: 2px 0;
    text-align: left;
    overflow : hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .ssdj_article_date_container {
    width: 100%;
    font-size: 10px;
    text-align: left;
    height: 30px;
    color: #C9C9C9;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ssdj_article_words_container {
    width: 100%;
    height: 100px;
    font-size: 14px;
    letter-spacing: 0.1rem;
    line-height: 20px;
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}
</style>
