<template>
<!-- 业绩展示 -->
    <div class="sylb_container" v-loading.lock="loading" element-loading-text="数据拉取中">
        <div class="sylb_btns_container">
            <el-button-group>
                <el-button type="primary" size="small" icon="el-icon-refresh" @click="toggleSelection()">取消</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editSelection()">修改</el-button>
                <el-button type="success" size="small" icon="el-icon-plus" @click="addDialogBtn()">添加</el-button>
                <el-button type="danger"  size="small" icon="el-icon-delete" @click="deleteBtn()">删除</el-button>
            </el-button-group>
        </div>
        <div class="sylb_table_container">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                show-overflow-tooltip
                fit
                height="675"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection">
                </el-table-column>
                <el-table-column
                label="职位名称"
                width="300"
                sortable
                prop="positionname">
                </el-table-column>
                <el-table-column
                label="工作地点"
                width="200"
                prop="positionplace">
                </el-table-column>
                <el-table-column
                label="招聘人数"
                width="100"
                prop="positionnumber">
                </el-table-column>
                <el-table-column
                label="发布时间"
                width="180"
                sortable
                prop="createtime">
                </el-table-column>
                <el-table-column
                label="更新日期"
                sortable
                prop="edittime">
                </el-table-column>
                <el-table-column
                label="任职技能"
                width="500"
                prop="positionskill">
                  <template slot-scope="scope">
                      <div class="positionskill_container" v-html="scope.row.positionskill"></div>
                  </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- tianjia -->
        <el-dialog title="添加岗位信息" :visible.sync="addDialogVisable" :close-on-click-modal="false"  @close="dialogClose" width="900px">
            <el-form :model="positionInfo" ref="companyFormRef" :rules="companyFormRules">
                <el-form-item label="职位名称" label-width="100px" prop="positionname">
                    <el-input type="text" v-model="positionInfo.positionname"></el-input>
                </el-form-item>
                <el-form-item label="工作地点"  label-width="100px" prop="positionplace">
                    <el-input type="text" v-model="positionInfo.positionplace"></el-input>
                </el-form-item>
                <el-form-item label="招聘人数" label-width="100px" prop="positionnumber">
                    <el-input-number v-model="positionInfo.positionnumber" :min="1" :max="99" label="招聘人数"></el-input-number>
                </el-form-item>
                <el-form-item label="发布时间" label-width="100px" prop="createtime">
                    <el-date-picker
                      v-model="positionInfo.createtime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="任职技能" label-width="100px" prop="positionskill">
                    <editor-bar  v-model="positionInfo.positionskill" :isClear="isClear"></editor-bar>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitDialog()">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改 -->
        <el-dialog title="修改岗位信息" :visible.sync="editDialogVisable" @close="dialogClose" width="900px" :close-on-click-modal="false">
            <el-form :model="positionInfo" ref="companyFormRef" :rules="companyFormRules">
                <el-form-item label="职位名称" label-width="100px" prop="positionname">
                    <el-input type="text" v-model="positionInfo.positionname"></el-input>
                </el-form-item>
                <el-form-item label="工作地点"  label-width="100px" prop="positionplace">
                    <el-input type="text" v-model="positionInfo.positionplace"></el-input>
                </el-form-item>
                <el-form-item label="招聘人数" label-width="100px" prop="positionnumber">
                    <el-input-number v-model="positionInfo.positionnumber" :min="1" :max="99" label="招聘人数"></el-input-number>
                </el-form-item>
                <el-form-item label="发布时间" label-width="100px" prop="createtime">
                    <el-date-picker
                      v-model="positionInfo.createtime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="任职技能" label-width="100px" prop="positionskill">
                    <editor-bar  v-model="positionInfo.positionskill" :isClear="isClear"></editor-bar>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisable = false">取 消</el-button>
                <el-button type="primary" @click="submitEditDialog()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import EditorBar from './WangEditor/Editor.vue'
export default {
  components: { 'editor-bar': EditorBar },
  data () {
    return {
      isClear: true,
      dialogLoading: false,
      positionInfo: {},
      loading: false,
      tableData: [],
      multipleSelection: [],
      addDialogVisable: false,
      editDialogVisable: false,
      companyFormRules: {
        positionname: [
          { required: true, message: '请输入职位名称', trigger: 'blur' }
        ],
        positionplace: [
          { required: true, message: '请输入工作地点', trigger: 'blur' }
        ],
        positionnumber: [
          { required: true, message: '招聘人数', trigger: 'change' }
        ],
        createtime: [
          { required: true, message: '发布时间', trigger: 'change' }
        ],
        positionskill: [
          { required: true, message: '任职技能', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    dialogClose () {
      this.loading = false
      this.positionInfo = {}
    },
    getPosition () {
      this.loading = true
      this.$http.get('/admin/getPosition').then(res => {
        this.tableData = res.data.data
        this.loading = false
      })
    },
    // editDialog
    submitEditDialog () {
      // console.log(this.positionInfo)
      this.$refs.companyFormRef.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.$http.post('/admin/editPosition', this.positionInfo).then(res => {
            if (res.data.code === 200) {
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.companyFormRef.resetFields()
              this.getPosition()
              this.editDialogVisable = false
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
          })
        }
      })
    },
    // 提交dialog内容 addDialog
    submitDialog () {
      console.log(this.positionInfo)
      this.dialogLoading = true
      this.$refs.companyFormRef.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.$http.post('/admin/addPosition', this.positionInfo).then(res => {
            if (res.data.code === 200) {
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              })
              this.$refs.companyFormRef.resetFields()
              this.getPosition()
              this.dialogLoading = false
              this.loading = false
            } else {
              this.$notify.error({
                title: '失败',
                message: '操作失败'
              })
            }
          })
        }
      })
    },
    addDialogBtn () {
      this.addDialogVisable = true
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 修改操作
    editSelection () {
      if (this.multipleSelection.length === 1) {
        // console.log(this.multipleSelection[0])
        this.positionInfo = this.multipleSelection[0]
        this.editDialogVisable = true
      } else {
        this.$notify.info({
          title: '提示',
          message: '请选择一项进行修改'
        })
      }
    },
    deleteBtn () {
      if (this.multipleSelection.length === 0) {
        this.$notify.info({
          title: '提示',
          message: '请选择对应行'
        })
      } else {
        this.loading = true
        this.$http.post('/admin/deletePosition', this.multipleSelection).then(res => {
          this.loading = false
          this.getPosition()
          this.$notify.success({
            title: '成功',
            message: '删除成功'
          })
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.$notify.error({
            title: '错误',
            message: '内部错误'
          })
        })
      }
    }
  },
  mounted () {
  },
  created () {
    this.getPosition()
  }
}
</script>

<style lang="less" scoped>
.sylb_container {
  width: 100%;
  // background-color: #2D2D2D;
  // height: 100%;
  margin-top: 50px;
  padding: 10px;
}
.sylb_btns_container {
  width: 100%;
  padding: 20px 5px;
  background-color: #FFFFFF;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sylb_table_container {
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;
}
// .positionskill_container {
//   width: 100%;
//   height: 28px;
//   display: -webkit-box;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
// }
</style>
